import React, {useEffect, useMemo} from 'react'
import CustomDropdown from './CustomDropdown'
import {dummyWatchData, printingConsts} from '../qrcodeConsts'
import useQzTray from '../../../../../hooks/useQzTray'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'

const qrSizeOptions =  Array.from(Array(8), (_, index) => ({
	value: index + 3,
	label: index + 3,
}));

const PrinterAdjustments = ({configData, updatePrintingData, hidePrint, pt}) => {
	const {getPrinters, printersList, printPPLZ} = useQzTray()

	const printingData = useMemo(() => {
		return configData?.find(item => item.key === 'printer_settings')
	}, [configData])

	useEffect(() => {
		if (printingData && printingData?.key === 'printer_settings' && !printingData.value.printer && printersList === null) {
			getPrinters()
		}
	}, [printingData])

	const printerOptions = useMemo(() => {
		if (!printersList && printingData?.value.printer) {
			return [
				{
					value: printingData?.value.printer,
					label: printingData?.value.printer,
				},
			]
		}
		return (printersList || []).map(printer => {
			return {
				value: printer,
				label: printer,
			}
		})
	}, [printersList, printingData])

	const handlePrintingOptionsChange = async ({value, key}) => {
		const clonedData = cloneDeep(printingData)
		set(clonedData, key, value)
		updatePrintingData(clonedData)
	}

	const printWithQZTray = () => {
		printPPLZ({
			watchData: {
				...dummyWatchData,
				short_url: 'https://app.elefta.com/',
			},
			customPrintingSettings: configData,
		})
	}
	return (
		<div className={`flex-1 flex px-5 pt-[${pt ? pt : '44px'}] flex-row justify-center `}>
			<div style={{width: '100%', maxWidth: 460, position: 'relative'}}>
				<p className={'font-bold text-[#5d6e81] mt-3'}>Printing settings</p>

				<div className={'flex flex-row mt-8 pb-3 items-center'}>
					<p className={'text-[#5d6e81]'} style={{fontSize: 14, paddingRight: 12}}>
						Label Size (inch)
					</p>
					<div className={'relative'}>
						<div
							style={{
								position: 'absolute',
								top: -16,
								left: 8,
							}}
						>
							<p className={'text-[10px] text-[#5d6e81]'}>Width</p>
						</div>
						<input
							onChange={e =>
								handlePrintingOptionsChange({
									value: e.target.value,
									key: 'value.sizing.width',
								})
							}
							value={get(printingData, 'value.sizing.width')}
							type="number"
							className={'border h-[24px] pl-2 border-gray-300 rounded-md mr-3 text-[#5d6e81]'}
							style={{width: 64, fontSize: 12}}
						/>
					</div>

					<div className={'relative'}>
						<div
							style={{
								position: 'absolute',
								top: -16,
								left: 8,
							}}
						>
							<p className={'text-[10px] text-[#5d6e81]'}>Height</p>
						</div>
						<input
							onChange={e =>
								handlePrintingOptionsChange({
									value: e.target.value,
									key: 'value.sizing.height',
								})
							}
							value={get(printingData, 'value.sizing.height')}
							type="number"
							className={'border h-[24px] pl-2 border-gray-300 text-[#5d6e81] rounded-md'}
							style={{width: 64, fontSize: 12}}
						/>
					</div>
				</div>
				<CustomDropdown
					onOpen={() => getPrinters()}
					mt={'12px'}
					onChange={option => handlePrintingOptionsChange({value: option.value, key: 'value.printer'})}
					options={printerOptions}
					value={get(printingData, 'value.printer')}
					label={'Select Default Printer'}
				/>
				<p className={'font-bold text-[#5d6e81] mt-4 text-[14px]'}>Label Spacing,(pts)</p>
				<div className={'flex mt-4 text-[#5d6e81] flex-row justify-between relative'}>
					{printingConsts.map((col, index) => (
						<div className={'w-[30%]'}>
							<p className={''} style={{fontSize: 14, paddingRight: 12}}>
								Column {index + 1} {(index === 1 && '(QR)') || ''}
							</p>
							{col.map((field, index) => (
								<div className={'flex font-regular flex-row items-center justify-between mt-2'}>
									<p className={''} style={{fontSize: 14, paddingRight: 12}}>
										{field.label}
									</p>
									{field.type === 'select' ? (
										<div className={' relative'}>
											<select
												onChange={e => {
													handlePrintingOptionsChange({
														value: e.target.value,
														key: field.key,
													})
												}}
												className={'flex-1 border border-gray-300 pl-[12px] bg-transparent text-[12px] rounded-md'}
												value={get(printingData, field.key)}
												style={{height: 24, width: 64 }}
											>
												{qrSizeOptions.map(option => (
													<option value={option.value}>{option.label}</option>
												))}
											</select>
											{/*{qrFieldsConfig[config.key].defaultValue === config.value && (*/}
											{/*	<div className={'absolute z-0 left-[16px] top-[1px]'}>*/}
											{/*		<p style={{lineHeight: '10px'}} className={'text-[6px] text-[#D1D6DB]'}>*/}
											{/*			Default*/}
											{/*		</p>*/}
											{/*	</div>*/}
											{/*)}*/}
										</div>
									) : (
										<input
											onChange={e =>
												handlePrintingOptionsChange({
													value: e.target.value,
													key: field.key,
												})
											}
											value={get(printingData, field.key)}
											type="number"
											className={'border h-[24px] pl-2 border-gray-300 rounded-md'}
											style={{width: 64, fontSize: 12}}
										/>
									)}
								</div>
							))}
						</div>
					))}
					<div
						style={{
							position: 'absolute',
							left: '-180px',
							top: '-30px',
						}}
					>
						<img className="w-[auto] h-[180px]" src="/printAxis.png" alt="" />
					</div>
				</div>
				{!hidePrint && (
					<div className={'flex flex-row justify-end mt-6'}>
						<button className="px-[24px] py-[12px] border-[1px] rounded-[5px] text-[#FFFFFF] font-bold text-[14px] bg-[#4B7CBE] cursor-pointer" onClick={printWithQZTray}>
							Test Printing
						</button>
					</div>
				)}
			</div>
		</div>
	)
}

export default PrinterAdjustments
