import React, { useCallback, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import Box from '@mui/material/Box'
import { addToast, setCurrentSpace } from '../../../app/appActions'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import {Globals} from '../../../Context'
import InventoryConfigGroup from './GeneralComponents/InventoryConfigGroup'
import { debounce } from 'lodash'

const General = (props) => {
    const { currentSpace } = props
    const { handleSubmit, control, watch, reset, setValue } = useForm({
      values: currentSpace.space_general_setting
    });
    const dispatch = useDispatch()
    const triggerSubmit = useCallback(debounce(() => {
      handleSubmit(onSubmit)()
    }, 1000), [])

    const onSubmit = (data) => {
      const submitData = {
        space_general_setting: {
          auto_suggest_sku_enabled: data.auto_suggest_sku_enabled,
          auto_suggest_sku_prefix: data.auto_suggest_sku_prefix,
          auto_suggest_sku_starting_number: data.auto_suggest_sku_starting_number,
          auto_suggest_sku_suffix: data.auto_suggest_sku_suffix,
          default_sort_order_inventory: data.default_sort_order_inventory,
          default_sort_order_sold_inventory: data.default_sort_order_sold_inventory,
          price_visible_in_marketplace: data.price_visible_in_marketplace,
          default_visible_in_marketplace: data.default_visible_in_marketplace,
        }}

      return Globals.New_Axios()
        .patch(`${Globals.NEW_API_URL}/v1/spaces/${Globals.SELECTEDSPACEID}/space_general_settings`, submitData)
        .then(async response => {
          const { data } = response

          dispatch(setCurrentSpace({
            ...currentSpace,
            space_general_setting: data
          }))

          dispatch(
            addToast({
            title: 'General Settings',
            text: 'General settings updated successfully.',
            type: 'success',
          }))

        })
        .catch(error => {
          console.log('error', error);

          dispatch(
            addToast({
            title: 'General Settings',
            text: error.response?.data?.message || 'Something went wrong please try again later.',
            type: 'error',
          }))
        })
    }

    const isAutoSuggestEnabled = watch('auto_suggest_sku_enabled') && watch('auto_suggest_sku_enabled').toString() === 'true'

    return (
        <form className="w-full h-full pt-2 pb-10" onSubmit={handleSubmit(onSubmit)}>
            {Globals.adminOrOwnerOrEditor(props.profile) &&
                <div className='mt-[35px] text-[16px] font-bold text-[#5D6E81]'>Inventory</div>
            }
            <InventoryConfigGroup
              control={control}
              reset={reset}
              isAutoSuggestEnabled={isAutoSuggestEnabled}
              profile={props.profile}
              triggerSubmit={triggerSubmit}
            />
            {Globals.adminOrOwnerOrEditor(props.profile) &&
                <div className='mt-[35px] text-[16px] font-bold text-[#5D6E81]'>Marketplace</div>
            }
            <div className="mt-[17px]">
              {props.profile.role === 'owner' &&
                <>
                  <div className='flex items-center'>
                    <div className="mx-6 text-[16px]">Do you want your price visible in the marketplace?</div>
                    <Controller
                        name="price_visible_in_marketplace"
                        control={control}
                        defaultValue={true}
                        render={({ field }) => (
                            <RadioGroup {...field}
                              aria-labelledby="price-visible-in-marketplace-group"
                              sx={{
                                display: 'contents'
                              }}
                              row
                              onChange={(e) => {
                                field.onChange(e.target.value)
                                triggerSubmit()
                              }}
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio size="small" />}
                                    label={
                                        <Box component="div">
                                            Yes
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio size="small" />}
                                    label={
                                        <Box component="div">
                                            No
                                        </Box>
                                    }
                                />
                            </RadioGroup>
                        )}
                    />
                  </div>
                  <div className='flex items-center'>
                    <div className="mx-6 text-[16px]">Default value for “Visible In Marketplace”</div>
                    <Controller
                        name="default_visible_in_marketplace"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                            <RadioGroup {...field}
                              aria-labelledby="default-visible-in-marketplace-group"
                              sx={{
                                display: 'contents'
                              }}
                              row
                              onChange={(e) => {
                                field.onChange(e.target.value)
                                triggerSubmit()
                              }}
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio size="small" />}
                                    label={
                                        <Box component="div">
                                            Yes
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio size="small" />}
                                    label={
                                        <Box component="div">
                                            No
                                        </Box>
                                    }
                                />
                            </RadioGroup>
                        )}
                    />
                  </div>
                  <div className='flex items-center opacity-60'>
                    <div className="mx-6 text-[16px]">Do you allow other dealers to display the watches you’ve listed on Elefta’s marketplace on their own sites?</div>
                    <Controller
                        name="allow_dealers_to_display"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                            <RadioGroup {...field} aria-labelledby="wholesale-price-visibility-group" row sx={{
                                display: 'contents'
                            }}>
                                <FormControlLabel
                                    value={true}
                                    control={<Radio size="small" />}
                                    label={
                                        <Box component="div">
                                            Yes
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio size="small" />}
                                    label={
                                        <Box component="div">
                                            No
                                        </Box>
                                    }
                                />
                            </RadioGroup>
                        )}
                    />
                  </div>
                </>
              }
            </div>
            {props.profile.role === 'owner' &&
                 <>
                    <div className='mt-[35px] text-[16px] font-bold text-[#5D6E81]'>Daily Report</div>
                    <div className="flex items-center mt-[17px] opacity-60">
                        <div className="mx-6 text-[16px]">Would you like a daily activity report emailed to the owners of this space?</div>
                            <Controller
                                name="daily_visible"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} aria-labelledby="wholesale-price-visibility-group" row>
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio size="small" />}
                                            label={
                                                <Box component="div">
                                                    Yes
                                                </Box>
                                            }
                                        />
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio size="small" />}
                                            label={
                                                <Box component="div">
                                                    No
                                                </Box>
                                            }
                                        />
                                    </RadioGroup>
                                )}
                            />
                    </div>
                 </>
            }
            {Globals.adminOrOwnerOrEditor(props.profile) &&
              <>
                <div className='mt-[35px] text-[16px] font-bold text-[#5D6E81]'>Email</div>
                <div className="flex items-center mt-[17px] opacity-60">
                    <div className="mx-6 text-[16px]">Would you like emails for Memo/Invoices to: </div>
                        <Controller
                            name="email_visible"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup {...field} aria-labelledby="wholesale-price-visibility-group" row>
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio size="small" />}
                                        label={
                                            <Box component="div">
                                                Open your default mail application
                                            </Box>
                                        }
                                    />
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio size="small" />}
                                        label={
                                            <Box component="div">
                                                Send automatically via Elefta
                                            </Box>
                                        }
                                    />
                                </RadioGroup>
                            )}
                        />
                </div>
              </>
            }
        </form>
    )
}

const mapStateToProps = (state, props) => {
	return {
    currentSpace: state.currentSpace,
  }
}

const mapActionsToProps = {
  setCurrentSpace
}

export default connect(mapStateToProps, mapActionsToProps)(General)
