import React, {useEffect, useState, useRef} from 'react'
import Tooltip from '@mui/material/Tooltip'
import classNames from 'classnames'
import Moment from 'moment'
import {SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type as ListType} from 'react-swipeable-list'
import 'react-swipeable-list/dist/styles.css';

import {Globals} from '../../Context'
import ConfirmPopUp from '../ConfirmPopUp'
import AddNewMiscProduct from '../Memo/AddNewMiscProduct'
import SalesProductsHunter from './SalesProductsHunter'
import TradeInDialog from './TradeInDialog'
import CommentModal from './CommentModal'
import PartialPayment from './PartialPayment'
import { focusElement } from '../../utils/keyboardHandler'
import {onPressEscape} from '../../utils/keyboardHandler'
import AddressDropdown from '../Customers/AddressDropdown'
import UsersDropdown from "../Customers/UsersDropdown"
import TermsDropdown from '../Sales/TermsDropdown'
import CreditPayment from './CreditPayment'
import useOutsideClick from '../../hooks/useOutsideClick'
import ServerAutoSuggestAddress from '../ServerAutoSuggestAddress'
import { formatPhoneNumber } from '../../Components/Customers/AddNewCustomer'
import CommentForm from './CommentForm'
import AddNoteForLineItem from './AddNoteForLineItem'

import './styles.css'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import sum from 'lodash/sum'
import isFunction from 'lodash/isFunction'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import remove from 'lodash/remove'
import assignIn from 'lodash/assignIn'
import head from 'lodash/head'
import isNull from 'lodash/isNull'
import upperFirst from 'lodash/upperFirst'

const initAddress = {
	visible: false,
	address: '',
	apt_unit_number: '',
	city: '',
	state: '',
	country: '',
	is_manual: false,
	zip_code: '',
	full_address: '',
	save_to_contact: false,
}

const getDefaultNewBillingAddress = (customer) => {
	return {
		...(customer?.customer_billing_addresses.find(address => address.is_billing_default) || {
		company_name: '',
		contact_name: '',
		phone_number: '+1'
		}),
		...initAddress,
		id: null
	};
	};

const getDefaultNewShippingAddress = (customer) => {
	return {
		...(customer?.customer_shipping_addresses.find(address => address.is_shipping_default) || {
		company_name: '',
		contact_name: '',
		phone_number: '+1'
		}),
		...initAddress,
		id: null
	};
};

const EditSale = ({handleClose, invoiceDetails, props, updateInvoiceItem, tradeIn, handleUpdateItemInInventory,handleOpenPerformanceLimitAccessModal, orderComments, handleSubmitComment, setCommentField, commentField, isVisibleComments, setIsVisibleComments, selectedComment, handleToogleInputComment, sendingComment, handleUpdateComment, handleDeleteComment, loadSingleOrder, handleStatusUpdate, handleDeletePayment, handleDeleteCreditPayment, handlePrimaryTab, loadedUsers}) => {
	const [invoiceData, setInvoiceData] = useState(invoiceDetails)
	const [invoicedItems, setInvoicedItems] = useState(invoiceDetails.sale_items)
	const [loadedProducts, setLoadedProducts] = useState([])
	const [showProductsHunter, setShowProductsHunter] = useState(false)
	const [deletedItems, setDeletedItems] = useState([])
	const [miscItems, setMiscItems] = useState(invoiceDetails.misc_items || [])
	const [addNewMiscProduct, setAddNewMiscProduct] = useState(false)
	const [showTradeIn, setShowTradeIn] = useState(false)
	const [tradeInItems, setTradeInItems] = useState(invoiceDetails.trade_in_items)
	const [isCustomerLabeled, setIsCustomerLabeled] = useState(invoiceData.customer_label)
	const [shippingPrice, setShippingPrice] = useState(invoiceData.shipping_price)
	const [inventorySearch, setInventorySearch] = useState()
	const [isVisibleDropdown, setIsVisibleDropdown] = useState(false)
	const [openCommentModal, setOpenCommentModal] = useState(false)
	const [commentForSale, setCommentForSale] = useState()
	const [isAddCommentModal, setIsAddCommentModal] = useState(true)
	const [partialPaymentPopup, setPartialPaymentPopup] = useState(false)
	const [creditPaymentPopup, setCreditPaymentPopup] = useState(false)
	const [partialPayment, setPartialPayment] = useState()
	const [creditPayment, setCreditPayment] = useState()
	const [customer, setCustomer] =useState(invoiceDetails.customer)
	const [selectedShippingAddress, setSelectedShippingAddress] =useState(invoiceDetails.shipping_address)
	const [selectedBillingAddress, setSelectedBillingAddress] =useState(invoiceDetails.billing_address)
	const [newBillingAddress, setNewBillingAddress] = useState(getDefaultNewBillingAddress(customer))
	const [newShippingAddress, setNewShippingAddress] = useState(getDefaultNewShippingAddress(customer))
  const [openNoteModal, setOpenNoteModal] = useState(false)
  const [saleItemSelected, setSaleItemSelected] = useState(null)

	const [loadedSpaceUsers, setLoadedSpaceUsers] = useState(loadedUsers || [])
	const [selectedSalesPerson, setSelectedSalesPerson] = useState()
	const [isAddCreditPayment, setIsAddCreditPayment] = useState(true)
	const [termCustomField, setTermCustomField] = useState('')
	const [termCustomSelect, setTermCustomSelect] = useState({value: 'days', label: 'Days'})
	const [selectedTerm, setSelectedTerm] = useState(Globals.saleTerms[0])
	const [trackingNumber, setTrackingNumber] = useState('')
	const [isFlagTrackingNumber, setIsFlagTrackingNumber] = useState(false)
	const [loadingProduct, setLoadingProduct] = useState(false)
	const [taxRate, setTaxRate] = useState(invoiceDetails.is_tax_exempt ? 0 : invoiceDetails.tax_rate)
	const [isTaxExempt, setIsTaxExempt] = useState(invoiceDetails.is_tax_exempt)
	const [isManualBillingAddress, setIsManualBillingAddress] = useState(false)
	const [isManualShippingAddress, setIsManualShippingAddress] = useState(false)
	const [errors, setErrors] = useState([])
	const [isShowTaxExemptInput, setIsShowTaxExemptInput] = useState(!invoiceDetails.is_tax_exempt || props.currentSpace?.invoice_settings?.[0]?.add_sale_tax)
	const [billingAddress, setBillingAddress] = useState('')
	const [shippingAddress, setShippingAddress] = useState('')

	const billingAddressInputRef = useRef(null)
	const shippingAddressInputRef = useRef(null)
	const selectRef = useRef(null)
	const trackingNumberInputRef = useRef(null)

	useOutsideClick(trackingNumberInputRef, () => handleClickOutTrackingNumber());

	const loadProducts = () => {
		setLoadingProduct(true)
		Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches?kind=inventory&q[g][0][status_in][]=on_hand&frontend_filter_enabled=true${inventorySearch}`)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					const items = invoicedItems
					let loadeddProducts = response.data.watches.map(post => {
						post.inBag = items.map(item => item.saleable.id).includes(post.id)
						post.qty = 1

						return post
					})
					setLoadingProduct(false)
					setLoadedProducts(loadeddProducts)
				} else {
					setLoadingProduct(false)
					setLoadedProducts([])
				}
			})
			.catch(error => {
				setLoadingProduct(false)
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	useEffect(() => {
		const handleClickOutside = event => {
			if (isVisibleDropdown && selectRef.current && !selectRef.current.contains(event.target)) {
				setIsVisibleDropdown(false)
			}
		}
		if (isVisibleDropdown) {
			document.addEventListener('mousedown', handleClickOutside)
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [isVisibleDropdown])

	useEffect(() => {
		const address = selectedShippingAddress || newShippingAddress
		if (!isTaxExempt) {
			handleFetchTaxRate(address)
		}
	}, [isTaxExempt, selectedShippingAddress, newShippingAddress])

	const handleKeyDownTrackingNumber = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			trackingNumberInputRef.current.blur();
			handleSubmitTrackingNumber()
		}
	};

	const handleClickOutTrackingNumber = () => {
		if(showProductsHunter || showTradeIn || addNewMiscProduct || partialPaymentPopup || creditPaymentPopup) return
		if(isFlagTrackingNumber) {
			handleSubmitTrackingNumber()
			setIsFlagTrackingNumber(false)
		}
	}


	const handleNewAddressChange = (type, field, value) => {
		if (field === 'phone_number') {
			value = formatPhoneNumber(value)
		}

		if (type === "newBillingAddress") {
			let newValue = field === 'save_to_contact' ? !newBillingAddress.save_to_contact : value

			setNewBillingAddress({
				...newBillingAddress,
				[field]: newValue,
			})
		} else if (type === "newShippingAddress") {
			let newValue = field === 'save_to_contact' ? !newShippingAddress.save_to_contact : value

			setNewShippingAddress({
				...newShippingAddress,
				[field]: newValue,
			})

			handleFetchTaxRate({...newShippingAddress, [field]: value})
		}
	}

	const handleChangeTrackingNumber = (event) => {
		setIsFlagTrackingNumber(true)
		setTrackingNumber(event.target.value)
	}

	const handleSubmitTrackingNumber = () => {
		Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales/${invoiceDetails.id}`, {
				sale: {
					tracking_number: trackingNumber,
				},
			})
			.then(response => {

				updateInvoiceItem(response.data)
				props.addToast({
					text: 'Tracking Number Updated Successfully',
					type: 'success',
				})

			}).catch(error => {
				return props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	const handleSelectAddress = (type, address) => {
		if (type === 'billing') {
			setSelectedBillingAddress(address)
			setNewBillingAddress({
				...getDefaultNewBillingAddress(customer),
				visible: false,
			})
		} else {
			setSelectedShippingAddress(address)
			setNewShippingAddress({
				...getDefaultNewShippingAddress(customer),
				visible: false,
			})
			handleFetchTaxRate(address)
		}
	}

	const handleChangeTaxExempt = () => {
		setIsTaxExempt(!isTaxExempt)
	}

	const handleFetchTaxRate = debounce((address) => {
		if (isTaxExempt) {
			return
		}

		let zipCode = 0;

		zipCode = address?.zip_code

		if (!!zipCode) {
			Globals.New_Axios()
				.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales/fetch_tax_rate`, {
					params: {
						zip_code: zipCode,
					},
				})
				.then(response => {
					setTaxRate(response.data?.taxSales || 0)
				})
				.catch(error => {
					const message = error.response?.data?.message || 'Something went wrong please try again later.'
					return props.addToast({
						text: message,
						type: 'error',
					})
				})
		} else {
			setTaxRate(0)
		}
	}, 500)

	const handleChangeTermCustomField = (event) => {
		const value = event.target.value;
        if (!isNaN(value) || value === '') {
			setTermCustomField(value)
        }
	}

	const handleSelectTerms = value => {
		setSelectedTerm(value)
	}

	const handleSelectCustomTerm = selectedTermObject => {
		if(selectedTermObject.value === 'custom') {
			setSelectedTerm(selectedTermObject)
			setTermCustomSelect({value: 'days', label: 'Days'})
			setTermCustomField('')
		} else {
			setTermCustomSelect(selectedTermObject)
		}
	}

	const handleVisibleManualBillingAddress = () => {
		if(!isManualBillingAddress) {
			if(!newBillingAddress.address) {
				const address = {
					...newBillingAddress,
					address: newBillingAddress.full_address
				}
				setNewBillingAddress(address)
			}
		} else {
			const address = {
				...newBillingAddress,
				address: billingAddress
			}
			setNewBillingAddress(address)
		}
		setIsManualBillingAddress(!isManualBillingAddress)
	}

	const handleVisibleManualShippingAddress = () => {
		if(!isManualShippingAddress) {
			if(!newShippingAddress.address) {
				const address = {
					...newShippingAddress,
					address: newShippingAddress.full_address
				}
				setNewShippingAddress(address)
			}
		} else {
			const address = {
				...newShippingAddress,
				address: shippingAddress
			}
			setNewShippingAddress(address)
		}
		setIsManualShippingAddress(!isManualShippingAddress)
	}

	const handleSetSalePerson = (users) => {
		const defaultSelectedSalesPerson = users.find(user => user.id === invoiceDetails.salesperson_id) || props.currentSpace?.invoice_settings?.[0]?.saleperson
		setSelectedSalesPerson(defaultSelectedSalesPerson)
	}

	const handleLoadUsers = () => {
		handleSetSalePerson(loadedSpaceUsers)
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/users`, {
				params: {
					q: {
						s: 'created_at desc',
					},
					per_page: 10000,
				},
			})
			.then(response => {
				let {users} = response.data
				setLoadedSpaceUsers(users)
				handleSetSalePerson(users)
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return props.addToast({
					text: message,
					type: 'error',
				})
			})
	}

	const handleAddNewAddress = type => {
		if (type === 'billing') {
			setSelectedBillingAddress(null)
			setNewBillingAddress({
				...newBillingAddress,
				visible: true,
			})
		} else {
			setSelectedShippingAddress(null)
			setNewShippingAddress({
				...newShippingAddress,
				visible: true,
			})

			handleFetchTaxRate({})
		}
	}

	const handleSelectSalesPerson = user => {
		if(!!user) {
			setSelectedSalesPerson(user)
		} else {
			setSelectedSalesPerson(null)
		}
	}

	const handleItemPriceChange = (e, i) => {
		const newInvoicedItems = invoicedItems.map(item => {
			if (item.saleable.id === i.saleable.id) {
				let nPrice = e.target.value.replaceAll(',', '')
				return {...item, price: nPrice}
			} else {
				return item
			}
		})

		setInvoicedItems(newInvoicedItems)
	}
	const handlePriceChange = (item, price) => {
		let _price = price.replaceAll(',', '')
		_price = isNaN(_price) ? 0 : _price

		const newInvoiedItems = invoicedItems.map(product => (product.saleable?.id === item.id ? {...product, price: _price} : product))
		setInvoicedItems(newInvoiedItems)
		const allProducts = loadedProducts.map(product => {
			if (product.id === item.id) product.wholesale_price = _price
			return product
		})
		setLoadedProducts(allProducts)
	}

	const handleRemovingMiscItem = i => {
		setMiscItems(miscItems.filter((product, index) => index !== i))
	}

	const handleSaleMiscPriceChange = (i, e) => {
		let _price = 0
		if(!!e.target.value) {
			_price = e.target.value.replaceAll(',', '')
		}
		const items = miscItems.map((product, index) => (index === i ? {...product, price: _price} : product))
		setMiscItems(items)
	}

  const handleOpenNoteModal = (event, saleItem) => {
    setSaleItemSelected(saleItem)
    setOpenNoteModal(true)
  }

  const handleCloseNoteModal = () => {
    setOpenNoteModal(false)
    setSaleItemSelected(null)
  }


  const setNoteData = (event, value) => {
    setInvoicedItems(invoicedItems.map(item => {
      if (item.id === saleItemSelected.id && item.saleable_id === saleItemSelected.saleable_id) {
        item.note = value
      }
      return item
    }))

    setOpenNoteModal(false)
    setSaleItemSelected(null)
  }

	const hideAddNewMiscProduct = () => {
		setAddNewMiscProduct(false)
		focusElement('.single-sale-view-edit')
	}

	const handleMiscItemList = newItem => {
		let items = [...miscItems]
		items.push(newItem)
		setMiscItems(items)
	}

	const handleRemoveItem = i => {
		let items = invoicedItems
		items = items.map(item => {
			if (item.saleable.id === i.saleable.id) {
				return {...item, _destroy: true}
			} else {
				return item
			}
		})
		let newProducts = loadedProducts.map(item => {
			if (item.id === i.saleable.id) {
				return {...item, inBag: false}
			} else {
				return item
			}
		})
		setLoadedProducts(newProducts)
		setInvoicedItems(items)
	}

	const handleBuildDataCustomTerms = () => {
		if(!invoiceDetails?.payment_term_method) return
		if(invoiceDetails?.payment_term_method === 'cod') {
			return setSelectedTerm({value: invoiceDetails?.payment_term_method, label: 'C.O.D.'})
		} else if(invoiceDetails?.payment_term_method === 'net_3') {
			return setSelectedTerm({value: invoiceDetails?.payment_term_method, label: 'Net 3 Days'})
		} else if(invoiceDetails?.payment_term_method === 'net_7') {
			return setSelectedTerm({value: invoiceDetails?.payment_term_method, label: 'Net 7 Days'})
		}  else if(invoiceDetails?.payment_term_method === 'net_30') {
			return setSelectedTerm({value: invoiceDetails?.payment_term_method, label: 'Net 30 Days'})
		} else if(invoiceDetails?.payment_term_method === 'days') {
			setTermCustomField(invoiceDetails?.payment_term_days)
			setTermCustomSelect({value: 'days', label: 'Days'})
			setSelectedTerm({value: 'custom', label: 'Custom'})
		} else if(invoiceDetails?.payment_term_method === 'months') {
			setTermCustomField(invoiceDetails?.payment_term_days)
			setTermCustomSelect({value: 'months', label: 'Months'})
			setSelectedTerm({value: 'custom', label: 'Custom'})
		}
	}

	useEffect(() => {
		setInvoiceData(invoiceDetails)
		handleLoadUsers()
		handleBuildDataCustomTerms()
		setTrackingNumber(invoiceDetails.tracking_number)
	}, [invoiceDetails])

	const handleShippingChange = e => {
		if (e.target.value) {
			setShippingPrice(e.target.value)
		} else {
			setShippingPrice(0)
		}
	}

	const handleCloseProductsHunter = () => {
		setErrors([])
		setShowProductsHunter(false)
		setInventorySearch('')
		focusElement('.single-sale-view-edit')
	}

	const handleAddingItemToOrder = item => {
		let items = invoicedItems
		if (!isEmpty(items.filter(it => it.id === item.id))) return
		let memIt = {
			quantity: item.qty,
			saleable_id: item.id,
			//id: item.id,
			price: item.wholesale_price,
			saleable_type: 'Watch',
			saleable: item,
			box: item.box
		}
		items.push(memIt)

		setInvoicedItems(items)
		setLoadedProducts(
			loadedProducts.map(product => {
				if (product.id === item.id) product.inBag = true
				return product
			})
		)
	}

	const handleRemovingItemToOrder = (item, $event) => {
		$event.stopPropagation()
		setLoadedProducts(
			loadedProducts.map(product => {
				if (product.id === item.id) product.inBag = false
				return product
			})
		)
		setInvoicedItems(invoicedItems.filter(product => product.saleable.id !== item.id))
	}

	const handleSearchProducts = debounce($event => {
		const keyword = $event.target.value
		let search = ''
		if (!isEmpty(keyword)) {
			search = `&q[${Globals.watchSearchParams}]=${keyword}`
		}
		setInventorySearch(search)
	}, 1000)

	useEffect(() => {
		loadProducts()
	}, [inventorySearch])

	const handleTradeInPriceChange = (value, ind) => {
		let price = value.replaceAll(',', '')

		let items = tradeInItems.map((item, index) => {
			if (index === ind) {
				item.price = !!price ? price : 0
				return item
			} else {
				return item
			}
		})
		setTradeInItems(items)
	}

	const handleCustomTermsWhenSubmit = () => {
		if(selectedTerm !== 'Custom') {
			return  selectedTerm
		} else {
			if(Globals.saleTerms.includes(termCustomSelect)) {
				return termCustomSelect
			} else {
				return `${termCustomField} ${termCustomSelect}`
			}
		}
	}

	const handleCalculateTax = () => {
		if (isTaxExempt) {
			return 0
		}

		let total = 0
		total += sum(invoicedItems.filter(t => !t._destroy).map(t => (t.price ? Globals.revertPrice(t.price) : 0)))
		total += sum(miscItems.map(t => (t.price ? Globals.revertPrice(t.price) : 0)))
		total -= sum(tradeInItems.map(t => (t.price ? Globals.revertPrice(t.price) : 0)))

		return total * taxRate
	}

	const calculateTotalExcludeTax = () => {
		let total = 0
		total += sum(invoicedItems.filter(t => !t._destroy).map(t => (t.price ? Globals.revertPrice(t.price) : 0)))
		total += sum(miscItems.map(t => (t.price ? Globals.revertPrice(t.price) : 0)))
		total -= sum(tradeInItems.map(t => (t.price ? Globals.revertPrice(t.price) : 0)))
		total += (isCustomerLabeled ? 0 : Globals.revertPrice(shippingPrice))

		return total
	}

	const calculateSubtotal = () => {
		let total = 0
		total += sum(invoicedItems.filter(t => !t._destroy).map(t => (t.price ? Globals.revertPrice(t.price, true) : 0)))
		total += sum(miscItems.map(t => (t.price ? Globals.revertPrice(t.price, true) : 0)))
		total -= sum(tradeInItems.map(t => (t.price ? Globals.revertPrice(t.price, true) : 0)))

		return Globals.readCurrencyNumber(total, true)
	}

	const calculateTotalIncludeTax = () => {
		return calculateTotalExcludeTax() + handleCalculateTax()
	}

	const saveOrder = () => {
		let updateShippingPriceForSale = shippingPrice
		if(isCustomerLabeled) {
			updateShippingPriceForSale = 0
		}

		const saleParams = {
			sale_items_attributes: invoicedItems,
			shipping_price: updateShippingPriceForSale,
			status: invoiceData.status,
			customer_id: invoiceData.customer.id,
			deletedItems: deletedItems,
			misc_items: miscItems,
			trade_in_items: tradeInItems,
			customer_label: isCustomerLabeled,
			tracking_number: trackingNumber,
			payment_term_method: selectedTerm.value,
			is_tax_exempt: isTaxExempt,
			tax_rate: isTaxExempt ? 0 : taxRate,
		}

		if(selectedSalesPerson) {
			saleParams['salesperson_id'] = selectedSalesPerson.id
		} else {
			saleParams['salesperson_id'] = null
		}

		if (selectedTerm.value === 'custom') {
			saleParams['payment_term_days'] = termCustomField
			saleParams['payment_term_method'] = termCustomSelect.value
		}

		if (selectedBillingAddress) {
			saleParams.billing_address_id = selectedBillingAddress.id
		} else {
			saleParams.billing_address_attributes = {...newBillingAddress}
			if (newBillingAddress.phone_number === '+1') {
				saleParams.billing_address_attributes['phone_number'] = ''
			}

			saleParams.billing_address_attributes['in_contact'] = newBillingAddress.save_to_contact
			saleParams.billing_address_attributes['is_manual'] = isManualBillingAddress
			saleParams.billing_address_attributes['customer_id'] = customer.id
		}

		if (selectedShippingAddress) {
			saleParams.shipping_address_id = selectedShippingAddress.id
		} else {
			saleParams.shipping_address_attributes = {...newShippingAddress}
			if (newShippingAddress.phone_number === '+1') {
				saleParams.shipping_address_attributes['phone_number'] = ''
			}

			saleParams.shipping_address_attributes['in_contact'] = newShippingAddress.save_to_contact
			saleParams.shipping_address_attributes['is_manual'] = isManualShippingAddress
			saleParams.shipping_address_attributes['customer_id'] = customer.id
		}

		if(!!saleParams?.billing_address_attributes && !!saleParams?.shipping_address_attributes && !saleParams?.billing_address_attributes?.company_name && !saleParams?.shipping_address_attributes?.company_name && !saleParams?.billing_address_attributes.contact_name && !saleParams?.shipping_address_attributes?.contact_name) {
			return props.addToast({
				title: 'Error',
				text: 'Billing and Shipping addresses must include either a Company Name or a Contact Name.',
				type: 'error',
			})
		}
		if (isFunction(handlePrimaryTab)) handlePrimaryTab()

		Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/sales/${invoiceData.id}`, {
				sale: saleParams,
			})
			.then(response => {
				handleClose(response.data)
				updateInvoiceItem(response.data)

				// Change inventory status back to on hand after deleted a sale item
				const saleCloned = cloneDeep(response.data)
				saleCloned.sale_items = concat(saleCloned.sale_items, invoicedItems.filter(item => item._destroy).map(item => {
					item.saleable.status = 'on_hand'
					item.saleable.status_details = null
					return item
				}))

				//updateInvoiceItem(invoicedItems, shippingPrice, miscItems, tradeInItems)
				handleUpdateItemInInventory(saleCloned)
				props.addToast({
					title: 'Success',
					text: 'Invoice Updated Successfully',
					type: 'success',
				})
			}).catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return props.addToast({
					text: message,
					type: 'error',
				})
			})
	}

	const hideTradeInDialog = () => {
		setShowTradeIn(false)
		focusElement('.single-sale-view-edit')
	}

	const updateTradeIn = item => {
		let items = tradeInItems
		items.push(item)
		setTradeInItems(items)
	}

	const handleCheckCountries = (value) => {
		if(value.long_name === 'Japan' && value.types.includes('country')) {
			if(!value.types.includes('street_number') || !value.types.includes('route')) {
				return false
			}
		}
		return true
	}

	const handleChangeGoogleAddress = (place) => {
		const tmpAddress = []
    let subpremise

		if (!place.address_components) return

		(place.address_components || []).forEach(value => {
			if (value.types.includes('street_number') || value.types.includes('route')) {
        tmpAddress.push(value.long_name)
			}

      if (value.types.includes('subpremise')) {
        const match = value.long_name.match(/\d+$/);
        subpremise = match ? match[0] : value.long_name;
      }

			const isCheckCountries = handleCheckCountries(value)
			if(!isCheckCountries) {
				const part =  place.formatted_address.split(", ")
				tmpAddress.push(part[0])
			}
		})

		let city = place.address_components.find(component => component.types.includes('locality'))?.long_name || ''
		if(!city) {
			city = place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name || ''
		}

		//Note: Check Japan Country


		const searchedAddress = {
			full_address: place.formatted_address.replace(` ${subpremise}`, ''),
			address: tmpAddress?.toString().replaceAll(',', ' ').trimStart(),
			street: place.address_components.find(component => component.types.includes('route'))?.long_name || '',
			city: city,
			state: place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.short_name || '',
			country: place.address_components.find(component => component.types.includes('country'))?.long_name || '',
			zip_code: place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '',
		}

    if (subpremise) {
      searchedAddress.apt_unit_number = subpremise
    }

		return searchedAddress
	}

	const handleChangeBillingAddress = (place) => {
		const searchedAddress =  handleChangeGoogleAddress(place)
		if (!isManualBillingAddress) {
			const newAddress = assignIn({}, newBillingAddress, searchedAddress)
			setBillingAddress(searchedAddress.address)
			setNewBillingAddress(newAddress)
		}
	}

	const handleChangeShippingAddress = (place) => {
		const searchedAddress =  handleChangeGoogleAddress(place)
		if (!isManualShippingAddress) {
			const newAddress = assignIn({}, newShippingAddress, searchedAddress)
			setShippingAddress(searchedAddress.address)
			setNewShippingAddress(newAddress)
		}
	}


	const handleRemoveTradeIn = i => {
		let items = tradeInItems.filter((t, index) => index !== i)
		setTradeInItems(items)
	}

	const handleOpenProductHunter = () => {
		setShowProductsHunter(true)
		setIsVisibleDropdown(false)
	}

	const handleOpenMiscProduct = () => {
		setAddNewMiscProduct(true)
		setIsVisibleDropdown(false)
	}

	const handleOpenTradeIn = () => {
		setShowTradeIn(true)
		setIsVisibleDropdown(false)
	}

	useEffect(() => {
		loadProducts()
	}, [])

	const toggleShipping = () => {
		setIsCustomerLabeled(prevState => !prevState)
		setShippingPrice(0)
	}

	useEffect(() => {
		const iItems = remove([...invoiceDetails.sale_items], item => {
			return !item.canceled
		})

		setInvoicedItems(iItems)
	}, [invoiceDetails.sale_items])

	const trailingActions = (handleDelete) => {
		return (
			<TrailingActions>
				<SwipeAction className="bg-[#E1373794]" onClick={handleDelete}>
					<img src="/Images/delete.svg" alt="delete.svg" width={40} className="mx-auto px-[5px]" />
				</SwipeAction>
			</TrailingActions>
		)
	}

	const handleCloseCommentModal = () => {
		setOpenCommentModal(false)
    setCommentField({content: '', visible_on_pdf: false})
	}

	const handleEditComment = (comment) => {
		setOpenCommentModal(true)
		setIsAddCommentModal(false)
    setCommentField({ content: comment.content, visible_on_pdf: comment.visible_on_pdf })
		setCommentForSale(comment)
	}

	const hidePartialPaymentPopup = () => {
		setPartialPaymentPopup(false)
		setPartialPayment(null)
	}

	const hideCreditPaymentPopup = () => {
		setCreditPaymentPopup(false)
		setCreditPayment(null)
	}

	const openEditCreditPayment = (payment) => {
		setCreditPaymentPopup(true)
		setIsAddCreditPayment(false)
		setCreditPayment(payment)
	}

	const handleUpdateItemProduct = (item) => {
		const newInvoicedItems = invoicedItems.map(product => (product.saleable?.id === item.id ? {...product, box: item.box} : product))
		setInvoicedItems(newInvoicedItems)
		const allProducts = loadedProducts.map(product => {
			if (product.id === item.id) product.box = item.box
			return product
		})
		setLoadedProducts(allProducts)
	}

	const handleChangeSaleBox = (event, item) => {
		event.stopPropagation()
		let newOrderedItems
		if(!item.saleable_id) {
			newOrderedItems = invoicedItems.map(product => (product.id === item.id ? {...product, box: !item.box} : product))
		} else {
			newOrderedItems = invoicedItems.map(product => (product.saleable?.id === item.saleable_id ? {...product, box: !item.box} : product))
		}
		setInvoicedItems(newOrderedItems)
	}


	const handleEditPayment = (payment) => {
		setPartialPaymentPopup(true)
		setPartialPayment(payment)
	}

	const handleEditCredit = (payment) => {
		setCreditPaymentPopup(true)
		setCreditPayment(payment)
	}

	const handleSubmit = (e) => {
		selectedComment ? handleUpdateComment(e, selectedComment.id) : handleSubmitComment(e)
		setIsVisibleComments(false)
	}

	useEffect(() => {
		focusElement('.single-sale-view-edit')
	}, [])

	return (
		<>
			{showProductsHunter && (
				<SalesProductsHunter
					loadedProducts={loadedProducts}
					handleCloseProductsHunter={handleCloseProductsHunter}
					invoicedItems={invoicedItems}
					handleAddingItemToOrder={handleAddingItemToOrder}
					handleRemovingItemToOrder={handleRemovingItemToOrder}
					handleSearchProducts={handleSearchProducts}
					handleItemPriceChange={handlePriceChange}
					props={props}
					handleUpdateItemProduct={handleUpdateItemProduct}
					loadingProduct={loadingProduct}
				/>
			)}
			{openCommentModal && (
				<CommentModal
					handleCloseCommentModal={handleCloseCommentModal}
					handleSubmitComment={handleSubmitComment}
					setCommentField={setCommentField}
					commentField={commentField}
					isAddCommentModal={isAddCommentModal}
					handleUpdateComment={handleUpdateComment}
					commentForSale={commentForSale}
				/>
			)}
			{showTradeIn && <TradeInDialog hideTradeInDialog={hideTradeInDialog} props={props} updateTradeIn={updateTradeIn} />}
			{addNewMiscProduct && <AddNewMiscProduct props={props} hideAddNewMiscProduct={hideAddNewMiscProduct} handleMiscItemList={handleMiscItemList} />}
			{partialPaymentPopup && (
				<PartialPayment
					hidePartialPayment={hidePartialPaymentPopup}
					props={props}
					singleOrderItem={invoiceData}
					loadSingleOrder={loadSingleOrder}
					handleStatusUpdate={handleStatusUpdate}
					singlePartialPayment={partialPayment}
					isAddPartialPayment={false}
					shippingPrice={shippingPrice}
				/>
			)}
			{creditPaymentPopup && (
				<CreditPayment
					hideCreditPayment={hideCreditPaymentPopup}
					props={props}
					singleOrderItem={invoiceData}
					loadSingleOrder={loadSingleOrder}
					handleStatusUpdate={handleStatusUpdate}
					singleCreditPayment={creditPayment}
					isAddCreditPayment={isAddCreditPayment}
				/>
			)}
			<div
				onKeyDown={onPressEscape((event) => handleClose())}
				className="hidden single-sale-view-edit  bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-30 md:block"
				tabIndex="-1"
			>
				<div className="bg-white rounded-tl-xl absolute top-0 right-0 shadow-xl overflow-scroll h-full" style={{maxWidth: '1100px', width: '100%'}}>
					<div className="flex items-center gap-3 bg-white border-b py-6 px-10">
						<div className="flex items-center justify-between w-full">
							<div className='flex'>
								<ConfirmPopUp title="Invoice Edit" content="You are going to close the edit and none of the changes will be saved" onConfirm={handleClose}>
									<button>
										<svg className="w-6 h-6" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
										</svg>
									</button>
								</ConfirmPopUp>
								{<h2 className="font-semibold text-2xl uppercase">Edit ORDER: #{invoiceData?.order_number}</h2>}
							</div>
							<div className="flex justify-between items-center pb-6 md:pb-0">
								<div className=" mr-5">
									<Tooltip title="Add a product from your inventory" placement="bottom" arrow>
										<button
											onClick={() => setShowProductsHunter(true)}
											className="bg-blue-500 text-white border border-blue-500 flex items-center px-3 py-2 relative rounded w-full"
										>
											<img src="/Images/plus.svg" alt="plus.svg" height={12} width={12} />
											<span className="font-normal text-sm text-center">Add products</span>
										</button>
									</Tooltip>
								</div>
								<div className="mr-5">
									<Tooltip title="Add a miscellaneous item" placement="bottom" arrow>
										<button
											onClick={() => setAddNewMiscProduct(true)}
											className="bg-blue-500 text-white border border-blue-500 flex items-center px-3 py-2 relative rounded w-full"
										>
											<img src="/Images/plus.svg" alt="plus.svg" height={12} width={12} />
											<span className="font-normal text-sm text-center">Add misc products</span>
										</button>
									</Tooltip>
								</div>
								<div className="mr-5">
									<Tooltip title="Add a product from your inventory" placement="bottom" arrow>
										<button onClick={() => setShowTradeIn(true)} className="bg-blue-500 text-white border border-blue-500 flex items-center px-3 py-2 relative rounded w-full">
											<img src="/Images/plus.svg" alt="plus.svg" height={12} width={12} />
											<span className="font-normal text-sm text-center">Add Trade-In</span>
										</button>
									</Tooltip>
								</div>
							</div>
						</div>
					</div>
					<div className="pb-5 pt-2">
						<div  className='px-[24px]'>
							<table className="relative min-w-full border-b bg-transparent overflow-scroll">
								<thead className="bg-[#5D6E81] text-white uppercase font-semibold text-[14px]">
									<tr>
										<th className="w-6/12 px-[15px] py-2 text-sm font-semibold tracking-worderer rounded-tl-[5px] rounded-bl-[5px]">Product</th>
										<th className="w-2/12 py-2 text-sm font-semibold tracking-worderer">SKU</th>
										<th className="w-2/12 py-2 text-sm font-semibold tracking-worderer">Price</th>
										<th className="w-1/12 pr-[60px] py-2 text-sm font-semibold tracking-worderer text-right  rounded-tr-[5px] rounded-br-[5px]">Action</th>
									</tr>
								</thead>
								<tbody className="divide-y">
									{isEmpty(invoicedItems) && isEmpty(tradeInItems) && isEmpty(miscItems) &&  <div className="text-xs py-4">No Item(s) Invoiced</div>}
									{!isEmpty(invoicedItems) &&
										invoicedItems?.map((item, index) => {
											return (
												!item._destroy && (
													<tr key={index} className={classNames('cursor-pointer hover:bg-gray-100 bg-white border-b')}>
														<td className="py-6 px-[15px] text-sm font-medium tracking-worderer w-7/12">
															<div className="w-10/12">
																<p>{item.saleable.name}</p>
                                <div className='flex gap-4'>
																  <p onClick={(event) => {handleChangeSaleBox(event, item)}} className='text-[14px] font-semibold w-max border-[1px] rounded-[5px] p-[2px]'>{item?.box ? "With Box" : "Without Box"}</p>
                                  <p
                                    onClick={(event) => {
                                      handleOpenNoteModal(event, item)
                                    }}
                                    className={classNames(`text-[14px] font-semibold w-max border-[1px] rounded-[5px] p-[2px]`, {
                                      'border-[#E0E0E0] text-[#E0E0E0]': !item?.note,
                                    })}
                                  >
                                    Notes
                                  </p>
                                </div>
															</div>
														</td>
														<td className="py-6  text-sm font-medium tracking-worderer">{item.saleable.sku}</td>
														<td className="py-6  text-sm font-medium tracking-worderer">
															<input
																onChange={e => handleItemPriceChange(e, item)}
																type="text"
																className="border px-2 py-1 rounded"
																value={Globals.formatCurrency(item?.price, true)}
																placeholder="Price"
															/>
															{errors.map(value => {
																return (
																	value.price.index === index &&
																		<span className='text-red-700'>{value.price.error}</span>
																)
															})}
														</td>
														<td className="py-6 px-[15px] tracking-worderer" align="center">
															{invoicedItems.length > 0 && (
																	<button
																	onClick={() => handleRemoveItem(item)}
																	className="flex flex-col text-xs rounded items-center pl-1 pr-2 py-0.5"
																>
																	<img src="/Images/remove_icon.png" alt="remove icon" className="w-[22px] h-[22px]" />
																	<span className="text-[10px] font-semibold text-[#EE8B8B]">Delete</span>
																</button>
															)}
														</td>
													</tr>
												)
											)
										})}

										{!isEmpty(miscItems) && miscItems.map((product, index) => {
											return (
												!product._destroy && (
													<tr key={index} className={classNames('cursor-pointer hover:bg-gray-100 bg-white border-b')}>
														<td className="py-2 px-[15px] text-sm font-medium tracking-worderer w-7/12">
															<div className="w-10/12">
																<p>{product.product_name}</p>
															</div>
														</td>
														<td className="py-2  text-sm font-medium tracking-worderer">{product.sku}</td>
														<td className="py-2  text-sm font-medium tracking-worderer">
															<input
																onChange={e => handleSaleMiscPriceChange(index, e)}
																type="text"
																className="border px-2 py-1 rounded"
																value={Globals.formatCurrency(product.price, true)}
																placeholder="Item Price"
															/>
														</td>
														<td className="py-2 px-[15px] tracking-worderer" align="center">
															<button
																onClick={() => handleRemovingMiscItem(index)}
																className="flex flex-col text-xs rounded items-center pl-1 pr-2 py-0.5"
															>
																	<img src="/Images/remove_icon.png" alt="remove icon" className="w-[22px] h-[22px]" />
																	<span className="text-[10px] font-semibold text-[#EE8B8B]">Delete</span>
															</button>
														</td>
													</tr>
												)
											)
										})}

									{!isEmpty(tradeInItems) &&
										tradeInItems.map((item, index) => {
											return (
												<tr key={index + item.productName + 'trade'} className={classNames('cursor-pointer hover:bg-gray-100 bg-white border-b')}>
														<td className="py-2 px-[15px] text-sm font-medium tracking-worderer w-7/12">
															<div className="w-10/12">
																<p>(Trade-In) - {item.product_name}</p>
															</div>
														</td>
														<td className="py-2  text-sm font-medium tracking-worderer">{item.sku}</td>
														<td className="py-2  text-sm font-medium tracking-worderer">
															<input
																onChange={e => handleTradeInPriceChange(e.target.value, index)}
																type="text"
																className="border px-2 py-1 rounded"
																value={Globals.formatCurrency(item?.price, true) || 0}
																placeholder="Price"
															/>
														</td>
														<td className="py-2 px-[15px] tracking-worderer" align="center">
															<button
																onClick={() => handleRemoveTradeIn(index)}
																className="flex flex-col text-xs rounded items-center pl-1 pr-2 py-0.5"
															>
																	<img src="/Images/remove_icon.png" alt="remove icon" className="w-[22px] h-[22px]" />
																	<span className="text-[10px] font-semibold text-[#EE8B8B]">Delete</span>
															</button>
														</td>
													</tr>
											)
										})}
								</tbody>
							</table>
							{(!isEmpty(invoicedItems) || !isEmpty(miscItems) || !isEmpty(tradeInItems)) && (
										<div className="flex justify-between items-start w-full pt-2">
											<div className="w-1/4 text-[13px] font-normal px-[35px] tracking-worderer flex">
												<p className='mr-2'>Total Products: {invoicedItems.length + miscItems.length + tradeInItems.length}</p>
											</div>
											<div className="w-[400px] pr-[30px] text-sm font-semibold tracking-worderer">
												<br />
												<div className="flex justify-between items-center">
													<div className="text-black text-base font-normal">Subtotal</div>
													<div className="text-black text-base font-normal">
														{Globals.readCurrencyNumber(
															calculateSubtotal()
														)}
													</div>
												</div>
												<div className="flex justify-between items-center pb-[4px]">
													<div className="text-black text-base font-normal">Shipping</div>
													<div className="text-black text-base font-normal">
														<div className="text-sm font-normal flex">
															<div
																onClick={(e) => toggleShipping(e)}
																className={classNames("text-center w-[120px] h-[23px] cursor-pointer flex items-center justify-center rounded-[5px] border-[1px] border-[#5d6e8133]", {
																	"text-[#4B7CBE] bg-[#5D6E81] bg-opacity-20": isCustomerLabeled,
																	"text-[#4B7CBE] bg-white": !isCustomerLabeled,
																})}
															>
																Customer Label
															</div>
															{!isCustomerLabeled ?
																<input
																	onChange={$event => {
																		handleShippingChange($event)
																	}}
																	className="border h-[23px] w-[51px] rounded-[5px] ml-[7px] text-right"
																	readOnly={isCustomerLabeled}
																	disabled={isCustomerLabeled}
																	value={isCustomerLabeled ? '' : shippingPrice ? Globals.formatCurrency(shippingPrice, true) : ''}
																/> : <></>
															}
														</div>
													</div>
												</div>
												{ isShowTaxExemptInput && (
													<div className="flex justify-between items-center pb-[4px]">
														<div className="text-black text-base font-normal">Tax</div>
														<div className="text-black text-base font-normal">
															<div className="text-sm font-normal flex">
																{isTaxExempt ? (
																	<div
																		onClick={handleChangeTaxExempt}
																		className="border h-[23px] min-w-[51px] pl-[13px] pr-1 rounded-[5px] ml-[7px] text-right cursor-pointer"
																	>
																		Exempt
																	</div>
																) : (
																	<div
																		onClick={handleChangeTaxExempt}
																		className="border h-[23px] min-w-[51px] pl-[13px] pr-1 rounded-[5px] ml-[7px] text-right cursor-pointer"
																	>
																		{Globals.readCurrencyNumber(handleCalculateTax(), true)}
																	</div>
																)}
															</div>
														</div>
													</div>
												)}
												<hr className="m-0 border-black w-4/12 float-right"/>
												<div className="flex justify-between items-center w-full pt-[4px]">
													<div className="text-black text-base font-normal">Total</div>
													<div className="text-black text-base font-normal">
														{Globals.readCurrencyNumber(calculateTotalIncludeTax(), true)}
													</div>
												</div>
											</div>
										</div>
									)}
						</div>
						<div className="mt-[48px] bg-[#F4F4F4]">
							<div className="px-[39px] py-5">
								<div className="text-[#5D6E81] text-[22px] font-bold">Customer Details</div>
								<div className="w-full flex justify-between mt-[15px]">
									<div className="justify-between">
										<div className="flex items-center gap-x-[13px] mb-[30px]">
											<img src="./Images/company.png" alt="" className="h-[24px] w-[24px]" />
											<span>{customer?.company_name}</span>
										</div>
										<div className="flex items-center gap-x-[13px] mb-[30px]">
											<img src="./Images/phone.png" alt="" className="h-[24px] w-[24px]" />
											<div className="flex flex-col">
												{
													customer?.customer_phone_numbers && customer?.customer_phone_numbers[0] && (
														<div>
															<span>{customer.customer_phone_numbers[0].phone_number_formatted}</span>
														</div>
													)
												}
											</div>
										</div>
										<div className="flex items-center gap-x-[13px]">
											<img src="./Images/mail.png" alt="" className="h-[24px] w-[24px]" />
											<span>{customer?.email}</span>
										</div>
									</div>
									<div className="flex flex-col">
										<div className="flex items-center gap-x-[13px] mb-[11px]">
											<img src="./Images/people.png" alt="" className="h-[24px] w-[24px]" />
											<span className="text-[#5D6E81] text-[14px] font-semibold">Billing</span>
											<AddressDropdown
												addressOptions={customer.customer_billing_addresses}
												addressType="billing"
												selectedAddress={selectedBillingAddress}
												onSelect={handleSelectAddress}
												onAddNew={handleAddNewAddress}
											></AddressDropdown>
										</div>
										<div className="ms-[43px] max-w-[235px]">
											{newBillingAddress.visible ? (
												<React.Fragment>
													<input
														type="text"
														className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
														placeholder="Company Name"
														autoComplete='off'
														value={newBillingAddress.company_name}
														onChange={e => handleNewAddressChange('newBillingAddress', 'company_name', e.target.value)}
													/>
													<input
														type="text"
														className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
														placeholder="Contact Name"
														autoComplete='off'
														value={newBillingAddress.contact_name}
														onChange={e => handleNewAddressChange('newBillingAddress', 'contact_name', e.target.value)}
													/>
													{!isManualBillingAddress ?
														<>
															<div>
																<div className='flex w-[235px] gap-1'>
																	<ServerAutoSuggestAddress
																		formData={{full_address: newBillingAddress.full_address}}
																		handleChangeGoogleAddress={handleChangeBillingAddress}
																		className="border rounded-[5px] w-full h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		view={'memo'}
																	/>
																	<input
																		type="text"
																		className="border rounded-[5px] w-[38%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		placeholder="Apt/Unit #"
																		name='apt_unit_number'
																		value={newBillingAddress.apt_unit_number}
																		onChange={e => handleNewAddressChange('newBillingAddress', 'apt_unit_number', e.target.value)}
																	/>
																</div>

																<span
																	onClick={handleVisibleManualBillingAddress}
																	className='text-xs mt-[1px] cursor-pointer underline'
																>
																	Add Manually
																</span>
															</div>
														</>
														:
														<>
															<div>
																<div className='flex w-[235px] gap-1'>
																	<input
																		type="text"
																		className="border rounded-[5px] w-[70%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		placeholder="Street"
																		name='address'
																		value={newBillingAddress.address}
																		onChange={e => handleNewAddressChange('newBillingAddress', 'address', e.target.value)}
																	/>
																	<input
																		type="text"
																		name='apt_unit_number'
																		className="border rounded-[5px] w-[30%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		placeholder="Apt/Unit #"
																		value={newBillingAddress.apt_unit_number}
																		onChange={e => handleNewAddressChange('newBillingAddress', 'apt_unit_number', e.target.value)}
																	/>
																</div>
																<span
																	onClick={handleVisibleManualBillingAddress}
																	className='text-xs mt-[1px] cursor-pointer underline'
																>
																	Use Google autocomplete
																</span>
															</div>
															<div className='flex w-[235px] gap-1'>
																<input
																	type="text"
																	name='city'
																	className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																	placeholder="City"
																	value={newBillingAddress.city}
																	onChange={e => handleNewAddressChange('newBillingAddress', 'city', e.target.value)}
																/>
																<input
																	type="text"
																	name='state'
																	className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																	placeholder="State"
																	value={newBillingAddress.state}
																	onChange={e => handleNewAddressChange('newBillingAddress', 'state', e.target.value)}
																/>
															</div>
															<div className='flex w-[235px] gap-1'>
																<input
																	type="text"
																	name='zip_code'
																	className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																	placeholder="Zip Code"
																	value={newBillingAddress.zip_code}
																	onChange={e => handleNewAddressChange('newBillingAddress', 'zip_code', e.target.value)}
																/>
																<div className="flex items-center border rounded-[5px] w-1/2 h-[30px] text-[12px]">
																	<select
																		id="country"
																		name="country"
																		className="w-full"
																		defaultValue={newBillingAddress?.country || ''}
																		onChange={e => handleNewAddressChange('newBillingAddress', 'country', e.target.value)}
																		>
																		<option value="" disabled>
																			Select Country
																		</option>
																		{Globals.countries.map(c => (
																			<option key={c.code} value={c.code}>
																				{c.name}
																			</option>
																		))}
																	</select>
																</div>
															</div>

														</>
													}
													<input
														type="text"
														className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
														placeholder="Phone Number"
														autoComplete='off'
														value={newBillingAddress.phone_number}
														onChange={e => handleNewAddressChange('newBillingAddress', 'phone_number', e.target.value)}
													/>
													<div className="flex items-center">
														<label htmlFor="save_to_contact_billing">
															<input
																type="checkbox"
																id="save_to_contact_billing"
																className="hidden peer"
																checked={newBillingAddress.save_to_contact}
																onChange={e => handleNewAddressChange('newBillingAddress', 'save_to_contact', e.target.checked)}
															/>
															<div className="flex items-center text-center">
																<span
																	htmlFor="save_to_contact_billing"
																	className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500 indeterminate:bg-gray-300"
																>
																	{newBillingAddress.save_to_contact && (
																		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
																			<path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0" />
																		</svg>
																	)}
																</span>
																<span className="mt-1 text-[12px] text-[#5D6E81] font-normal">Save Address to Contact</span>
															</div>
														</label>
													</div>
												</React.Fragment>
											) : (
												<React.Fragment>
													<p>{selectedBillingAddress?.company_name}</p>
													<p>{selectedBillingAddress?.contact_name}</p>
													<p>{selectedBillingAddress?.full_address}</p>
													<p>{selectedBillingAddress?.phone_number_formatted}</p>
												</React.Fragment>
											)}
										</div>
									</div>
									<div className="flex flex-col">
										<div className="flex items-center gap-x-[13px] mb-[11px]">
											<img src="./Images/location_detail.png" alt="" className="h-[24px] w-[24px]" />
											<span className="text-[#5D6E81] text-[14px] font-semibold">Shipping</span>
											{/* Customer form goes here */}
											<AddressDropdown
												addressOptions={customer.customer_shipping_addresses}
												addressType="shipping"
												selectedAddress={selectedShippingAddress}
												onSelect={handleSelectAddress}
												onAddNew={handleAddNewAddress}
											></AddressDropdown>
										</div>
										<div className="ms-[43px] max-w-[235px]">
											{newShippingAddress.visible ? (
												<React.Fragment>
													<input
														type="text"
														className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
														placeholder="Company Name"
														autoComplete='off'
														value={newShippingAddress.company_name}
														onChange={e => handleNewAddressChange('newShippingAddress', 'company_name', e.target.value)}
													/>
													<input
														type="text"
														className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
														placeholder="Contact Name"
														autoComplete='off'
														value={newShippingAddress.contact_name}
														onChange={e => handleNewAddressChange('newShippingAddress', 'contact_name', e.target.value)}
													/>

													{!isManualShippingAddress ?
														<>
															<div>
																<div className='flex w-[235px] gap-1'>
																	<ServerAutoSuggestAddress
																		formData={{full_address: newShippingAddress.full_address}}
																		handleChangeGoogleAddress={handleChangeShippingAddress}
																		className="border rounded-[5px] w-full h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		view={'memo'}
																	/>
																	<input
																		type="text"
																		name='apt_unit_number'
																		className="border rounded-[5px] w-[38%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		placeholder="Apt/Unit #"
																		value={newShippingAddress.apt_unit_number}
																		onChange={e => handleNewAddressChange('newShippingAddress', 'apt_unit_number', e.target.value)}
																	/>
																</div>
																<span
																	onClick={handleVisibleManualShippingAddress}
																	className='text-xs mt-[1px] cursor-pointer underline'
																>
																	Add Manually
																</span>
															</div>
														</>
														:
														<>
															<div>
																<div className='flex w-[235px] gap-1'>
																	<input
																		type="text"
																		className="border rounded-[5px] w-[70%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																		placeholder="Street"
																		name='address'
																		value={newShippingAddress.address}
																		onChange={e => handleNewAddressChange('newShippingAddress', 'address', e.target.value)}
																	/>
																	<input
																			type="text"
																			name='apt_unit_number'
																			className="border rounded-[5px] w-[30%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																			placeholder="Apt/Unit #"
																			value={newShippingAddress.apt_unit_number}
																			onChange={e => this.handleNewAddressChange('newShippingAddress', 'apt_unit_number', e.target.value)}
																	/>
																</div>
																<span
																	onClick={handleVisibleManualShippingAddress}
																	className='text-xs mt-[1px] cursor-pointer underline'
																>
																	Use Google autocomplete
																</span>
															</div>
															<div className='flex w-[235px] gap-1'>
																<input
																	type="text"
																	name='city'
																	className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																	placeholder="City"
																	value={newShippingAddress.city}
																	onChange={e => handleNewAddressChange('newShippingAddress', 'city', e.target.value)}
																/>
																<input
																	type="text"
																	name='state'
																	className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																	placeholder="State"
																	value={newShippingAddress.state}
																	onChange={e => handleNewAddressChange('newShippingAddress', 'state', e.target.value)}
																/>
															</div>
															<div className='flex w-[235px] gap-1'>
																<input
																	type="text"
																	name='zip_code'
																	className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																	placeholder="Zip Code"
																	value={newShippingAddress.zip_code}
																	onChange={e => handleNewAddressChange('newShippingAddress', 'zip_code', e.target.value)}
																/>
																<div className="flex items-center border rounded-[5px] w-1/2 h-[30px] text-[12px]">
																	<select
																		id="country"
																		name="country"
																		className="w-full"
																		defaultValue={newShippingAddress?.country || ''}
																		onChange={e => handleNewAddressChange('newShippingAddress', 'country', e.target.value)}
																		>
																		<option value="" disabled>
																			Select Country
																		</option>
																		{Globals.countries.map(c => (
																			<option key={c.code} value={c.code}>
																				{c.name}
																			</option>
																		))}
																	</select>
																</div>
															</div>

														</>
													}
													<input
														type="text"
														className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
														placeholder="Phone Number"
														autoComplete='off'
														value={newShippingAddress.phone_number}
														onChange={e => handleNewAddressChange('newShippingAddress', 'phone_number', e.target.value)}
													/>
													<div className="flex items-center">
														<label htmlFor="save_to_contact_shipping">
															<input
																type="checkbox"
																id="save_to_contact_shipping"
																className="hidden peer"
																defaultChecked={newShippingAddress.save_to_contact}
																onChange={e => handleNewAddressChange('newShippingAddress', 'save_to_contact', e.target.checked)}
															/>
															<div className="flex items-center text-center">
																<span
																	htmlFor="save_to_contact_shipping"
																	className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500 indeterminate:bg-gray-300"
																>
																	{newShippingAddress.save_to_contact && (
																		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
																			<path d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z" fill="#2C79E0" />
																		</svg>
																	)}
																</span>
																<span className="mt-1 text-[12px] text-[#5D6E81] font-normal">Save Address to Contact</span>
															</div>
														</label>
													</div>
												</React.Fragment>
											) : (
												<React.Fragment>
													<p>{selectedShippingAddress?.company_name}</p>
													<p>{selectedShippingAddress?.contact_name}</p>
													<p>{selectedShippingAddress?.full_address}</p>
													<p>{selectedShippingAddress?.phone_number_formatted}</p>
												</React.Fragment>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="hidden px-[39px] w-full mt-[21px] min-h-[153px] relative md:flex">
					<div className="w-1/2">
						<div className="text-[22px] text-[#5D6E81] font-bold">Order Details</div>
						<div className="flex flex-col pl-[22px] pr-[37px] pt-[20px] gap-y-[13px]">
							<div className="flex justify-between">
								<div>Invoice</div>
								<div>{invoiceData?.order_number}</div>
							</div>
							<div className="flex justify-between">
								<div>Order Date</div>
								<div>{Moment().format('MM/DD/YYYY')}</div>
							</div>
							<div className="flex justify-between">
								<div>Terms</div>
								<TermsDropdown
									termOptions={Globals.saleTerms}
									selectedTerm={selectedTerm}
									onSelect={handleSelectTerms}
									termCustomField={termCustomField}
									handleChangeTermCustomField={handleChangeTermCustomField}
									termCustomSelect={termCustomSelect}
									handleSelectCustomTerm={handleSelectCustomTerm}
								/>
							</div>
							{
								props.currentSpace.invoice_settings?.[0]?.visible_salesperson &&
								<div className="flex justify-between">
									<div>Salesperson</div>
									<UsersDropdown
										userOptions={loadedSpaceUsers}
										selectedUser={selectedSalesPerson}
										onSelect={handleSelectSalesPerson}
										className={"bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81] text-center w-[184px] truncate"}
									/>
								</div>
							}
							<div className="flex justify-between">
								<div>Tracking Number</div>
								<input
									name="tracking_number"
									value={trackingNumber}
									onChange={handleChangeTrackingNumber}
									onKeyDown={handleKeyDownTrackingNumber}
									ref={trackingNumberInputRef}
									className='bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81]
									w-[184px] text-end px-1'
								/>
							</div>
						</div>
					</div>
						<hr className="w-[1px] h-[153px] bg-[#E5E7EB] mt-10 mx-[20px]"/>
						<div className="w-1/2 pl-[22px]">
							{!isEmpty(invoiceData.payments) &&
								<>
									<div className="text-[22px] text-[#5D6E81] font-bold flex items-center gap-x-[37px]">
										<span>Payments</span>
									</div>
									<div className="flex flex-col">
										{
											invoiceData.payments.map((payment, index) => {
												return (
													<div className="py-3 text-sm flex items-center justify-between gap-x-2 px-3" key={index}>
														<div className="">
															<div className="text-[#5D6E81] font-medium">{payment.amount}</div>
															<div className="text-xs">{Moment(payment.date).format('M/DD/YYYY')} - {payment.user.full_name}</div>
														</div>
														<div className="flex gap-x-3 ">
															<button
																className="underline font-normal text-[12px] cursor-pointer"
																onClick={() => { handleEditPayment(payment)}}
															>
																Edit
															</button>
															<ConfirmPopUp
																title="Confirm deleting payment"
																content="Are you sure you want to delete this payment."
																onConfirm={(event) => handleDeletePayment(payment.id)}
															>
																<button className='underline font-normal text-[12px] cursor-pointer'>
																	<span>Delete</span>
																</button>
															</ConfirmPopUp>
														</div>
													</div>
												)
											})
										}
									</div>
								</>
							}
							{!isEmpty(invoiceData.credit_payments) &&
								<>
									<div className="text-[22px] text-[#5D6E81] font-bold flex items-center gap-x-[37px]">
										<span>Credit Payment</span>
									</div>
									<div className="flex flex-col">
										{
											invoiceData.credit_payments.map((creditPayment, index) => {
												return (
													<div className="py-3 text-sm flex items-center justify-between gap-x-2 px-3" key={index}>
														<div className="">
															<div className="text-[#5D6E81] font-medium">{creditPayment.amount}</div>
															<div className="text-xs">{Moment(creditPayment.date).format('M/DD/YYYY')} - {creditPayment.user.full_name}</div>
														</div>
														<div className="flex gap-x-3 ">
															<button
																className="underline font-normal text-[12px] cursor-pointer"
																onClick={() => { openEditCreditPayment(creditPayment)}}
															>
																Edit
															</button>
															<ConfirmPopUp
																title="Confirm deleting payment"
																content="Are you sure you want to delete this payment."
																onConfirm={(event) => handleDeleteCreditPayment(creditPayment.id)}
															>
																<button className='underline font-normal text-[12px] cursor-pointer'>
																	<span>Delete</span>
																</button>
															</ConfirmPopUp>
														</div>
													</div>
												)
											})
										}
									</div>
								</>
							}
							<div className="text-[22px] text-[#5D6E81] font-bold flex items-center gap-x-[37px]">
								<span>Comments</span>
								<img
									src="./Images/plus_radius.png"
									alt=""
									className="w-[22px] h-[22px]"
									onClick={() => handleToogleInputComment('new')}
								/>
							</div>
							<div className="flex flex-col">
								{
									orderComments.map((comment, index) => {
										return (
                      <>
                        <div className="py-3 text-sm flex items-center justify-between gap-x-2 px-3" key={index}>
                          <div className="">
                            <div className="text-[#5D6E81] font-medium">{comment.content}</div>
                            <div className="text-xs">{Moment(comment.created_at).format('M/DD/YYYY')} - {comment.user.full_name}</div>
                          </div>
                          <div className="flex gap-x-3 ">
                            <button
                              className="underline font-normal text-[12px] cursor-pointer"
                              onClick={() => handleToogleInputComment('edit', comment)}
                            >
                              Edit
                            </button>
                            <button
                              className="underline font-normal text-[12px] cursor-pointer"
                              onClick={(e) => handleDeleteComment(e, comment.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                        {isVisibleComments && selectedComment?.id === comment.id && (
                          <CommentForm
                            commentField={commentField}
                            setCommentField={setCommentField}
                            sendingComment={sendingComment}
                            selectedComment={selectedComment}
                            handleUpdateComment={handleUpdateComment}
                            handleSubmitComment={handleSubmitComment}
                          />
                        )}
                      </>
										)
									})
								}

								{isVisibleComments && isEmpty(selectedComment) && (
									<CommentForm
                    commentField={commentField}
                    setCommentField={setCommentField}
                    sendingComment={sendingComment}
                    selectedComment={selectedComment}
                    handleUpdateComment={handleUpdateComment}
                    handleSubmitComment={handleSubmitComment}
                  />
								)}
							</div>
						</div>

					</div>
					<div className="m-6 flex gap-4 items-center justify-center px-10 mx-auto gap-x-[16px]">
						<button
							className="transition w-[84px] h-[38px] rounded-[5px] border border-[#5d6e8147] text-[#5D6E81] font-semibold text-[12px] hover:bg-gray-300"
							onClick={handleClose}
						>
							Cancel
						</button>
						<button
							className="w-[66px] h-[38px] transition rounded-[5px] bg-[#2863EB] text-white text-[12px] hover:bg-blue-800"
							onClick={saveOrder}
						>
							Save
						</button>
					</div>
				</div>
			</div>
			<div className="max-h-screen flex flex-col bg-white single-customer-view bg-black/50 fixed top-0 inset-x-0 bottom-0 z-30 md:hidden" style={{maxWidth: '1100px', width: '100%'}}>
				<div className="h-[63px] flex items-center gap-3 bg-[#DEDEDE] rounded-tl-2xl py-4 px-4">
					<div className="flex flex-col md:flex-row md:items-center justify-between w-full">
						<div className="flex gap-1 justify-between">
							<div className='flex justify-between'>
								<button
									onClick={handleClose}
								>
									<svg xmlns="http://www.w3.org/2000/svg" width="27" height="24" viewBox="0 0 27 24" fill="none">
										<path fillRule="evenodd" clipRule="evenodd" d="M14.6022 6.35147C15.2661 6.8201 15.2661 7.5799 14.6022 8.04853L9.00426 12L14.6022 15.9515C15.2661 16.4201 15.2661 17.1799 14.6022 17.6485C13.9383 18.1172 12.8619 18.1172 12.198 17.6485L5.39801 12.8485C4.73412 12.3799 4.73412 11.6201 5.39801 11.1515L12.198 6.35147C12.8619 5.88284 13.9383 5.88284 14.6022 6.35147Z" fill="#5D6E81"/>
									</svg>
								</button>
							<h2 className="font-semibold text-3xl text-[#5D6E81]">Edit Order</h2>
							</div>
							<button onClick={() => {setIsVisibleDropdown(!isVisibleDropdown)}}>
								<svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
									<path d="M7.8 12.4H1.19209e-07V9.05H7.8V0.749998H11.15V9.05H19V12.4H11.15V20.75H7.8V12.4Z" fill="#4B7CBE"/>
								</svg>
							</button>
						</div>
						{isVisibleDropdown &&
								<div ref={selectRef}  className="absolute bg-white divide-gray-100 divide-y rounded shadow top-11 w-44 z-10 right-4">
									<ul className="py-1 text-xs	text-gray-700">
										<li>
											<button onClick={handleOpenProductHunter} className="flex justify-between items-center w-full text-left block py-2 px-4 hover:bg-gray-100 border-b-2">
												<span>
													Add Product
												</span>
												<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
													<path fillRule="evenodd" clipRule="evenodd" d="M7 0C7.55228 0 8 0.447715 8 1V6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H8V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55228 0 7C0 6.44771 0.447715 6 1 6L6 6V1C6 0.447715 6.44772 0 7 0Z" fill="#5D6E81"/>
												</svg>
											</button>
												<button onClick={handleOpenMiscProduct} className="flex justify-between items-center w-full text-left block py-2 px-4 hover:bg-gray-100 border-b-2">
													<span>
														Add Misc Product
													</span>
													<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
														<path fillRule="evenodd" clipRule="evenodd" d="M7 0C7.55228 0 8 0.447715 8 1V6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H8V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55228 0 7C0 6.44771 0.447715 6 1 6L6 6V1C6 0.447715 6.44772 0 7 0Z" fill="#5D6E81"/>
													</svg>
												</button>
											<button onClick={handleOpenTradeIn} className="flex justify-between items-center w-full text-left block py-2 px-4 hover:bg-gray-100">
												<span>
													Add Trade-In
												</span>
												<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
													<path fillRule="evenodd" clipRule="evenodd" d="M7 0C7.55228 0 8 0.447715 8 1V6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H8V13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V8H1C0.447715 8 0 7.55228 0 7C0 6.44771 0.447715 6 1 6L6 6V1C6 0.447715 6.44772 0 7 0Z" fill="#5D6E81"/>
												</svg>
											</button>
										</li>
									</ul>
								</div>
							}

					</div>
				</div>

				<div className="divide-y divide-gray-200 px-0 overflow-hidden">
					{isEmpty(invoicedItems) && isEmpty(miscItems) &&  isEmpty(tradeInItems)   && (
						<div className="flex h-full items-center justify-center">
							<button disabled type="button" className="text-white bg-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 inline-flex items-center">
								<svg role="status" className="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
										fill="#E5E7EB"
									/>{' '}
									<path
										d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
										fill="currentColor"
									/>
								</svg>
								<span>Loading order...</span>
							</button>
						</div>
					)}
					<div className="py-2 md:px-10 md:py-5 h-full overflow-y-auto">
						<div className="px-2 md:px-0">
							<div className="relative min-w-full border-b bg-transparent">
								<div className="min-w-full table-fixed">
									{!isEmpty(invoicedItems) && (
										<div>
											<SwipeableList fullSwipe={false} threshold={0.5} type={ListType.IOS}>
												{invoicedItems.map((orderItem, index) => {
													return (
														<SwipeableListItem
															trailingActions={trailingActions(() => {
																handleRemoveItem(orderItem)
															})}
															maxSwipe={0.2}
															type={ListType.IOS}
															actionDelay={10000}
															destructiveCallbackDelay={10000}
															{...props}
														>
															{!orderItem._destroy && (
																<div key={index} className="rounded-[5px] grid grid-cols-4 gap-2 border-[1px] cursor-pointer bg-white">
																	<div className="flex items-center py-4 px-2 text-sm font-normal tracking-worderer">
																		<div
																			className="bg-center bg-contain bg-white bg-no-repeat cursor-pointer h-16 md:h-24 m-auto w-16 md:w-24"
																			style={{
																				backgroundImage:
																					head(orderItem?.saleable?.images) !== undefined
																						? `url('${head(orderItem?.saleable?.images).url}')`
																						: `url('/watch_icon.png')`,
																			}}
																		></div>
																	</div>
																	<div className="flex flex-col justify-center text-[10px] font-normal tracking-worderer col-span-2">
																		<div>
																			<span className="font-semibold mr-1">SKU:</span>
																			{orderItem?.saleable?.sku}
																		</div>
																		<span className="min-w-[180px] tracking-worderer">{!isNull(orderItem?.saleable) && orderItem?.saleable?.name}</span>
																	</div>
																	<div className="flex items-start justify-end text-sm font-semibold tracking-worderer mt-2 mr-2">
																		<label className="absolute right-[58px] top-[10px]">$</label>
																		<input
																			onChange={e => handleItemPriceChange(e, orderItem)}
																			type="text"
																			className="border rounded max-w-[63px] pl-3 rounded-[19px]"
																			value={Globals.formatCurrency(orderItem?.price) || 0}
																		/>
																	</div>
																</div>
															)}
														</SwipeableListItem>
													)
												})}
											</SwipeableList>
										</div>
									)}
									{!isEmpty(tradeInItems) && (
										<div>
											<SwipeableList fullSwipe={false} threshold={0.5} type={ListType.IOS}>
												{tradeInItems.map((item, index) => {
													return (
														<SwipeableListItem
															trailingActions={trailingActions(() => handleRemoveTradeIn(index))}
															maxSwipe={0.2}
															type={ListType.IOS}
															actionDelay={10000}
															destructiveCallbackDelay={10000}
															{...props}
														>
															<div key={index + item.productName} className="rounded-[5px] grid grid-cols-4 gap-2 border-[1px] cursor-pointer bg-white">
																<div className="flex items-center py-4 px-2 text-sm font-normal tracking-worderer">
																	<div className="flex flex-col items-center">
																		<div
																			className="bg-center bg-contain bg-white bg-no-repeat cursor-pointer h-16 md:h-24 m-auto w-16 md:w-24"
																			style={{
																				backgroundImage: `url('/Images/swap_arrows_icon.png')`,
																			}}
																		></div>
																		<span className="font-semibold text-[10px]">Trade-In</span>
																	</div>
																</div>
																<div className="flex flex-col col-span-2 justify-center text-[10px] font-normal tracking-worderer">
																	<div className=" mb-[25px]">
																		<div className="text-[10px]">
																			<span className="font-semibold mr-1">Product:</span>
																			<span>{!isNull(item.product_name) && item.product_name}</span>
																		</div>
																		<div className="text-[10px">
																			<span className="font-semibold mr-1">SKU:</span>
																			<span>{item?.sku}</span>
																		</div>
																	</div>
																</div>
																<div className="flex items-start justify-end text-sm font-semibold tracking-worderer mt-2 mr-2">
																	<>-</>
																	<label className="absolute right-[58px] top-[10px]">$</label>
																	<input
																		onChange={e => handleTradeInPriceChange(e.target.value.replaceAll(',', ''), index)}
																		type="text"
																		className="border rounded max-w-[63px] pl-3 rounded-[19px]"
																		value={Globals.formatCurrency(item?.price) || 0}
																	/>
																</div>
															</div>
														</SwipeableListItem>
													)
												})}
											</SwipeableList>
										</div>
									)}
									{!isEmpty(miscItems) && (
										<div>
											<SwipeableList fullSwipe={false} threshold={0.5} type={ListType.IOS}>
												{miscItems.map((orderItem, index) => {
													return (
														<SwipeableListItem
															trailingActions={trailingActions(() => handleRemovingMiscItem(index))}
															maxSwipe={0.2}
															type={ListType.IOS}
															actionDelay={10000}
															destructiveCallbackDelay={10000}
															{...props}
														>
															<div key={index} className="rounded-[5px] grid grid-cols-4 gap-2 border-[1px] cursor-pointer bg-white">
																<div className="py-4 px-2 text-sm font-normal tracking-worderer">
																	<div className="flex flex-col items-center">
																		<div
																			className="bg-center bg-contain bg-white bg-no-repeat cursor-pointer h-16 md:h-24 m-auto w-16 md:w-24"
																			style={{
																				backgroundImage: `url('/Images/timer_watch_icon.png')`,
																			}}
																		></div>
																		<span className="font-semibold text-[10px]">Misc</span>
																	</div>
																</div>
																<div className="flex flex-col col-span-2 justify-center text-[10px] font-normal tracking-worderer">
																	<div className="mb-[25px]">
																		<div className="text-[10px]">
																			<span className="font-semibold mr-1">Product:</span>
																			<span>{!isNull(orderItem.product_name) && orderItem.product_name}</span>
																		</div>
																		<div className="text-[10px">
																			<span className="font-semibold mr-1">SKU:</span>
																			<span>{orderItem?.sku}</span>
																		</div>
																	</div>
																</div>
																<div className="flex items-start justify-end text-sm font-semibold tracking-worderer mt-2 mr-2">
																	<label className="absolute right-[58px] top-[10px]">$</label>
																	<input
																		onChange={e => handleSaleMiscPriceChange(index, e)}
																		type="text"
																		className="border rounded max-w-[63px] pl-3 rounded-[19px]"
																		value={Globals.formatCurrency(orderItem?.price) || 0}
																	/>
																</div>
															</div>
														</SwipeableListItem>
													)
												})}
											</SwipeableList>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="flex px-8 py-8 gap-2">
							<div className="flex flex-1 flex-col justify-between text-[#000000]">
								<div className='flex justify-between'>
									<div>SubtoTal</div>
									<div className='max-w-[100px] flex justify-end break-all'>
										{Globals.readCurrencyNumber(
											sum(invoicedItems.filter(t => !t._destroy).map(t => (t.price ? Globals.revertPrice(t.price) : 0))) -
												sum(tradeInItems.map(t => (t.price ? Globals.revertPrice(t.price) : 0))) +
												sum(miscItems?.map(t => (t.price ? Globals.revertPrice(t.price) : 0)))
										)}
									</div>

								</div>
								<div className='flex justify-between'>
									<div>Shipping</div>
										<div className="relative flex items-center justify-end break-all">
											<div className={classNames('flex justify-center ', {'items-start': isCustomerLabeled, 'items-center': !isCustomerLabeled})}>
												<button
													onClick={()=> setIsCustomerLabeled(prevState => !prevState)}
													className={classNames(' max-w-auto h-[21px] border rounded-[5px] px-1 flex items-center justify-center mr-[-6px]', {'bg-[#4B7CBE] mr-4': isCustomerLabeled})}
												>
													<span className={classNames("w-max text-[14px] text-[#4B7CBE]", {'text-white': isCustomerLabeled})}>Customer Label</span>
												</button>
												{!isCustomerLabeled
													?
													<div className="relative flex justify-end items-center">
														<label className="text-sm absolute left-[12px]">$</label>
														<input
															onChange={e => handleShippingChange(e)}
															type="text"
															className="border rounded max-w-[63px] pl-[10px] rounded-[19px] ml-2.5"
															value={shippingPrice ? shippingPrice : ''}
														/>
													</div> :
													<div className="flex justify-end break-all ml-2">
														<span className=' text-slate-400'>
															{Globals.moneyFormatter.format(shippingPrice)}
														</span>
													</div>
												}
											</div>
										</div>
								</div>
								{!invoiceDetails.is_tax_exempt && (
									<div className='flex justify-between'>
										<div>Tax</div>
										<div className='flex justify-end break-all'>
											{Globals.readCurrencyNumber(handleCalculateTax())}
										</div>
									</div>
								)}
								<div className='flex justify-between'>
									<div>Total</div>
									<div className='flex justify-end break-all  border-t-[1px] border-[#000000] min-w-[80px]'>
										{Globals.readCurrencyNumber(
											sum(invoicedItems.filter(t => !t._destroy).map(t => (t.price ? Globals.revertPrice(t.price) : 0))) -
												sum(tradeInItems.map(t => (t.price ? Globals.revertPrice(t.price) : 0))) +
												sum(miscItems?.map(t => (t.price ? Globals.revertPrice(t.price) : 0))) +
												(isCustomerLabeled ? 0 : Globals.revertPrice(shippingPrice))
										)}

									</div>
								</div>
								<div className='flex justify-between'>
									<div>Payment</div>
									<div className='w-[120px] flex justify-end break-all'>
										<div>({Globals.moneyFormatter.format(invoiceData.payment)})</div>
									</div>
								</div>
								<div className='flex justify-between'>
									<div>Balance</div>
									<div className='w-[100px] flex justify-end break-all text-[#4B7CBE]'>
											{Globals.readCurrencyNumber(
												sum(invoicedItems.filter(t => !t._destroy).map(t => (t.price ? Globals.revertPrice(t.price) : 0))) -
													sum(tradeInItems.map(t => (t.price ? Globals.revertPrice(t.price) : 0))) +
													sum(miscItems?.map(t => (t.price ? Globals.revertPrice(t.price) : 0))) +
													(isCustomerLabeled ? 0 : Globals.revertPrice(shippingPrice)) -
													(invoiceData.payment ? Globals.revertPrice(invoiceData.payment) : 0)
											)}
										</div>
								</div>

							</div>
						</div>
						<div className="p-2 bg-[#F4F4F4]">
							<div>
								<div className="flex justify-between items-center">
									<span className="text-xl font-bold text-[#000000]">Customer Detail</span>
								</div>
								<div className="pt-2">
									<div className="flex">
										<img className="mr-2" src="/Images/company.svg" />
										<div className="text-[13px] font-normal max-w-[150px]">{invoiceData.customer.company_name}</div>
									</div>
								</div>
								<div className="pt-2">
									<div className="flex">
										<div>
											<img className="mr-2" src="/Images/location.svg" />
										</div>
										<div className="text-[13px] font-normal max-w-[150px]">
											{`${invoiceData.customer.company_address} ${invoiceData.customer.city} ${invoiceData.customer.state} ${invoiceData.customer.zip_code}`}
										</div>
									</div>
								</div>
								<div className="pt-2">
									<div className="flex items-center">
										<div>
											<img className="mr-2" src="/Images/phone.svg" />
										</div>
										<div className="text-[13px] font-normal max-w-[150px]">{invoiceData.customer.phone_number}</div>
									</div>
								</div>
								<div className="pt-2">
									<div className="flex items-center">
										<div>
											<img className="mr-2" src="/Images/mail.svg" />
										</div>
										<div className="text-[13px] font-normal max-w-[150px]">{invoiceData.customer.email}</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="p-2 mt-4 md:p-0">
								<div>
									<div className="font-semibold text-xl text-[#000000]">Payments</div>
									{isEmpty(invoiceData.payments) && <p className="text-sm pt-3">No order Payment yet</p>}
									{!isEmpty(invoiceData.payments) &&
										invoiceData.payments.map((payment, index) => {
											return (
												<div className="py-3  ml-5 text-sm" key={index}>
													<div className="text-[13px] font-normal	">${Globals.moneyFormatter.format(payment.amount).replace('.00', '').replace('$', '')}</div>
													<div className="flex ml-2 text-[8px]">
														<span>{Moment(payment.date).format('M/DD/YY')}</span>
														<div className="ml-2 flex gap-2 text-[6px] font-normal text-[#FF0000]">
															<button onClick={() => { handleEditPayment(payment)}}>Edit</button>
															<ConfirmPopUp
																title="Confirm deleting payment"
																content="Are you sure you want to delete this payment."
																onConfirm={(event) => handleDeletePayment(payment.id)}
															>
																<button>
																	<span>Delete</span>
																</button>
															</ConfirmPopUp>
														</div>
													</div>
												</div>
											)
										})}
								</div>
								<div
									className="mt-4 md:mt-0 md:divide-y"
									style={{
										maxWidth: 600,
									}}
								>
									<div className="flex items-cente">
										<div className="font-semibold text-xl text-[#000000] mr-2">Comments</div>
										<button
											onClick={() => {
												setOpenCommentModal(true)
												setIsAddCommentModal(true)
											}}
										>
											<img src="/Images/add_comment.svg" />
										</button>
									</div>
									{isEmpty(orderComments) && <p className="text-sm pt-2 ml-5 md:pt-3 md:ml-0">No order comments yet</p>}
									{!isEmpty(orderComments) &&
										orderComments.map((comment, index) => {
											return (
												<div className="py-2 ml-5 md:py-3 md:ml-0 text-sm" key={index}>
													<div className="text-[13px] font-normal	md:text-lg md:font-bold">{comment.content}</div>
													<div className="hidden text-xs italic md:block">{Moment(comment.created_at).format('MM/DD/YYYY')}</div>
													<div className="flex ml-2 text-[8px]">
													{Moment(comment.created_at).format('M/DD/YY')} - {comment.user.full_name}
														<div className="ml-2 flex gap-2 text-[6px] font-normal text-[#FF0000]">
															<button onClick={() => handleEditComment(comment)}>Edit</button>
															<ConfirmPopUp
																title="Confirm deleting comment"
																content="Are you sure you want to delete this comment."
																onConfirm={(event) => handleDeleteComment(event, comment.id)}
															>
																<button>
																	<span>Delete</span>
																</button>
															</ConfirmPopUp>
														</div>
													</div>
												</div>
											)
										})}
								</div>
							</div>
						</div>
						<div className="flex flex-row mt-4 justify-center space-x-4">
							<button onClick={handleClose} className="border-2 cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text[#000000] text-xs">
								Cancel
							</button>
							<button onClick={saveOrder} className="bg-blue cursor-pointer font-bold leading-5 my-2 px-8 py-2 rounded-md text-white text-xs uppercase" type="submit">
								Save
							</button>
						</div>
					</div>
				</div>
			</div>

      {openNoteModal && (
        <AddNoteForLineItem
          noteValue={saleItemSelected?.note}
          handleChangeNote={setNoteData}
          handleClose={handleCloseNoteModal}
          isEdit={!isEmpty(saleItemSelected?.note) ? true : false}
          addToast={props.addToast}
        />
      )}
		</>
	)
}

export default EditSale
