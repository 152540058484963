import {bindActionCreators} from 'redux'
import {Globals} from './Context'
import {useEffect, useState, useRef} from 'react'
import ActionCable from 'actioncable'
import {useNavigate} from 'react-router-dom'
import { isGuestMode } from './utils/guestMode'
import StripePlansModal from './Components/StripePlansModal'

import {
	addToast,
	getNotifications,
	getUnopenedConversationCount,
	getConversationsCount,
	handleGetGlobalColumnConfigs,
	logOut
} from './app/appActions'

import {connect} from 'react-redux'

let space
let cableApp = {}

function RouterWrapper(props) {
	const navigate = useNavigate()

	const {
		currentSpace,
		currentProfile,
		notificationsData,
		children
	} = props

	space = currentSpace

  const isSubscriptionInactiveOrNotPickSpacePage = space?.internal_subscription?.subscription_expired && location.pathname !== '/pickspace'

  const trialEndRef = useRef(null)

  const [isOpenPlans, setIsOpenPlans] = useState(false);

  // redirect to pick space page if trialEndRef has been remove
  useEffect(() => {
    if (!isSubscriptionInactiveOrNotPickSpacePage) return;
    if (!trialEndRef.current) return;

    if (isSubscriptionInactiveOrNotPickSpacePage && location.pathname !== '/inventory') {
      window.location.replace('/inventory')
    }

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
          mutation.removedNodes.forEach(node => {
            if (node === trialEndRef.current) {
              window.location.reload();
            }
          });
        }

        if (mutation.type === 'attributes' && mutation.attributeName === 'style' && mutation.target === trialEndRef.current) {
          window.location.reload();
        }

        if (mutation.type === 'attributes' && mutation.attributeName === 'class' && mutation.target === trialEndRef.current) {
          window.location.reload();
        }
      }
    });

    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

	// use global space to fix stale closure
	const newMessageToast = async data => {
		if (!!space?.id && !!data.receiver_space_id && data.receiver_space_id === space.id) {
			const content = JSON.parse(data.content)
			let message = ''
			await content.map(item => {
				message = `${message} ${item.children[0].text}`
			})
			props.addToast({
				title: `Message from: ${data.space_name}`,
				text: message,
				type: 'messages',
				onClick: () => {
					navigate(`/messages/${data.conversation_id}`)
				}
			})
		}
	}

	useEffect(() => {
		const {
			getNotifications,
			getUnopenedConversationCount,
			getConversationsCount,
			handleGetGlobalColumnConfigs
		} = props
		if (isGuestMode(location.pathname)) {
			return
		}

		getNotifications()
		getUnopenedConversationCount()
		getConversationsCount()
		handleGetGlobalColumnConfigs(currentSpace)

		return () => {
			cableApp?.cable1.disconnect()
		}
	}, [])

	useEffect(() => {
		const {
			getNotifications,
			getUnopenedConversationCount,
			getConversationsCount,
			handleGetGlobalColumnConfigs
		} = props

		cableApp.cable1 = ActionCable.createConsumer(`${Globals.actionCableURL}?access_token=${Globals.getAccessToken()}`)

		cableApp.cable1.subscriptions.create(
			{
				channel: 'ActivityNotification::NotificationApiChannel',
				target_type: 'User',
				target_id: currentProfile.id
			},
			{
				connected: function() {
					// Connected
				},
				disconnected: function() {
					// Disconnected
				},
				rejected: function() {
					// Rejected
				},
				received: function(response) {
					if (isGuestMode(location.pathname)) {
						return
					}

					newMessageToast(response.data.notifiable)
					getNotifications()
					getUnopenedConversationCount()
					getConversationsCount()
				}
			}
		)

		cableApp.cable1.subscriptions.create(
			{
				channel: 'SpacesUsersChannel',
				user_id: currentProfile.id,
				space_id: currentSpace.id
			},
			{
				connected: function() {
					// Connected

				},
				disconnected: function() {
					// Disconnected
				},
				rejected: function() {
					// Rejected
				},
				received: function(response) {
					if (response.action === 'log_out') {
						props.logOut();
						return window.location.replace('/login')
					} else if(response.action === 'pick_space') {
						return window.location.replace('/pickspace')

					}
				}
			}
		)

		cableApp.cable1.subscriptions.create({channel: 'WatchColumnsConfigurationChannel', space_id: currentSpace.id}, {
			connected: () => {
				console.log('Connected to Watch Columns Configuration Channel')
			},
			received: (data) => {
				handleGetGlobalColumnConfigs(currentSpace)
			}
		})

		cableApp.cable1.subscriptions.create({channel: 'SubscriptionUpgradeChannel', space_id: currentSpace.id}, {
			connected: () => {
				console.log(`Connected to SubscriptionUpgradeChannel`)
			},
			received: async (data) => {
				setTimeout(() => {
					window.location.reload()
				}, 1000)
			}
		})

		return () => {
			cableApp?.cable1.disconnect()
		}
	}, [currentProfile.id, notificationsData])

	const openBillingPortal = () => {
    if (space?.internal_subscription?.status === 'canceled') {
      setIsOpenPlans(true)
    } else {
      Globals.New_Axios().post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${space.id}/internal_subscriptions/billing_portal`)
      .then(response => {
        const url = response.data.url
        window.location.href = url
      }
    )
    }
	}

  const handleSwitchSpaces = async () => {
    await Globals.Cache.deleteStorage(Globals.USERSELECTEDSPACE)
    await Globals.Cache.deleteCookie(Globals.USERSELECTEDSPACE)
    window.location.replace('/pickspace')
  }

	if (isGuestMode(location.pathname)) {
		return children
	}

  return (
    <>
      {isSubscriptionInactiveOrNotPickSpacePage && (
        <div className="fixed top-0 w-screen h-screen bg-black/50 flex items-center justify-center z-50 py-10" ref={trialEndRef}>
          <div className="bg-white drop-shadow-xl w-full overflow-hidden" style={{maxWidth: '1045px'}}>
            <div className="flex items-center justify-between h-[493px] max-h-[80dvh]">
              <div className="w-[216px] grow-1 h-full bg-[#5D6E81] flex items-center justify-center">
              </div>
              <div className="w-[829px] h-full bg-white pt-[130px] px-[30px] pb-[30px] font-poppins">
                <p className="text-[#5D6E81] text-[40px] font-semibold mb-5">Your 30-day trial has ended</p>
                <p className="text-[#5D6E81] text-[22px] mb-12">Want to continue using Elefta to increase your productivity and manage your watches?</p>
                <div className='group-buttons flex gap-4'>
                  {/* See Plans & Switch Spaces */}
                  <button className="h-[45px] bg-[#2C79E0] text-white text-lg flex items-center justify-center p-[7.5px_37px] rounded-sm" onClick={openBillingPortal}>
                    See Plans
                  </button>
                  {currentProfile?.spaces?.length > 1 && (
                    <button className="h-[45px] text-[#5D6E81] text-lg flex items-center justify-center p-[7.5px_2px] rounded-sm" onClick={handleSwitchSpaces}>
                      Switch Spaces
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isOpenPlans && (
            <StripePlansModal setIsOpen={setIsOpenPlans} space={space} />
          )}
        </div>
      )}
      {children}
    </>
  )
}


const mapStateToProps = (state, props) => {
	return {
		currentSpace: state.currentSpace,
		currentProfile: state.profile,
		notificationsData: state.notificationsData
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
		addToast,
		getNotifications,
		getUnopenedConversationCount,
		getConversationsCount,
		handleGetGlobalColumnConfigs,
		logOut,
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterWrapper)
