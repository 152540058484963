import Classnames from 'classnames'
import React, {createRef} from 'react'
import {connect} from 'react-redux'
import {formatPhoneNumberIntl} from 'react-phone-number-input'

import {addToast, toggleSidebar} from './../app/appActions'
import {Globals} from '../Context'
import SideBar from '../Components/SideBar'
import TopBar from '../Components/TopBar'
import EleftaTable from '../Components/EleftaTable/EleftaTable'
import Tooltip from '@mui/material/Tooltip'
import ActionCable from "actioncable";

import AddNewCustomer from '../Components/Customers/AddNewCustomer'
import EditCustomer from '../Components/Customers/EditCustomer'
import CustomerDetail from '../Components/Customers/CustomerDetail'
import {focusElement, onPressFocusSearchInput} from '../utils/keyboardHandler'

import 'react-phone-number-input/style.css'
import isFunction from 'lodash/isFunction'
import isNull from 'lodash/isNull'
import unionBy from 'lodash/unionBy'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'

let cableApp = {}

const TableColumnComponent = props => {
	const _triangleIcon = (
		<svg width="10" height="8" viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
			<path d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
		</svg>
	)

	const handleSwitchOrder = () => {
		if (isFunction(props.sortAction)) return props.sortAction(props.data.key)
	}

	return (
		<div className="relative" onClick={handleSwitchOrder}>
			<Tooltip title={props.data.title} placement="bottom" arrow>
				<span className="truncate block w-full">{props.data.title}</span>
			</Tooltip>
			<span
				className={Classnames('absolute order-button -right-2 top-1 text-gray-400 transition-all', {
					'text-blue-500': props.data.key === props.orderby,
					rotate: props.order === 'ASC' && props.orderby === props.data.key,
				})}
			>
				{_triangleIcon}
			</span>
		</div>
	)
}

class CustomersView extends React.Component {
	constructor() {
		super()
		this.searchCustomerInputRef = createRef()
		this.state = {
			visibleSingleCustomerView: false,
			singleCustomerView: null,
			order: 'DESC',
			orderby: 'created_at',
			sendingData: false,
			customerFormVisible: false,
			customerEditFormVisible: false,
			form: {},
			formErrors: {},
			editForm: {},
			phoneNumber: null,
			secondaryPhoneNumber: null,
			secondaryPhoneNumberVisible: false,
			phoneCountry: null,
			editFormErrors: {},
			searchKeys: null,
			loadedCustomers: [],
			originalLoadedCustomers: [],
			customerTable: null,
			loadingMoreData: false,
			loadingData: false,
			isPrimaryTab: false,
			pagination: {
				nextPage: 1,
				totalPages: 1,
				totalCount: 0,
				totalEntries: 0,
				currentPage: 1,
			},
			loadedCustomersColumns: [
				{
					enabled: true,
					key: 'company_name',
					title: 'Company',
					data: function (response) {
						return <TableColumnComponent data={this} title={this.title || ""} sortAction={response.sortAction} orderby={response.orderby} order={response.order} />
					},
					render: function (item, data) {
						try {
							return <div className="py-3 whitespace-nowrap">{item}</div>
						} catch (error) {
							return <div>-</div>
						}
					},
				},
				{
					enabled: true,
					key: 'name',
					title: 'Name',
					data: function (response) {
						return <TableColumnComponent data={this} title={this.title} sortAction={response.sortAction} orderby={response.orderby} order={response.order} />
					},
					render: function (item, data) {
						try {
							return <div className="py-3 whitespace-nowrap">{item || ""}</div>
						} catch (error) {
							return <div>-</div>
						}
					},
				},


				{
					enabled: true,
					key: 'company_address',
					title: 'Address',
					data: function (response) {
						return <TableColumnComponent data={this} title={this.title} sortAction={response.sortAction} orderby={response.orderby} order={response.order} />
					},
					render: function (item, data) {
						try {
							const customerAddress = data.customer_addresses[0]
							return <div className="py-3 whitespace-nowrap">{customerAddress?.full_address}</div>
						} catch (error) {
							return <div>-</div>
						}
					},
				},
				{
					enabled: true,
					key: 'phone_number',
					title: 'Phone',
					data: function (response) {
						return <TableColumnComponent data={this} title={this.title} sortAction={response.sortAction} orderby={response.orderby} order={response.order} />
					},
					render: function (item, data) {
						try {
							const customerPhoneNumer = data.customer_phone_numbers[0]
							return <div className="py-3 whitespace-nowrap">{customerPhoneNumer?.phone_number_formatted}</div>
						} catch (error) {
							return <div>-</div>
						}
					},
				},
				{
					enabled: true,
					key: 'email',
					title: 'Email',
					data: function (response) {
						return <TableColumnComponent data={this} title={this.title || ""} sortAction={response.sortAction} orderby={response.orderby} order={response.order} />
					},
					render: function (item, data) {
						return <div>{item}</div>
					},
				},
			],
		}
	}

	componentDidMount() {
		if (!this.props.loggedin) return window.location.replace('/login')

		this.handleLoadCustomers()
		window.addEventListener('keydown', this.handleKeyDown)
		cableApp?.cable?.disconnect()
		cableApp.cable = ActionCable.createConsumer(`${Globals.actionCableURL}?access_token=${Globals.getAccessToken()}`)
		cableApp.cable.subscriptions.create({channel: "CustomerChannel", space_id: this.props.currentSpace.id}, {
			received: (data) => {
				if(this.state.isPrimaryTab) return this.setState({isPrimaryTab: false})
				if (data.action === 'create') {
					Globals.New_Axios()
					.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers/${data.customer_id}`)
					.then(async response => {
						this.handleGetCustomer(response.data)
					}).catch(error => {
						console.log(error)
					})
				}	else if (data.action === 'update') {
					Globals.New_Axios()
					.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers/${data.customer_id}`)
					.then(async response => {
						this.uploadCustomerListWhenEdit(response.data)
					}).catch(error => {
						console.log(error)
					})
				}
			}
		})
	}

	componentWillUnmount = () => {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	handlePrimaryTab = () => {
		this.setState({isPrimaryTab: true})
	}

	handleGetCustomer = (customer) => {
		this.setState({
			loadedCustomers: [customer, ...this.state.loadedCustomers],
			originalLoadedCustomers: [customer, ...this.state.originalLoadedCustomers]
		})
	}


	handleKeyDown = event => {
		const {visibleSingleCustomerView, customerEditFormVisible} = this.state
		if (visibleSingleCustomerView || customerEditFormVisible) return
		onPressFocusSearchInput(event, this.searchCustomerInputRef.current)
	}

	handleLoadCustomers = async (isScroll = false) => {
		if (isScroll) this.setState({loadingMoreData: true})
		else this.setState({loadingData: true})

		let sortOrder = `q[s]=${this.state.orderby} ${this.state.order}`
		sortOrder = !isNull(this.state.searchKeys)
			? `${sortOrder}&q[company_name_or_name_or_email_or_phone_number_or_phone_country_or_certificate_or_customer_addresses_address_or_customer_phone_numbers_phone_number_i_cont]=${this.state.searchKeys}`
			: sortOrder

		let queryParams=''
		if (isScroll) {
			queryParams = `&page=${this.state.pagination.nextPage}`
		}

		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers?${sortOrder}${queryParams}`)
			.then(response => {
				const {customers, current_page, next_page, total_entries, total_pages} = response.data

				if (response.status && response.status >= 200 && response.status < 300) {
					let newCusomerList
					if (isScroll) {
						newCusomerList = unionBy(this.state.loadedCustomers, customers, 'id')
					} else {
						newCusomerList = unionBy(customers, this.state.loadedCustomers, 'id')
					}

					this.setState({
						loadedCustomers: newCusomerList,
						originalLoadedCustomers: newCusomerList,
						pagination: {
							nextPage: next_page ?? 1,
							totalEntries: total_entries,
							totalPages: total_pages,
							currentPage: current_page,
						},
						loadingData: false,
						loadingMoreData: false,
					})
				}
			})
			.catch(error => {
				this.setState({
					loadingData: false,
					loadingMoreData: false,
				})
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return this.props.addToast({
					text: message,
					type: 'error',
				})
			})
	}

	showSecondaryPhoneNumber = () => {
		this.setState({
			secondaryPhoneNumberVisible: true,
		})
	}

	onHandle = (e, i, numbers) => {
		let newObj = numbers
		newObj[i]['phone_number'] = e
	}

	handleCloseForm = $event => {
		if ($event.detail !== 0) {
			$event.preventDefault()
			this.setState({
				customerFormVisible: false,
				sendingData: false,
				form: {},
				formErrors: {},
			})
		} else if (this.state.customerFormVisible) {
			// when press esc on keyboard
			this.setState({
				customerFormVisible: false,
				sendingData: false,
				form: {},
				formErrors: {},
			})
		}
	}

	handleClickOut = $event => {
		if ($event.target.classList.contains('single-customer-view')) {
			this.setState({
				visibleSingleCustomerView: false,
				singleCustomerView: null,
			})
		}
	}

	handleRowClick = $data => {
		this.setState({
			visibleSingleCustomerView: true,
			singleCustomerView: $data,
		})
	}
	setVisibleSingleCustomerView = () => {
		this.setState({
			visibleSingleCustomerView: false,
			singleCustomerView: null,
		})
	}

	handleRemoveNumber = i => {
		this.setState({
			editForm: {
				...this.state.editForm,
				secondary_phone_number: this.state.editForm.secondary_phone_number.filter((item, index) => index !== i),
			},
		})
	}

	handleOpenEditForm = $event => {
		this.setState({phoneNumber: this.state.singleCustomerView.phone_number})
		this.setState({secondaryPhoneNumber: this.state.singleCustomerView.secondary_phone_number})
		this.setState({phoneCountry: this.state.singleCustomerView.phone_country})
		this.setState({
			customerEditFormVisible: true,
			editForm: {
				id: this.state.singleCustomerView.id,
				first_name: this.state.singleCustomerView.first_name,
				last_name: this.state.singleCustomerView.last_name,
				email: this.state.singleCustomerView.email,
				company_name: this.state.singleCustomerView.company_name,
				country: this.state.singleCustomerView.country,
				apt_unit_number: this.state.singleCustomerView.apt_unit_number,
				city: this.state.singleCustomerView.city,
				state: this.state.singleCustomerView.state,
				company_address: this.state.singleCustomerView.company_address,
				zip_code: this.state.singleCustomerView.zip_code,
				phone_number: this.state.singleCustomerView.phone_number,
				website: this.state.singleCustomerView.website,
				certificate: this.state.singleCustomerView.certificate,
				phone_country: this.state.singleCustomerView?.phone_country,
				secondary_phone_number: this.state.singleCustomerView.secondary_phone_number || '',
			},
		})
	}

	handlePhone = (phone, inputName) => {
		const phoneNumber = phone || ''
		switch (inputName) {
			case 'phone_number':
				this.setState({phoneNumber: phoneNumber})
				break
			case 'secondary_phone_number':
				this.setState({secondaryPhoneNumber: phoneNumber})
				break
			default:
		}

		this.setState({
			editForm: {
				...this.state.editForm,
				[inputName]: formatPhoneNumberIntl(phoneNumber),
			},
		})
	}

	handleEditChange = $event => {
		if ($event.target.name === 'phonenumber') {
			this.setState({
				editForm: {
					...this.state.editForm,
					[$event.target.name]: this.formatPhoneNumber($event.target.value.trim()),
				},
			})
		} else {
			this.setState({
				editForm: {
					...this.state.editForm,
					[$event.target.name]: $event.target.value.trim(),
				},
			})
		}
	}

	handleCloseEditForm = $event => {
		$event.preventDefault()
		this.setState({
			customerEditFormVisible: false,
			sendingData: false,
			editForm: {},
			editFormErrors: {},
		})
		focusElement('.single-customer-view')
	}

	handleEditSubmit = $event => {
		$event.preventDefault()

		const state = this.state

		if (state.sendingData) return //Block user from sending data before the previous request is processed.

		let errors = []

		if (isEmpty(state.editForm.first_name)) errors = {...errors, first_name: 'First name is required'}
		if (isEmpty(state.editForm.last_name)) errors = {...errors, last_name: 'Last name is required'}
		if (isEmpty(state.editForm.email)) errors = {...errors, email: 'Email is required'}
		if (isEmpty(state.editForm.company_name)) errors = {...errors, company_name: 'Company name is required'}
		if (isEmpty(state.editForm.country)) errors = {...errors, country: 'Country is required'}
		if (isEmpty(state.editForm.city)) errors = {...errors, city: 'City is required'}
		if (isEmpty(state.editForm.state)) errors = {...errors, state: 'State is required'}
		if (isEmpty(state.editForm.company_address)) errors = {...errors, company_address: 'Company Address is required'}
		if (isEmpty(state.editForm.zip_code)) errors = {...errors, zip_code: 'Zip Code is required'}
		if (isEmpty(state.editForm.phone_number)) errors = {...errors, phone_number: 'Phone number is required'}
		if (isEmpty(state.editForm.certificate)) errors = {...errors, certificate: 'Resale certificate is required'}

		this.setState({editFormErrors: errors})

		if (!isEmpty(errors)) {
			return Object.values(errors)
				.reverse()
				.map(error => {
					return this.props.addToast({
						title: 'Cannot edit contact',
						text: error,
						type: 'error',
					})
				})
		}

		this.setState({sendingData: true})

		return Globals.New_Axios()
			.patch(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers/${state.editForm.id}`, {
				customer: state.editForm,
			})
			.then(response => {
				this.setState({sendingData: false})

				if (response.status && response.status >= 200 && response.status < 300) {
					//this.handleLoadCustomers()

					const customer = response.data

					const loadedCustomers = this.state.loadedCustomers.map(_customer => {
						if (_customer.id === customer.id) _customer = customer
						return _customer
					})

					const originalLoadedCustomers = this.state.originalLoadedCustomers.map(_customer => {
						if (_customer.id === customer.id) _customer = customer
						return _customer
					})
					this.setState({
						loadedCustomers: loadedCustomers,
						originalLoadedCustomers: originalLoadedCustomers,
						singleCustomerView: customer,
						customerEditFormVisible: false,
						sendingData: false,
						editForm: {},
						editFormErrors: {},
					})

					return this.props.addToast({
						title: 'Inventory Customers',
						text: 'Customer has been updated successfully',
						type: 'success',
					})
				}

				return this.props.addToast({
					text: response.data.errors,
					type: 'error',
				})
			})
			.catch(error => {
				this.setState({sendingData: false})
				return this.props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	uploadCustomerListWhenEdit = customer => {
		const loadedCustomers = this.state.loadedCustomers.map(_customer => {
			if (_customer.id === customer.id) _customer = customer
			return _customer
		})

		const originalLoadedCustomers = this.state.originalLoadedCustomers.map(_customer => {
			if (_customer.id === customer.id) _customer = customer
			return _customer
		})
		this.setState({
			loadedCustomers: loadedCustomers,
			originalLoadedCustomers: originalLoadedCustomers,
			singleCustomerView: customer,
			customerEditFormVisible: false,
			sendingData: false,
		})
	}

	handleSearchDebounce = debounce($event => {
		let searchString = $event.target.value

		this.setState(
			{
				loadedCustomers: [],
				originalLoadedCustomers: [],
				searchKeys: searchString,
				pagination: {
					nextPage: 1,
					totalPages: 1,
					totalCount: 0,
					totalEntries: 0,
					currentPage: 1,
				},
			},
			() => {
				this.handleLoadCustomers()
			}
		)
	}, 1000)

	handleSearchCustomer = $event => {
		this.handleSearchDebounce($event)
	}

	handleOrderSwitch = (requestKey) => {
		let order = this.state.order
		if(requestKey !== this.state.orderby) {
			order = 'DESC'
		}
		this.setState({
			order: order === 'ASC' ? 'DESC' : 'ASC',
			orderby: requestKey,
		}, () => {
			this.handleLoadCustomers()
		})
	}

	handleDeleteCustomer = $event => {
		$event.preventDefault()

		const state = this.state

		if (state.sendingData) return //Block user from sending data before the previous request is processed.

		this.setState({sendingData: true})

		return Globals.New_Axios()
			.delete(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers/${this.state.singleCustomerView.id}`)
			.then(response => {
				this.setState({sendingData: false})

				if (response.status && response.status >= 200 && response.status < 300) {
					const loadedCustomers = this.state.loadedCustomers.filter(customer => customer.id !== this.state.singleCustomerView.id)
					const originalLoadedCustomers = this.state.originalLoadedCustomers.filter(customer => customer.id !== this.state.singleCustomerView.id)

					this.setState({
						loadedCustomers: loadedCustomers,
						originalLoadedCustomers: originalLoadedCustomers,
						visibleSingleCustomerView: null,
						singleCustomerView: null,
						customerEditFormVisible: false,
						sendingData: false,
						editForm: {},
						editFormErrors: {},
					})

					return this.props.addToast({
						title: 'Inventory Customers',
						text: 'Customer has been successfully removed',
						type: 'success',
					})
				}

				return this.props.addToast({
					text: response.data.errors,
					type: 'error',
				})
			})
			.catch(error => {
				this.setState({sendingData: false})
				return this.props.addToast({
					text: error.response?.data?.message || 'Something went wrong please try again later.',
					type: 'error',
				})
			})
	}

	formatPhoneNumber = target => {
		const input = target.replace(/\D/g, '').substring(0, 10) // First ten digits of input only
		const zip = input.substring(0, 3)
		const middle = input.substring(3, 6)
		const last = input.substring(6, 10)

		if (input?.length > 6) {
			return `(${zip}) ${middle} - ${last}`
		} else if (input?.length > 3) {
			return (target = `(${zip}) ${middle}`)
		} else if (input?.length > 0) {
			return (target = `(${zip}`)
		}
	}

	loadMoreData = e => {
		const state = this.state

		if (state.loadingMoreData || state.loadedCustomers.length >= state.pagination.totalEntries) return

		if (this.state.loadedCustomers.length < this.state.pagination.totalEntries) {
			this.handleLoadCustomers(true)
		}
	}

	render() {
		const state = this.state
		const props = this.props

		return (
			<React.Fragment>
				{props.loggedin && (
					<React.Fragment>
						<section className="relative">
							<TopBar toggleSideBar={() => props.toggleSidebar(!props.isOpenSideBar)} />
							<div className="flex relative">
								<SideBar isOpen={props.isOpenSideBar} />
								<main className="flex flex-col relative w-full h-screen overflow-y-hidden" style={{paddingTop: (props.currentSpace.should_show_upgrade_announcement) ? '6.6rem' : '4rem'}}>
									<div className="flex bg-white border justify-center content-center border-r-0 section-head p-5">
										<div className="flex flex-col items-center">
											<h1 className="font-semibold text-3xl">Contacts</h1>
										</div>
										<div className="flex relative w-full justify-center md:justify-end items-center space-x-2">
											<div>
												<div className="text-gray-400 relative">
													<input
														ref={this.searchCustomerInputRef}
														className="text-gray-700 text-xs bg-white border flex items-center max-w-xs outline-blue-300 px-3 pr-9 relative rounded w-full md:w-screen h-11"
														type="text"
														name="search"
														placeholder="Search Here..."
														onChange={this.handleSearchCustomer}
													/>
													<button className="pointer-events-none absolute right-0 top-0 h-full px-3">
														<svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
															<path
																fillRule="evenodd"
																d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
																clipRule="evenodd"
															/>
														</svg>
													</button>
												</div>
											</div>
											<div>
												<Tooltip title="Add new contact" placement="bottom" arrow>
													<button
														className="bg-white border flex items-center px-3 py-[11px] relative rounded w-full"
														onClick={$event =>
															this.setState({
																customerFormVisible: true,
															})
														}
													>
														<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
															<path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
														</svg>
														<span className="ml-1 font-normal text-sm text-center">Add</span>
													</button>
												</Tooltip>
											</div>
										</div>
									</div>
									<div className="bg-white overflow-hidden rounded-tl-lg w-full h-full">
										<div
											className="w-full h-full relative overflow-x-scroll border-t"
										>
											<div className="flex flex-col h-full relative  ">
												<div className="sm:rounded-lg h-full relative">
													<EleftaTable
														separation
														order={state.order}
														orderBy={state.orderby}
														loadMoreData={this.loadMoreData}
														switchOrder={this.handleOrderSwitch}
														columns={state.loadedCustomersColumns}
														dataSource={state.loadedCustomers}
														onRowClick={this.handleRowClick}
														kind={'customer'}
													/>
												</div>
											</div>
										</div>
									</div>
									{state.loadingData && (
										<div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
											<button
												type="button"
												className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
												disabled
											>
												<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													{' '}
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
													<path
														className="opacity-75"
														fill="currentColor"
														d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
													/>
												</svg>
												<span>Loading... </span>
											</button>
										</div>
									)}
								</main>
							</div>
						</section>

						{state.visibleSingleCustomerView && (
							<CustomerDetail
								singleCustomerView={state.singleCustomerView}
								handleClickOut={this.handleClickOut}
								setVisibleSingleCustomerView={this.setVisibleSingleCustomerView}
								handleOpenEditForm={this.handleOpenEditForm}
								props={this.props}
							/>
						)}

						{state.customerEditFormVisible && (
							<EditCustomer
								handleCloseEditForm={this.handleCloseEditForm}
								props={this.props}
								handleLoadCustomers={this.handleLoadCustomers}
								singleCustomerView={this.state.singleCustomerView}
								uploadCustomerListWhenEdit={this.uploadCustomerListWhenEdit}
								handleDeleteCustomer={this.handleDeleteCustomer}
								handlePrimaryTab={this.handlePrimaryTab}
							/>
						)}

						{state.customerFormVisible && <AddNewCustomer handleCloseForm={this.handleCloseForm} props={this.props} handleLoadCustomers={this.handleLoadCustomers} handlePrimaryTab={this.handlePrimaryTab}/>}
					</React.Fragment>
				)}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		isOpenSideBar: state.isOpenSideBar,
		loggedin: state.loggedin,
		currentSpace: state.currentSpace,
	}
}

const mapActionsToProps = {
	addToast,
	toggleSidebar,
}

export default connect(mapStateToProps, mapActionsToProps)(CustomersView)
