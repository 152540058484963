import Classnames from 'classnames'
import React from 'react'
import {connect} from 'react-redux'
import {addToast, toggleSidebar, toggleDetailsCardInventory} from './../app/appActions'
import {Globals} from '../Context'
import SideBar from '../Components/SideBar'
import TopBar from '../Components/TopBar'
import Tooltip from '@mui/material/Tooltip'
import Moment from 'moment'
import ActionCable from 'actioncable'

import AddNewCustomer, {formatPhoneNumber} from '../Components/Customers/AddNewCustomer'
import ServerAutoSuggestAddress from '../Components/ServerAutoSuggestAddress'

import SingleMemoDetail from '../Components/Memo/SingleMemoDetail'
import AddNewMiscProduct from '../Components/Memo/AddNewMiscProduct'
import ProductsHunter from '../Components/Memo/ProductsHunter'
import DrawnWatchDetail from '../Components/DrawnWatchDetail'
import {focusElement, onPressEscape, onPressFocusSearchInput} from '../utils/keyboardHandler'
import AddressDropdown from '../Components/Customers/AddressDropdown'
import UsersDropdown from '../Components/Customers/UsersDropdown'
import PerformanceLimitAccessModal from '../Components/common/PerformanceLimitAccessModal'
import AddNoteForLineItem from '../Components/Memo/AddNoteForLineItem'

import assignIn from 'lodash/assignIn'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import isNil from 'lodash/isNil'
import uniqueId from 'lodash/uniqueId'
import isFunction from 'lodash/isFunction'
import findIndex from 'lodash/findIndex'
import head from 'lodash/head'
import isNull from 'lodash/isNull'
import sum from 'lodash/sum'
import isNaN from 'lodash/isNaN'
import upperFirst from 'lodash/upperFirst'
import InfiniteScroll from 'react-infinite-scroll-component'


const initAddress = {
	visible: false,
	company_name: '',
	contact_name: '',
	address: '',
	apt_unit_number: '',
	city: '',
	state: '',
	is_manual: false,
	country: '',
	zip_code: '',
	full_address: '',
	phone_number: '+1',
	save_to_contact: false
}

let cableApp = {}

class MemoView extends React.Component {
	constructor() {
		super()
		this.searchMemoInputRef = React.createRef()
		this.searchCustomerInputRef = React.createRef()
		this.billingAddressInputRef = React.createRef()
		this.shippingAddressInputRef = React.createRef()
		this.selectCustomerRef = React.createRef()
		this.state = {
			height: 0,
			sendingData: false,
			activeTab: 'all',
			step: 1,
			isVisibleAddSale: false,
			openPerformanceLimitAccessModal: false,
			isVisibleProductsHunter: false,
			isVisibleMiscProducts: false,
			loadedCustomers: null,
			loadedSpaceUsers: null,
			originalLoadedCustomers: null,
			loadingCustomers: false,
			selectedCustomer: null,
			selectedSalesPerson: null,
			selectedBillingAddress: null,
			selectedShippingAddress: null,
			newBillingAddress: initAddress,
			newShippingAddress: initAddress,

			memoedItems: [],
			miscItems: [],
			loadedProducts: [],
			originalLoadedProducts: [],
			memoMemo: null,
			customerFormVisible: false,
			searchString: null,
			orderBy: 'created_at',
			order: 'DESC',
			isVisibleComments: false,
			memoComment: {content: '', visible_on_pdf: false},

			originalMemosList: [],
			memosList: [],
			memosListOpen: [],
			memosListClosed: [],

			isVisibleSingleMemo: false,
			singleMemoItem: null,
			singleMemoItemId: null,

			shippingPrice: 0,

			checkedItems: [],

			openEdit: false,
			inventorySearch: '',
			offset: 1,
			loadingData: false,
			watchItem: {},
			loadingMoreData: false,
			pagination: {
				totalEntries: 0
			},
			searchCustomer: '',
			isCustomerLabeled: false,
			loadingProduct: false,
			isManualBillingAddress: false,
			isManualShippingAddress: false,
			trackingNumber: '',
			errors: [],
			isPrimaryTab: false,
			billingAddress: '',
			shippingAddress: '',
      openNoteModal: false,
      memoItemSelected: null,
		}

		this.handleLoadMemos = this.handleLoadMemos.bind(this)
		this.handleNewAddressChange = this.handleNewAddressChange.bind(this)
		this.listWrapperRef = React.createRef()
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize = () => {
		if(!!this.listWrapperRef.current) {
			this.setState({height: this.listWrapperRef.current.clientHeight})
		}
	};

	componentDidMount() {
		if(!!this.listWrapperRef.current) {
			this.setState({height: this.listWrapperRef.current.clientHeight})
		}
		if (!this.props.loggedin) return window.location.replace('/login')

		this.handleLoadMemos()
		const saleperson = this.props.currentSpace?.invoice_settings?.[0]?.saleperson
		this.setState({
			selectedSalesPerson: saleperson
		})
		window.addEventListener('keydown', this.handleKeyDown)
		cableApp?.cable?.disconnect()
		cableApp.cable = ActionCable.createConsumer(`${Globals.actionCableURL}?access_token=${Globals.getAccessToken()}`)
		cableApp.cable.subscriptions.create({channel: 'MemoChannel', space_id: this.props.currentSpace.id}, {
			received: (data) => {
				if (this.state.isPrimaryTab) return this.setState({isPrimaryTab: false})
				if (data.action === 'create') {
					Globals.New_Axios()
						.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/memos/${data.memo_id}`)
						.then(async response => {
							this.handleGetMemo(response.data)
						}).catch(error => {
						console.log(error)
					})
				} else if ((data.action === 'update')) {
					Globals.New_Axios()
						.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/memos/${data.memo_id}`)
						.then(async response => {
							this.updateItemInMemos(response.data)
						}).catch(error => {
						console.log(error)
					})
				}
			}
		})
		window.addEventListener('resize', this.handleResize);
	}

	handleCheckCountries = (value) => {
		if(value.long_name === 'Japan' && value.types.includes('country')) {
			if(!value.types.includes('street_number') || !value.types.includes('route')) {
				return false
			}
		}
		return true
	}

	handleChangeGoogleAddress = (place) => {
		const tmpAddress = []
    let subpremise

		if (!place.address_components) return

		(place.address_components || []).forEach(value => {
			if (value.types.includes('street_number') || value.types.includes('route')) {
        tmpAddress.push(value.long_name)
			}

      if (value.types.includes('subpremise')) {
        const match = value.long_name.match(/\d+$/);
        subpremise = match ? match[0] : value.long_name;
      }

			const isCheckCountries = this.handleCheckCountries(value)
			if(!isCheckCountries) {
				const part =  place.formatted_address.split(", ")
				tmpAddress.push(part[0])
			}
		})

		let city = place.address_components.find(component => component.types.includes('locality'))?.long_name || ''
		if(!city) {
			city = place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.long_name || ''
		}

		//Note: Check Japan Country


		const searchedAddress = {
			full_address: place.formatted_address.replace(` ${subpremise}`, ''),
			address: tmpAddress?.toString().replaceAll(',', ' ').trimStart(),
			street: place.address_components.find(component => component.types.includes('route'))?.long_name || '',
			city: city,
			state: place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.short_name || '',
			country: place.address_components.find(component => component.types.includes('country'))?.long_name || '',
			zip_code: place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '',
		}

    if (subpremise) {
      searchedAddress.apt_unit_number = subpremise
    }

		return searchedAddress
	}

	handleChangeBillingAddress = (place) => {
		const {isManualBillingAddress} = this.state
		const searchedAddress =  this.handleChangeGoogleAddress(place)
		if (!isManualBillingAddress) {
			let newAddress = assignIn({}, this.state.newBillingAddress, searchedAddress)
			this.setState({newBillingAddress: newAddress, billingAddress: searchedAddress.address})
		}
	}

	handleChangeShippingAddress = (place) => {
		const {isManualShippingAddress} = this.state
		const searchedAddress =  this.handleChangeGoogleAddress(place)
		if (!isManualShippingAddress) {
			const newAddress = assignIn({}, this.state.newShippingAddress, searchedAddress)
			this.setState({newShippingAddress: newAddress, shippingAddress: searchedAddress.address})
		}
	}

	handleClickOut = $event => {
		if ($event.target.classList.contains('single-customer-view')) this.setState({isVisibleSingleMemo: false})
	}

	handleLoadMemos = (isScroll = false) => {
		if (isScroll) this.setState({loadingMoreData: true})
		else this.setState({loadingData: true})

		let params = {
			q: {
				s: `${this.state.orderBy} ${this.state.order}`
			},
			page: this.state.offset,
			per_page: 50
		}

		switch (this.state.activeTab) {
			case 'open':
				Object.assign(params.q, {status_eq: 1})
				break
			case 'closed':
				Object.assign(params.q, {status_eq: 0})
				break

			default:
				Object.assign(params.q, {status_eq: undefined})
				break
		}

		if (this.state.searchString) {
			Object.assign(params.q, {
				memo_number_str_or_customer_first_name_or_customer_last_name_or_customer_company_name_or_watches_sku_or_watches_reference_number_or_misc_items_str_i_cont: this.state.searchString
			})
		}

		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/memos`, {params: params})
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					this.handleDataResponse(response.data)
				}
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				this.setState({loadingData: false})
				return this.props.addToast({
					text: message,
					type: 'error'
				})
			})
	}

	handleDataResponse = data => {
		const {current_page} = data
		const newDataMemosList = current_page === 1 ? data.memos : [...this.state.memosList, ...data.memos]
		const newDataOrginalMemosList = current_page === 1 ? data.memos : [...this.state.originalMemosList, ...data.memos]
		const newDataMemosListOpen = current_page === 1 ? data.memos : [...this.state.memosListOpen, ...data.memos]
		const newDataMemosListClosed = current_page === 1 ? data.memos : [...this.state.memosListClosed, ...data.memos]

		// Update state with the new data
		this.setState({
			memosList: newDataMemosList,
			memosListOpen: newDataMemosListOpen,
			memosListClosed: newDataMemosListClosed,
			originalMemosList: newDataOrginalMemosList,
			offset: data.next_page ?? 1,
			loadingMoreData: false,
			loadingData: false,
			pagination: {
				totalEntries: data.total_entries
			}
		})
	}

	loadMoreData = async () => {
		const {loadingMoreData, memosList, memosListOpen, memosListClosed, activeTab, pagination} = this.state

		const hasReachedTotalEntries = {
			all: memosList.length >= pagination.totalEntries,
			open: memosListOpen.length >= pagination.totalEntries,
			closed: memosListClosed.length >= pagination.totalEntries
		}

		const isDataLoadingOrComplete = loadingMoreData || hasReachedTotalEntries[activeTab]

		if (isDataLoadingOrComplete) return

		if (!hasReachedTotalEntries[activeTab]) {
			await this.handleLoadMemos(true)
		}
	}

	handleLoadCustomers = () => {
		this.setState({loadingCustomers: true})

		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/customers`, {
				params: {
					q: {
						s: 'created_at desc'
					},
					per_page: 10000
				}
			})
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					let {customers} = response.data

					//Pre load latest products.
					this.loadInventory()

					return this.setState({
						loadedCustomers: customers,
						originalLoadedCustomers: customers,
						loadingCustomers: false
					})
				}
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return this.props.addToast({
					text: message,
					type: 'error'
				})
			})
	}

	handleLoadUsers = () => {
		return Globals.New_Axios()
			.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/users`, {
				params: {
					q: {
						s: 'created_at desc'
					},
					per_page: 10000
				}
			})
			.then(response => {
				let {users} = response.data


				return this.setState({
					loadedSpaceUsers: users
				})
			})
			.catch(error => {
				const message = error.response?.data?.message || 'Something went wrong please try again later.'
				return this.props.addToast({
					text: message,
					type: 'error'
				})
			})
	}

	loadInventory = () => {
		this.setState({loadingProduct: true})
		Globals.New_Axios()
			.get(
				`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches?kind=inventory&q[g][0][status_in][]=on_hand&frontend_filter_enabled=true${this.state.inventorySearch}`
			)
			.then(response => {
				if (response.status && response.status >= 200 && response.status < 300) {
					const memoedItems = this.state.memoedItems
					//let loadedProducts = response.data.watches

					let loadedProducts = response.data.watches.map(post => {
						post.inBag = memoedItems.map(item => item.id).includes(post.id)
						post.qty = 1
						return post
					})
					this.setState({loadingProduct: false})
					return this.setState({
						loadedProducts: loadedProducts,
						originalLoadedProducts: loadedProducts
					})
				}
				this.setState({loadingProduct: false})
				return this.setState({
					loadedProducts: []
				})
			})
			.catch(error => {
				this.setState({loadingProduct: false})
				return this.props.addToast({
					text: 'Something went wrong please try again later.',
					type: 'error'
				})
			})
	}

	handleSearchCustomer = $event => {
		const originalLoadedCustomers = this.state.originalLoadedCustomers
		let dataProvider = originalLoadedCustomers

		const searchKeys = ['email', 'id', 'first_name', 'last_name', 'company_address', 'company_name', 'phone_number']
		let searchString = $event.target.value
		searchString = new DOMParser().parseFromString(searchString, 'text/html')
		searchString = searchString.documentElement.textContent

		const searchValue = searchString.toLowerCase().replaceAll('\\', '')
		dataProvider = dataProvider.filter(data => searchKeys.filter(key => String(data[key]).toLowerCase().match(searchValue)).length)
		this.setState({
			searchCustomer: searchValue,
			loadedCustomers: dataProvider
		})
	}

	handleOpenMemoForm = $event => {
		if (this.props.currentSpace?.internal_subscription.plan_type === 'performance' && this.props.currentSpace?.internal_subscription.status === 'active') {
			this.setState({openPerformanceLimitAccessModal: true})
		} else {
			this.handleLoadCustomers()
			this.handleLoadUsers()
			this.setState({
				isVisibleAddSale: true
			}, () => {
				setTimeout(() => {
					this.selectCustomerRef?.current?.focus()
				}, 100)
			})
		}
	}

	pickCustomer = customer => {
		this.setState({
			selectedCustomer: customer,
			selectedBillingAddress: customer.customer_addresses.find(address => address.is_billing_default),
			selectedShippingAddress: customer.customer_addresses.find(address => address.is_shipping_default),
			step: 2
		})
	}

	handleAddingItemToMemo = item => {
		const memoedItems = this.state.memoedItems

		if (!isEmpty(memoedItems.filter(_item => _item.id === item.id))) return

		this.setState({
			memoedItems: [...this.state.memoedItems, item],
			loadedProducts: this.state.loadedProducts.map(product => {
				if (product.id === item.id) product.inBag = true
				return product
			})
		})
	}

	handleRemovingItemToMemo = (item, $event) => {
		$event.stopPropagation()
		this.setState({
			memoedItems: this.state.memoedItems.filter(product => product.id !== item.id),
			loadedProducts: this.state.loadedProducts.map(product => {
				if (product.id === item.id) product.inBag = false
				return product
			})
		})
	}

	handleItemPriceChange = (item, price) => {
		let _price = price.replaceAll(',', '')
		_price = isNaN(_price) ? 0 : _price
		const newProductPrice = this.state.loadedProducts.map(product => (product.id === item.id ? {
			...product,
			wholesale_price: _price
		} : product))
		const newMemoedItems = this.state.memoedItems.map(product => (product.id === item.id ? {
			...product,
			wholesale_price: _price
		} : product))
		this.setState({loadedProducts: newProductPrice, memoedItems: newMemoedItems})
	}

	handleMemoItemPriceChange = (item, $event) => {
		let _price = $event.target.value || ''
		_price = _price.replaceAll(',', '')
		const _memoedItems = this.state.memoedItems.map(product => (product.id === item.id ? {
			...product,
			wholesale_price: _price
		} : product))
		this.setState({
			memoedItems: _memoedItems
		})
	}

	handleCommentChange = $event => {
    let value = $event.target.value

    if ($event.target.type === 'checkbox') {
      value = $event.target.checked
    }

		this.setState({
			memoComment: {
        ...this.state.memoComment,
        [$event.target.name]: value
      }
		})
	}

	handleChangeMemoBox = (event, item) => {
		event.stopPropagation()
		const _memoedItems = this.state.memoedItems.map(product => (product.id === item.id ? {
			...product,
			box: !product.box
		} : product))
		this.setState({
			memoedItems: _memoedItems
		})
	}

	handleMemoItemQtyChange = debounce((item, $event) => {
		this.setState({
			memoedItems: this.state.memoedItems.map(product => {
				if (product.id === item.id) product.qty = $event.target.value
				return product
			})
		})
	}, 500)

	handleSearchProducts = debounce($event => {
		const keyword = $event.target.value
		let search = ''
		if (!isEmpty(keyword)) {
			search = `&q[${Globals.watchSearchParams}]=${keyword}`
		}
		this.setState(
			{
				inventorySearch: search
			},
			async () => {
				this.loadInventory()
			}
		)
	}, 1000)

	handleSubmitMemo = $event => {
		$event.preventDefault()

		const state = this.state
		let {selectedCustomer, memoedItems, memoMemo, shippingPrice} = state

		selectedCustomer = selectedCustomer.id
		memoedItems = memoedItems.map(item => {
			return {
				memoable_id: item.id,
				quantity: item.qty,
				price: Globals.revertPrice(item.wholesale_price),
				memoable_type: 'Watch',
        note: item.note,
				box: item.box
			}
		})

		if (state.sendingData) return

		this.setState({sendingData: true})

		state.miscItems.forEach(item => {
			if (isNil(item.id)) {
				item.id = uniqueId('misc_')
			}
		})
		let updateShippingPriceForMemo = shippingPrice
		if (state.isCustomerLabeled) {
			updateShippingPriceForMemo = 0
		}

    const commentAttributes = !state.isVisibleComments || isEmpty(state.memoComment.content) ? [] : [state.memoComment]

		const memoParams = {
			customer_id: selectedCustomer,
			memo_items_attributes: memoedItems,
			comments_attributes: commentAttributes,
			misc_items: state.miscItems,
			shipping_price: updateShippingPriceForMemo,
			status: 'published',
			tracking_number: state.trackingNumber,
			customer_label: state.isCustomerLabeled
		}

		if (state.selectedSalesPerson) {
			memoParams['salesperson_id'] = state.selectedSalesPerson.id
		}

		if (state.selectedBillingAddress) {
			memoParams.billing_address_id = state.selectedBillingAddress.id
		} else {
			memoParams.billing_address_attributes = state.newBillingAddress
			memoParams.billing_address_attributes['in_contact'] = state.newBillingAddress.save_to_contact
			memoParams.billing_address_attributes['is_manual'] = state.isManualBillingAddress
			memoParams.billing_address_attributes['customer_id'] = selectedCustomer
		}

		if (state.selectedShippingAddress) {
			memoParams.shipping_address_id = state.selectedShippingAddress.id
		} else {
			memoParams.shipping_address_attributes = state.newShippingAddress
			memoParams.shipping_address_attributes['in_contact'] = state.newShippingAddress.save_to_contact
			memoParams.shipping_address_attributes['is_manual'] = state.isManualShippingAddress
			memoParams.shipping_address_attributes['customer_id'] = selectedCustomer
		}
		if (isFunction(this.handlePrimaryTab)) this.handlePrimaryTab()

		return Globals.New_Axios()
			.post(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/memos`, {
				memo: memoParams
			})
			.then(response => {
				this.setState({sendingData: false})

				//Push the item into the list.
				const memo = response.data

				return this.setState({
					memosList: [memo, ...this.state.memosList],
					originalMemosList: [memo, ...this.state.originalMemosList],
					memosListOpen: [memo, ...this.state.memosListOpen],
					step: 1,
					isVisibleAddSale: false,
					loadedCustomers: null,
					originalLoadedCustomers: null,
					selectedCustomer: null,
					memoedItems: [],
					loadedProducts: [],
					miscItems: [],
					shippingPrice: 0,
					isManualBillingAddress: false,
					isManualShippingAddress: false,
					newBillingAddress: initAddress,
					newShippingAddress: initAddress,
					trackingNumber: '',
					selectedSalesPerson: null,
					errors: []
				})
			})
			.catch(error => {
				this.setState({sendingData: false})

				return this.props.addToast({
					text: error.response?.data?.message || 'Something went wrong please try again later.',
					type: 'error'
				})
			})
	}

	loadSingleMemo = memo => {
		this.setState({isVisibleSingleMemo: true, singleMemoItemId: memo.id})
	}

	updateMemoList = (memos, openMemos, closedMemos) => {
		this.setState({
			memosList: memos,
			memosListOpen: openMemos,
			memosListClosed: closedMemos
		})
	}

	handleSearchMemo = debounce($event => {
		let searchString = $event.target.value
		searchString = new DOMParser().parseFromString(searchString, 'text/html')
		searchString = searchString.documentElement.textContent
		const searchValue = searchString.toLowerCase().replaceAll('\\', '')
		this.setState(
			{
				searchString: searchValue,
				offset: 1
			},
			() => {
				this.handleLoadMemos()
			}
		)
	}, 800)

	handleCloseCustomerForm = $event => {
		$event.preventDefault()
		this.setState({
			customerFormVisible: false
		})
		focusElement('.add-new-memo-view')
	}

	handleNewAddCustomer = customer => {
		const loadedCustomers = [...this.state.loadedCustomers, customer]
		const originalLoadedCustomers = [...this.state.originalLoadedCustomers, customer]
		this.setState({
			loadedCustomers: loadedCustomers,
			originalLoadedCustomers: originalLoadedCustomers
		})
	}

	closeSingleMemoDetail = () => {
		this.setState({isVisibleSingleMemo: false})
	}

	hideAddNewMiscProduct = () => {
		this.setState({isVisibleMiscProducts: false})
		focusElement('.add-new-memo-view')
	}

	handleMiscItemList = newMiscItem => {
		const _miscItems = this.state.miscItems
		this.setState({miscItems: [..._miscItems, newMiscItem]})
	}

	handleRemovingMiscItemMemo = (sku, $event) => {
		$event.stopPropagation()
		this.setState({
			miscItems: this.state.miscItems.filter((product, index) => index !== sku)
		})
	}

	handleMemoMiscPriceChange = (sku, $event) => {
		let _price = ($event.target.value.replaceAll(',', ''))
		_price = isNaN(_price) ? 0 : _price

		const _memoedItems = this.state.miscItems.map((product, index) => (index === sku ? {
			...product,
			price: _price
		} : product))
		this.setState({
			miscItems: _memoedItems
		})
	}

	handlePrimaryTab = () => {
		this.setState({isPrimaryTab: true})
	}

	handleOpenEdit = val => {
		if (this.props.currentSpace?.internal_subscription.plan_type === 'performance' && this.props.currentSpace?.internal_subscription.status === 'active') {
			this.setState({openPerformanceLimitAccessModal: true})
		} else {
			this.setState({openEdit: !this.state.openEdit})
		}
	}

	handleOpenPerformanceLimitAccessModal = () => {
		this.setState({openPerformanceLimitAccessModal: true})
	}

	handleOpenProductsHunter = () => {
		this.handleLoadCustomers()
		this.handleLoadUsers()
		this.setState({isVisibleProductsHunter: true})
	}

	handleCloseProductsHunter = () => {
		return this.setState({isVisibleProductsHunter: false, inventorySearch: '', loadedProducts: []}, async () => {
			this.loadInventory()
			focusElement('.add-new-memo-view')
		})
	}

	setTabState = tabState => {
		this.setState(
			{
				activeTab: tabState,
				offset: 1,
				memosList: [],
				memosListOpen: [],
				memosListClosed: []
			},
			() => {
				this.handleLoadMemos()
			}
		)
	}

	updateItemInMemos = async memoData => {
		let {memosList, activeTab, memosListOpen, memosListClosed} = this.state
		if (activeTab === 'all') {
			const index = findIndex(memosList, {id: memoData.id})
			memosList[index] = memoData
			this.setState({
				memosList: [...memosList]
			})
		} else if (activeTab === 'open') {
			const index = findIndex(memosListOpen, {id: memoData.id})
			memosListOpen[index] = memoData
			memosListOpen = memosListOpen.filter(order => order.status !== 'closed')
			this.setState({
				memosListOpen: [...memosListOpen]
			})
		} else if (activeTab === 'closed') {
			if (memoData.status !== 'closed') return
			this.setState({
				memosListClosed: [memoData, ...memosListClosed]
			})
		}
	}

	handleShowDetailWatch = watch => {
		this.setState({loadingData: true})
		Globals.New_Axios()
		.get(`${Globals.NEW_API_URL}/${Globals.ENDPOINTVERSION}/spaces/${Globals.SELECTEDSPACEID}/watches/${watch.id}`)
		.then(async response => {
			this.setState(
				{
					watchItem: response.data,
					loadingData: false
				},
				() => this.props.toggleDetailsCardInventory(true)
			)
		})
		.catch(error => {
			this.setState({loadingData: true})
			return this.props.addToast({
				text: 'Something went wrong please try again later.',
				type: 'error',
			})
		})
	}

	handlePressEscape = event => {
		if (event.target.classList.contains('add-new-memo-view')) {
			this.setState({
				step: 1,
				isVisibleAddSale: false,
				loadedCustomers: null,
				originalLoadedCustomers: null,
				selectedCustomer: null,
				memoedItems: [],
				loadedProducts: [],
				miscItems: []
			})
		}
	}

	handleGetMemo = (memo) => {
		this.setState({
			memosList: [memo, ...this.state.memosList],
			originalMemosList: [memo, ...this.state.originalMemosList],
			memosListOpen: [memo, ...this.state.memosListOpen],
			step: 1
		})
	}

	handleKeyDown = event => {
		const {isVisibleAddSale, customerFormVisible, isVisibleSingleMemo, isVisibleProductsHunter} = this.state
		if (isVisibleAddSale) {
			if (!this.searchCustomerInputRef?.current || customerFormVisible) return
			onPressFocusSearchInput(event, this.searchCustomerInputRef.current)
		} else {
			if (customerFormVisible || isVisibleSingleMemo || isVisibleProductsHunter) return
			onPressFocusSearchInput(event, this.searchMemoInputRef.current)
		}
	}

	handleUpdateItemProduct = item => {
		const newMemoedItems = this.state.memoedItems.map(product => (product.id === item.id ? {
			...product,
			box: item.box
		} : product))
		this.setState({memoedItems: newMemoedItems})
	}

	toggleShipping = () => {
		this.setState(prevState => ({isCustomerLabeled: !prevState.isCustomerLabeled}), this.setState({shippingPrice: 0}))
	}

	componentWillUnmount = () => {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	handleSelectAddress = (type, address) => {
		if (type === 'billing') {
			this.setState({
				selectedBillingAddress: address,
				newBillingAddress: {
					...initAddress,
					visible: false
				}
			})
		} else {
			this.setState({
				selectedShippingAddress: address,
				newShippingAddress: {
					...initAddress,
					visible: false
				}
			})
		}
	}

	handleAddNewAddress = type => {
		if (type === 'billing') {
			this.setState({
				selectedBillingAddress: null,
				newBillingAddress: {
					...this.state['newBillingAddress'],
					visible: true
				}
			})
		} else {
			this.setState({
				selectedShippingAddress: null,
				newShippingAddress: {
					...this.state['newShippingAddress'],
					visible: true
				}
			})
		}
	}

	handleSelectSalesPerson = user => {
		if (!!user) {
			this.setState({selectedSalesPerson: user})
		} else {
			this.setState({selectedSalesPerson: null})
		}
	}

	handleNewAddressChange = (type, field, value) => {
		if (field === 'phone_number') {
			this.setState(prevState => ({
				[type]: {
					...prevState[type],
					phone_number: formatPhoneNumber(value)
				}
			}))
		} else if (field === 'save_to_contact') {
			this.setState(prevState => ({
				[type]: {
					...prevState[type],
					[field]: !prevState[type][field]
				}
			}))
		} else {
			this.setState(prevState => ({
				[type]: {
					...prevState[type],
					[field]: value
				}
			}))
		}
	}

	handleVisibleManualBillingAddress = () => {
		const {newBillingAddress, isManualBillingAddress, billingAddress} = this.state
		if (!isManualBillingAddress) {
			if (!newBillingAddress.address) {
				const address = {
					...newBillingAddress,
					address: newBillingAddress.full_address
				}
				this.setState({newBillingAddress: address})
			}
		} else {
			const address = {
				...newBillingAddress,
				address: billingAddress
			}
			this.setState({newBillingAddress: address})
		}
		this.setState({isManualBillingAddress: !this.state.isManualBillingAddress})
	}

	handleVisibleManualShippingAddress = () => {
		const {newShippingAddress, isManualShippingAddress, shippingAddress} = this.state
		if (!isManualShippingAddress) {
			if (!newShippingAddress.address) {
				const address = {
					...newShippingAddress,
					address: newShippingAddress.full_address
				}
				this.setState({newShippingAddress: address})
			}
		} else {
			const address = {
				...newShippingAddress,
				address: shippingAddress
			}
			this.setState({newBillingAddress: address})
		}
		this.setState({isManualShippingAddress: !this.state.isManualShippingAddress})
	}

	handleKeyDownTrackingNumber = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault()
			this.trackingNumberInputRef.current.blur()
		}
	}

	handleChangeTrackingNumber = (event) => {
		this.setState({trackingNumber: event.target.value})
	}

	refreshListAfterProductEdit = (data) => {
		const updatedMemosList = this.state.memosList.map((memo) => {
			const updatedMemoItems = memo.memo_items.map((item) => {
				if (item.memoable.id === data.id) {
					return {
						...item,
						memoable: {
							...item.memoable,
							reference_number: data.reference_number,
							sku: data.sku,
							images: data.images,
						},
					};
				}
				return item;
			});
			return { ...memo, memo_items: updatedMemoItems };
		});

		this.setState({ memosList: updatedMemosList, watchItem: data });
	};

  handleOpenNoteModal = (event, memoItem) => {
    this.setState({
      memoItemSelected: memoItem,
      openNoteModal: true
    })
  }

  handleCloseNoteModal = () => {
    this.setState({
      memoItemSelected: null,
      openNoteModal: false
    })
  }


  setNoteData = (event, value) => {
    const newMemoedItems = this.state.memoedItems.map(item => {
      if (item.id === this.state.memoItemSelected.id) {
        return {
          ...item,
          note: value
        }
      }
      return item
    })

    this.setState({
      memoedItems: newMemoedItems,
      openNoteModal: false,
      memoItemSelected: null
    })
  }

	render() {
		const state = this.state
		const props = this.props
		return (
			<React.Fragment>
				{props.loggedin && (
					<React.Fragment>
						<section className="relative">
							<TopBar toggleSideBar={() => props.toggleSidebar(!props.isOpenSideBar)} />
							<div className="flex relative">
								<SideBar isOpen={props.isOpenSideBar} />
								<main className="flex flex-col relative w-full h-screen overflow-y-hidden" style={{paddingTop: (props.currentSpace.should_show_upgrade_announcement) ? '6.6rem' : '4rem'}}>
									<div className="bg-white overflow-hidden rounded-tl-lg w-full h-full flex flex-col">
										<div className="flex relative w-full mt-6 mb-6 px-5 flex-col md:flex-row">
											<div className="flex flex-col items-center">
												<h1 className="font-semibold text-3xl">Memos</h1>
											</div>
											<div
												className="flex relative w-full justify-center md:justify-end items-center space-x-2">
												<div>
													<div className="text-gray-400 relative">
														<input
															ref={this.searchMemoInputRef}
															className="text-gray-700 text-xs bg-white border flex items-center max-w-xs outline-blue-300 px-3 pr-9 relative rounded w-full md:w-screen h-11"
															type="text"
															name="search"
															placeholder="Search Here..."
															onChange={this.handleSearchMemo}
														/>
														<button
															className="pointer-events-none absolute right-0 top-0 h-full px-3">
															<svg className="w-6 h-6" fill="currentColor"
																 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																<path
																	fillRule="evenodd"
																	d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
																	clipRule="evenodd"
																/>
															</svg>
														</button>
													</div>
												</div>
												<div>
													<Tooltip title="Add new memo" placement="bottom" arrow>
														<button
															className="bg-white border flex items-center px-3 py-[11px] relative rounded w-full"
															onClick={this.handleOpenMemoForm}>
															<svg className="w-4 h-4" fill="currentColor"
																 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																<path fillRule="evenodd"
																	  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
																	  clipRule="evenodd" />
															</svg>
															<span
																className="ml-1 font-normal text-sm text-center">Add</span>
														</button>
													</Tooltip>
												</div>
											</div>
										</div>

										<div
											className="text-sm font-medium text-center text-gray-500 border-t border-b border-gray-200">
											<ul className="flex flex-wrap -mb-px space-x-4">
												<li>
													<button
														onClick={() => this.setTabState('all')}
														className={Classnames('inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300', {
															'text-blue-600 border-blue-600 hover:text-blue-600 hover:border-blue-600': state.activeTab === 'all'
														})}
													>
														All
													</button>
												</li>
												<li>
													<button
														onClick={() => this.setTabState('open')}
														className={Classnames('inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300', {
															'text-blue-600 border-blue-600 hover:text-blue-600 hover:border-blue-600': state.activeTab === 'open'
														})}
													>
														Open
													</button>
												</li>
												<li>
													<button
														onClick={() => this.setTabState('closed')}
														className={Classnames('inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300', {
															'text-blue-600 border-blue-600 hover:text-blue-600 hover:border-blue-600': state.activeTab === 'closed'
														})}
													>
														Closed
													</button>
												</li>
											</ul>
										</div>

										<div
											style={{height: '100%'}}
											ref={this.listWrapperRef}
										>
											<InfiniteScroll
												loader={() => null}
												dataLength={state.memosList?.length || 0} //This is important field to render the next data
												next={this.loadMoreData}
												height={(this.state.height || 0) + 'px'}
												scrollThreshold={'50%'}
												hasMore={true}
											>
												<div className="flex h-full flex-col relative ">
													<div className="inline-block w-full relative ">
														{state.activeTab === 'all' && (
															<table
																className="relative min-w-full border-b bg-transparent overflow-scroll">
																<thead
																	className="drop-shadow-md sticky top-0 bg-white border-b">
																<tr className="divide-x">
																	<th className="p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Memo</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Date</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Customer</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Items</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="w-40 p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Total</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="w-28 p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Status</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																</tr>
																</thead>

																{!isEmpty(state.memosList) && (
																	<tbody className="divide-y">
																	{state.memosList.map((memo, index) => {
																		return (
																			<tr
																				key={index}
																				className={Classnames('cursor-pointer divide-x hover:bg-gray-100 bg-gray-50', {
																					'bg-white': (index + 1) % 2 === 0
																				})}
																				onClick={this.loadSingleMemo.bind(this, memo)}
																			>
																				<th className="w-[135px] p-4 text-sm font-normal tracking-worderer">#{memo.memo_number}</th>
																				<th className="w-[175px] p-4 text-sm font-normal tracking-worderer">
																					{Moment(memo.created_at).format('MM/DD/YYYY')}
																				</th>
																				<th className="w-[300px] p-4 text-sm font-normal tracking-worderer">
																					{isEmpty(memo.customer) &&
																						<span>#{memo.customer.name}</span>}
																					{memo.customer.company_name ? memo.customer.company_name : memo.customer.name}
																				</th>
																				<th className="w-[260px] p-4 text-sm font-normal tracking-worderer">
																					{memo.memo_items.map((memoItem, index) => {
																						const imageUrl =
																							memoItem.memoable &&
																							memoItem.memoable.images &&
																							!isEmpty(memoItem.memoable.images) &&
																							head(memoItem.memoable.images) &&
																							!isNull(memoItem.memoable.images)
																								? head(memoItem.memoable.images).url
																								: '/watch_icon.png'
																						const uniqueKey = `${memoItem.memoable?.reference_number ?? 'defaultRef'}-${memoItem.memoable?.sku ?? 'defaultSKU'}-${index}`
																						return (
																							<Tooltip
																								key={uniqueKey}
																								title={
																									<div
																										style={{
																											backgroundImage: `url('${imageUrl}')`,
																											width: 80,
																											height: 80,
																											backgroundSize: 'cover',
																											backgroundPosition: 'center'
																										}}
																									></div>
																								}
																								placement="bottom"
																								arrow
																							>
																								<span
																									onClick={event => {
																										event.stopPropagation()
																										this.handleShowDetailWatch(memoItem.memoable)
																									}}
																									className="hover:text-blue-500"
																								>
																									<span>{memoItem?.memoable?.reference_number}</span>
																									<span>{` (${memoItem?.memoable?.sku})`}</span>
																									{index < memo.memo_items.length - 1 &&
																										<span>,&nbsp;</span>}
																								</span>
																							</Tooltip>
																						)
																					})}
																					{memo.memo_items.length > 0 && (memo.misc_items || []).length > 0 &&
																						<span>,&nbsp;</span>}
																					{(memo.misc_items || []).map((miscItem, index) => {
																						const uniqueKey = `${miscItem.product_name}-${index}`
																						return (
																							<React.Fragment
																								key={uniqueKey}>
																								<span
																									className="hover:text-blue-500">
																									<span>{miscItem.product_name}</span>
																								</span>
																								{index < memo.misc_items.length - 1 &&
																									<span>,&nbsp;</span>}
																							</React.Fragment>
																						)
																					})}
																				</th>
																				<th className="p-4 text-sm font-normal tracking-worderer">{Globals.readCurrencyNumber(memo.total)}</th>
																				<th className="p-4 text-sm font-normal tracking-worderer">
																					{memo.status === 'published' && (
																						<small
																							className="bg-green-100 text-green-700 font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-center">
																										<span
																											className="w-1.5 h-1.5 inline-flex rounded-full bg-green-700"></span> open
																						</small>
																					)}
																					{memo.status !== 'published' && (
																						<small
																							className="bg-yellow-100 text-yellow-700 font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-center">
																										<span
																											className="w-1.5 h-1.5 inline-flex rounded-full bg-yellow-700"></span> closed
																						</small>
																					)}
																				</th>
																			</tr>
																		)
																	})}
																	</tbody>
																)}
															</table>
														)}

														{state.activeTab === 'open' && (
															<table
																className="relative min-w-full border-b bg-transparent overflow-scroll">
																<thead
																	className="drop-shadow-md sticky top-0 bg-white border-b">
																<tr className="divide-x">
																	<th className="p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Memo</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Date</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Customer</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Items</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="w-40 p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Total</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="w-28 p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Status</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																</tr>
																</thead>

																{!isEmpty(state.memosListOpen) && (
																	<tbody className="divide-y">
																	{state.memosListOpen.map((memo, index) => {
																		return (
																			<tr
																				key={index}
																				className={Classnames('cursor-pointer divide-x hover:bg-gray-100 bg-gray-50', {
																					'bg-white': (index + 1) % 2 === 0
																				})}
																				onClick={this.loadSingleMemo.bind(this, memo)}
																			>
																				<th className="w-[135px] p-4 text-sm font-normal tracking-worderer">#{memo.memo_number}</th>
																				<th className="w-[175px] p-4 text-sm font-normal tracking-worderer">
																					{Moment(memo.created_at).format('MM/DD/YYYY')}
																				</th>
																				<th className="w-[360px] p-4 text-sm font-normal tracking-worderer">
																					{isEmpty(memo.customer) &&
																						<span>#{memo.customer.name}</span>}
																					{!isEmpty(memo.customer) && memo.customer.company_name}
																				</th>
																				<th className="w-[260px] p-4 text-sm font-normal tracking-worderer">
																					{memo.memo_items.map((memoItem, index) => {
																						return (
																							<Tooltip
																								key={index}
																								title={
																									<div
																										style={{
																											backgroundImage:
																												memoItem.memoable &&
																												memoItem.memoable.images &&
																												!isEmpty(memoItem.memoable.images) &&
																												head(memoItem.memoable.images) &&
																												!isNull(memoItem.memoable.images)
																													? `url('${head(memoItem.memoable.images).url}')`
																													: `url('/watch_icon.png')`,
																											width: 80,
																											height: 80,
																											backgroundSize: 'cover',
																											backgroundPosition: 'center'
																										}}
																									></div>
																								}
																								placement="bottom"
																								followCursor={true}
																								arrow
																							>
																								<div
																									onClick={event => {
																										event.stopPropagation()
																										this.handleShowDetailWatch(memoItem.memoable)
																									}}
																									className="hover:text-blue-500"
																									key={index}
																								>
																									<span>{memoItem.memoable?.reference_number}</span>
																									<span>{` (${memoItem.memoable?.sku})`}</span>
																									{index < memo.memo_items.length - 1 &&
																										<span>,&nbsp;</span>}
																								</div>
																							</Tooltip>
																						)
																					})}
																					{memo.memo_items.length > 0 && (memo.misc_items || []).length > 0 &&
																						<span>,&nbsp;</span>}
																					{(memo.misc_items || []).map((miscItem, index) => {
																						return (
																							<React.Fragment>
																								<div
																									className="hover:text-blue-500"
																									key={index}>
																									<span>{miscItem.product_name}</span>
																								</div>

																								{index < memo.misc_items.length - 1 &&
																									<span>,&nbsp;</span>}
																							</React.Fragment>
																						)
																					})}
																				</th>

																				<th className="p-4 text-sm font-normal tracking-worderer">{Globals.readCurrencyNumber(memo.total)}</th>
																				<th className="p-4 text-sm font-normal tracking-worderer">
																					{memo.status === 'published' && (
																						<small
																							className="bg-green-100 text-green-700 font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-center">
																										<span
																											className="w-1.5 h-1.5 inline-flex rounded-full bg-green-700"></span> open
																						</small>
																					)}
																					{memo.status !== 'published' && (
																						<small
																							className="bg-yellow-100 text-yellow-700 font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-center">
																										<span
																											className="w-1.5 h-1.5 inline-flex rounded-full bg-yellow-700"></span> closed
																						</small>
																					)}
																				</th>
																			</tr>
																		)
																	})}
																	</tbody>
																)}
															</table>
														)}

														{state.activeTab === 'closed' && (
															<table
																className="relative min-w-full border-b bg-transparent overflow-scroll">
																<thead
																	className="drop-shadow-md sticky top-0 bg-white border-b">
																<tr className="divide-x">
																	<th className="p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Memo</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Date</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Customer</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Items</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="w-40 p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Total</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																	<th className="w-28 p-4 text-sm font-bold tracking-worderer">
																		<button
																			className="flex items-center text-gray-500">
																			<span>Status</span>
																			<svg
																				className="inline-flex ml-2 w-2"
																				viewBox="0 0 10 8"
																				xmlns="http://www.w3.org/2000/svg"
																				fill="currentColor">
																				<path
																					d="M9.22794 0.791504H0.769612C0.603362 0.791504 0.451112 0.885712 0.37732 1.03505C0.303529 1.18409 0.321029 1.3623 0.421654 1.49442L4.65082 7.03609C4.73394 7.14459 4.86228 7.20817 4.99878 7.20817C5.13528 7.20817 5.26361 7.14459 5.34644 7.03609L9.57561 1.49442C9.67653 1.3623 9.69374 1.18409 9.61994 1.03505C9.54644 0.885712 9.39419 0.791504 9.22794 0.791504Z" />
																			</svg>
																		</button>
																	</th>
																</tr>
																</thead>

																{!isEmpty(state.memosListClosed) && (
																	<tbody className="divide-y">
																	{state.memosListClosed.map((memo, index) => {
																		return (
																			<tr
																				key={index}
																				className={Classnames('cursor-pointer divide-x hover:bg-gray-100 bg-gray-50', {
																					'bg-white': (index + 1) % 2 === 0
																				})}
																				onClick={this.loadSingleMemo.bind(this, memo)}
																			>
																				<th className="w-[135px] p-4 text-sm font-normal tracking-worderer">#{memo.memo_number}</th>
																				<th className="w-[175px] p-4 text-sm font-normal tracking-worderer">
																					{Moment(memo.created_at).format('MM/DD/YYYY')}
																				</th>
																				<th className="w-[360px] p-4 text-sm font-normal tracking-worderer">
																					{isEmpty(memo.customer) &&
																						<span>#{memo.customer.name}</span>}
																					{!isEmpty(memo.customer) && memo.customer.company_name}
																				</th>
																				<th className="w-[260px] p-4 text-sm font-normal tracking-worderer">
																					{memo.memo_items.map((memoItem, index) => {
																						return (
																							<Tooltip
																								key={index}
																								title={
																									<div
																										style={{
																											backgroundImage:
																												memoItem.memoable?.images &&
																												!isEmpty(memoItem.memoable.images) &&
																												head(memoItem.memoable.images) &&
																												!isNull(memoItem.memoable.images)
																													? `url('${head(memoItem.memoable.images).url}')`
																													: `url('/watch_icon.png')`,
																											width: 80,
																											height: 80,
																											backgroundSize: 'cover',
																											backgroundPosition: 'center'
																										}}
																									></div>
																								}
																								placement="bottom"
																								arrow
																							>
																								<div
																									onClick={event => {
																										event.stopPropagation()
																										this.handleShowDetailWatch(memoItem.memoable)
																									}}
																									className="hover:text-blue-500"
																									key={index}
																								>
																									<span>{memoItem.memoable?.reference_number}</span>
																									<span>{` (${memoItem.memoable?.sku})`}</span>
																									{index < memo.memo_items.length - 1 && memo.memo_items.length > 1 &&
																										<span>,&nbsp;</span>}
																								</div>
																							</Tooltip>
																						)
																					})}
																					{memo.memo_items.length > 0 && (memo.misc_items || []).length > 0 &&
																						<span>,&nbsp;</span>}
																					{(memo.misc_items || []).map((miscItem, index) => {
																						return (
																							<React.Fragment>
																								<div
																									className="hover:text-blue-500"
																									key={index}>
																									<span>{miscItem.product_name}</span>
																								</div>

																								{index < memo.misc_items.length - 1 &&
																									<span>,&nbsp;</span>}
																							</React.Fragment>
																						)
																					})}
																				</th>

																				<th className="p-4 text-sm font-normal tracking-worderer">{Globals.readCurrencyNumber(memo.total, true)}</th>
																				<th className="p-4 text-sm font-normal tracking-worderer">
																					{memo.status === 'published' && (
																						<small
																							className="bg-green-100 text-green-700 font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-center">
																										<span
																											className="w-1.5 h-1.5 inline-flex rounded-full bg-green-700"></span> open
																						</small>
																					)}
																					{memo.status !== 'published' && (
																						<small
																							className="bg-yellow-100 text-yellow-700 font-medium inline-block leading-loose px-3 rounded-3xl flex-inline items-center">
																										<span
																											className="w-1.5 h-1.5 inline-flex rounded-full bg-yellow-700"></span> closed
																						</small>
																					)}
																				</th>
																			</tr>
																		)
																	})}
																	</tbody>
																)}
															</table>
														)}
													</div>
												</div>
											</InfiniteScroll>
										</div>
									</div>
									{state.loadingData && (
										<div
											className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-50">
											<button
												type="button"
												className="inline-flex items-center px-4 py-2 leading-6 text-sm shadow rounded-md text-white bg-blue transition ease-in-out duration-150 cursor-not-allowed"
												disabled
											>
												<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
													 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													{' '}
													<circle className="opacity-25" cx="12" cy="12" r="10"
															stroke="currentColor" strokeWidth="4" />
													<path
														className="opacity-75"
														fill="currentColor"
														d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
													/>
												</svg>
												<span>Loading... </span>
											</button>
										</div>
									)}
								</main>
							</div>
						</section>

						{state.openPerformanceLimitAccessModal && (
							<PerformanceLimitAccessModal
								closeModal={() => this.setState({openPerformanceLimitAccessModal: false})}
							/>
						)}

						{
							props.isOpenDetailsInventory &&
							<DrawnWatchDetail
								data={state.watchItem}
								refreshListAfterProductEdit={this.refreshListAfterProductEdit}
							/>
						}

						{/* ============================= ADD NEW MEMO POPUP =============================*/}
						{state.isVisibleAddSale && (
							<div ref={this.selectCustomerRef}
								 onKeyDown={onPressEscape(event => this.handlePressEscape(event))}
								 className="add-new-memo-view bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-40"
								 tabIndex="-1">
								<div
									className="bg-white rounded-tl-xl absolute top-0 right-0 shadow-xl overflow-hidden h-full"
									style={{maxWidth: '90%', width: '100%'}}>
									<div className="flex items-center gap-3 bg-white p-[24px]">
										<div className="flex flex-row items-center justify-between w-full">
											<div className="flex">
												<button
													onClick={() => {
														this.setState({
															step: 1,
															isVisibleAddSale: false,
															loadedCustomers: null,
															originalLoadedCustomers: null,
															selectedCustomer: null,
															memoedItems: [],
															loadedProducts: [],
															miscItems: [],
															trackingNumber: '',
															selectedSalesPerson: null,
															errors: [],
															isManualBillingAddress: false,
															isManualShippingAddress: false,
															newBillingAddress: initAddress,
															newShippingAddress: initAddress
														})
													}}
												>
													<svg className="w-6 h-6" viewBox="0 0 22 22" fill="currentColor"
														 xmlns="http://www.w3.org/2000/svg">
														<path
															d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
													</svg>
												</button>
												<h2 className="text-[#5D6E81] text-[24px] font-bold uppercase ms-[9px]">Add
													new memo</h2>
											</div>
										</div>
										{state.step === 2 && (
											<div className="flex justify-end items-center w-full">
												<div className=" mr-5">
													<Tooltip title="Add a product from your inventory"
															 placement="bottom" arrow>
														<button
															onClick={() =>
																this.setState({
																	isVisibleProductsHunter: true
																})
															}
															className="bg-[#2863EB] text-white border border-blue-500 flex items-center px-3 py-2 relative rounded w-full"
														>
															<svg className="w-4 h-4" fill="currentColor"
																 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																<path fillRule="evenodd"
																	  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
																	  clipRule="evenodd" />
															</svg>
															<span
																className="font-semibold text-[14px] text-sm text-center">Add products</span>
														</button>
													</Tooltip>
												</div>
												<div>
													<Tooltip title="Add a product that is not inventory"
															 placement="bottom" arrow>
														<button
															onClick={() =>
																this.setState({
																	isVisibleMiscProducts: true
																})
															}
															className="bg-[#2863EB] text-white border border-blue-500 flex items-center px-3 py-2 relative rounded w-full"
														>
															<svg className="w-4 h-4" fill="currentColor"
																 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
																<path fillRule="evenodd"
																	  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
																	  clipRule="evenodd" />
															</svg>
															<span className="font-semibold text-sm text-center">Add misc products</span>
														</button>
													</Tooltip>
												</div>
											</div>
										)}
										{state.step === 1 && (
											<div className=" w-60 mr-[16px]">
												<Tooltip title="Add new contact" placement="bottom" arrow>
													<button
														className="bg-white border flex items-center justify-center px-3 py-2 relative rounded w-full"
														onClick={$event =>
															this.setState({
																customerFormVisible: true
															})
														}
													>
														<svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20"
															 xmlns="http://www.w3.org/2000/svg">
															<path fillRule="evenodd"
																  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
																  clipRule="evenodd" />
														</svg>
														<span className="ml-1 font-normal text-sm text-center">Add New Contact</span>
													</button>
												</Tooltip>
											</div>
										)}
									</div>

									<div
										className="h-screen divide-y divide-gray-200 px-0 pb-28 overflow-y-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
										{state.step === 1 && (
											<React.Fragment>
												{isEmpty(state.originalLoadedCustomers) && state.loadingCustomers && (
													<div className="flex h-full items-center justify-center">
														<button
															disabled
															type="button"
															className="text-white bg-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 inline-flex items-center"
														>
															<svg
																role="status"
																className="inline mr-3 w-4 h-4 text-white animate-spin"
																viewBox="0 0 100 101"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
																	fill="#E5E7EB"
																/>
																{' '}
																<path
																	d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
																	fill="currentColor"
																/>
															</svg>
															<span>Loading customers...</span>
														</button>
													</div>
												)}

												{isEmpty(state.originalLoadedCustomers) && !state.loadingCustomers && (
													<div className="flex h-full items-center justify-center">
														<button
															disabled
															type="button"
															className="text-white bg-blue-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 inline-flex items-center"
														>
															<span>
																You have no customers in your space please create some in{' '}
																<a className="underline" href="/Customers"
																   alt="Create customers">
																	here
																</a>
															</span>
														</button>
													</div>
												)}

												{!isEmpty(state.originalLoadedCustomers) && (
													<div className="px-10">
														<div
															className="flex relative w-full justify-between items-center py-5 border-b">
															<div>
																<h2 className="font-semibold text-xl">Select a
																	customer</h2>
															</div>
															<div>
																<div className="text-gray-400 relative">
																	<input
																		ref={this.searchCustomerInputRef}
																		className="text-gray-700 text-xs bg-white border flex items-center max-w-xs outline-blue-300 px-3 pr-9 relative rounded w-screen h-11"
																		type="text"
																		name="search"
																		placeholder="Find a customer"
																		onChange={this.handleSearchCustomer}
																	/>
																	<button
																		className="pointer-events-none absolute right-0 top-0 h-full px-3">
																		<svg className="w-6 h-6" fill="currentColor"
																			 viewBox="0 0 20 20"
																			 xmlns="http://www.w3.org/2000/svg">
																			<path
																				fillRule="evenodd"
																				d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
																				clipRule="evenodd"
																			/>
																		</svg>
																	</button>
																</div>
															</div>
														</div>
														<table
															className="relative min-w-full border-b bg-transparent overflow-scroll">
															<thead className="bg-white border-b">
															<tr>
																<th className="p-2 pl-0 text-sm font-bold tracking-worderer">ID</th>
																<th className="p-2 text-sm font-bold tracking-worderer">Company</th>
																<th className="p-2 text-sm font-bold tracking-worderer">Name</th>
																<th className="p-2 text-sm font-bold tracking-worderer">Email</th>
																<th className="p-2 text-sm font-bold tracking-worderer">Phone
																	Number
																</th>
															</tr>
															</thead>

															<tbody className="divide-y">
															{state.loadedCustomers.map((customer, index) => {
																return (
																	<tr
																		key={index}
																		onClick={this.pickCustomer.bind(this, customer)}
																		className={Classnames('cursor-pointer hover:bg-gray-100 bg-white')}
																	>
																		<th className="p-2 pl-0 text-sm font-normal tracking-worderer ">
																			<div className="truncate max-w-[250px]">
																				{customer.friendly_id ? `#${customer.friendly_id}` : ""}
																			</div>
																		</th>
																		<th className="p-2 text-sm font-normal tracking-worderer">
																			<div className="truncate max-w-[250px]">
																				{customer.company_name}
																			</div>
																		</th>
																		<th className="p-2 text-sm font-normal tracking-worderer">
																			<div className="truncate max-w-[250px]">
																				{`${customer.name}`}
																			</div>
																		</th>
																		<th className="p-2 text-sm font-normal tracking-worderer">
																			<div className="truncate max-w-[250px]">
																				{customer.email}
																			</div>
																		</th>
																		<th className="p-2 text-sm font-normal tracking-worderer">
																			<div className="truncate max-w-[250px]">
																				{customer.customer_phone_numbers[0]?.phone_number_formatted}
																			</div>
																		</th>
																	</tr>
																)
															})}
															</tbody>
														</table>
													</div>
												)}
											</React.Fragment>
										)}

										{state.step === 2 && (
											<React.Fragment>
												<div>
													<div className="px-[24px] pb-5">
														<table
															className="min-w-full bg-transparent overflow-scroll px-[15px]">
															<thead
																className="bg-[#5D6E81] text-white uppercase font-semibold text-[14px]">
															<tr>
																<th className="w-1/4 px-[15px] py-2 text-sm font-semibold tracking-worderer rounded-tl-[5px] rounded-bl-[5px]">Product</th>
																<th className="w-1/6 py-2 text-sm font-semibold tracking-worderer">SKU</th>
																<th className="py-2 text-sm font-semibold tracking-worderer">Price</th>
																<th className="px-[15px] py-2 text-sm font-semibold tracking-worderer text-center  rounded-tr-[5px] rounded-br-[5px]">Action</th>
															</tr>
															</thead>
															<tbody className="">
															{state.memoedItems.map((product, index) => {
																return (
																	<tr key={index}
																		className={Classnames('cursor-pointer hover:bg-gray-100 bg-white border-b text-[#5D6E81] font-semibold')}>
																		<td className="py-6 px-[15px] text-sm font-normal tracking-worderer w-7/12">
																			<div className="w-10/12">
																				<p className="text-[#5D6E81] font-semibold mb-[8px]">{product.name}</p>
                                        <div className='flex gap-4'>
                                          <p
                                            onClick={event => {
                                              this.handleChangeMemoBox(event, product)
                                            }}
                                            className="text-[14px] font-semibold w-max border-[1px] rounded-[5px] px-[4px] py-[2px]"
                                          >
                                            {product?.box ? 'With Box' : 'Without Box'}
                                          </p>
                                          <p
                                            onClick={(event) => {this.handleOpenNoteModal(event, product)}}
                                            className={Classnames(`text-[14px] font-semibold w-max border-[1px] rounded-[5px] p-[2px]`, {
                                              'border-[#E0E0E0] text-[#E0E0E0]': !product?.note,
                                            })}
                                          >
                                            Notes
                                          </p>
                                        </div>
																			</div>
																		</td>
																		<td className="py-6  text-sm text-[#5D6E81] font-semibold tracking-worderer">{product.sku}</td>
																		<td className="py-6  text-sm font-normal tracking-worderer">
																			<div className="flex flex-col">
																				<input
																					onChange={this.handleMemoItemPriceChange.bind(this, product)}
																					type="text"
																					className="border px-2 py-1 rounded text-[#5D6E81] font-semibold"
																					value={Globals.formatCurrency(product.wholesale_price, true)}
																					placeholder="Item Price"
																				/>
																				{state.errors.map(value => {
																					return (
																						value.price.index === index &&
																						<span
																							className="text-red-700">{value.price.error}</span>
																					)
																				})}
																			</div>
																		</td>
																		<td className="py-6 px-[15px] tracking-worderer"
																			align="center">
																			{!product.inBag && (
																				<button
																					onClick={this.handleAddingItemToMemo.bind(this, product)}
																					className="inline-flex text-xs bg-green-100 text-green-700 border border-green-100 hover:bg-green-200 hover:border-green-200 transition rounded items-center pl-1 pr-2 py-0.5"
																				>
																					<svg className="w-4 h-4"
																						 fill="currentColor"
																						 viewBox="0 0 20 20"
																						 xmlns="http://www.w3.org/2000/svg">
																						<path
																							fillRule="evenodd"
																							d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
																							clipRule="evenodd"
																						/>
																					</svg>
																					<span>Add</span>
																				</button>
																			)}
																			{product.inBag && (
																				<button
																					onClick={this.handleRemovingItemToMemo.bind(this, product)}
																					className="flex flex-col text-xs rounded items-center pl-1 pr-2 py-0.5"
																				>
																					<img src="/Images/remove_icon.png"
																						 alt="remove icon"
																						 className="w-[22px] h-[22px] cursor-pointer" />
																					<span
																						className="text-[10px] font-semibold text-[#EE8B8B]">Delete</span>
																				</button>
																			)}
																		</td>
																	</tr>
																)
															})}

															{state.miscItems.map((product, index) => {
																return (
																	<tr key={index}
																		className={Classnames('cursor-pointer hover:bg-gray-100 bg-white border-b')}>
																		<td className="py-6 px-[15px] text-sm font-normal tracking-worderer w-7/12">
																			<div className="w-10/12">
																				<p className="text-[#5D6E81] font-semibold">{product.product_name}</p>
																			</div>
																		</td>
																		<td className="py-6  text-sm text-[#5D6E81] font-semibold tracking-worderer">{product.sku}</td>
																		<td className="py-6  text-sm font-normal tracking-worderer">
																			<div className="flex flex-col">
																				<input
																					onChange={this.handleMemoMiscPriceChange.bind(this, index)}
																					type="text"
																					className="border px-2 py-1 rounded text-[#5D6E81] font-semibold"
																					value={Globals.formatCurrency(product.price, true)}
																					placeholder="Item Price"
																				/>
																			</div>
																		</td>
																		<td className="py-6 px-[15px] font-normal tracking-worderer"
																			align="center">
																			<button
																				onClick={this.handleRemovingMiscItemMemo.bind(this, index)}
																				className="flex flex-col text-xs rounded items-center pl-1 pr-2 py-0.5"
																			>
																				<img src="/Images/remove_icon.png"
																					 alt="remove icon"
																					 className="w-[22px] h-[22px]" />
																				<span
																					className="text-[10px] font-semibold text-[#EE8B8B]">Delete</span>
																			</button>
																		</td>
																	</tr>
																)
															})}
															</tbody>
														</table>

														{!isEmpty(state.memoedItems) && (
															<div
																className="flex justify-between items-start w-full pt-2">
																<div
																	className="w-1/4 text-[13px] font-normal px-[35px] tracking-worderer">
																	Total
																	Products: {state.memoedItems.length + state.miscItems.length}
																</div>
																<div
																	className="w-[400px] pr-[30px] text-sm font-semibold tracking-worderer">
																	<br />
																	<div className="flex justify-between items-center">
																		<div
																			className="text-black text-base font-normal">Subtotal
																		</div>
																		<div
																			className="text-black text-base font-normal">
																			{Globals.readCurrencyNumber(
																				sum(state.memoedItems.map(t => (t.wholesale_price ? Globals.revertPrice(t.wholesale_price) : 0 * parseInt(t.qty)))) +
																				sum(state.miscItems.map(t => (t.price ? Globals.revertPrice(t.price) : 0)))
																			, true)}
																		</div>
																	</div>
																	<div
																		className="flex justify-between items-center pb-[4px]">
																		<div
																			className="text-black text-base font-normal">Shipping
																		</div>
																		<div
																			className="text-black text-base font-normal">
																			<div className="text-sm font-normal flex">
																				<div
																					onClick={e => this.toggleShipping(e)}
																					className={Classnames(
																						'text-center w-[120px] h-[23px] cursor-pointer flex items-center justify-center rounded-[5px] border-[1px] border-[#5d6e8133]',
																						{
																							'text-[#4B7CBE] bg-[#5D6E81] bg-opacity-20': this.state.isCustomerLabeled,
																							'text-[#4B7CBE] bg-white': !this.state.isCustomerLabeled
																						}
																					)}
																				>
																					Customer Label
																				</div>
																				{!this.state.isCustomerLabeled ? (
																					<input
																						onChange={$event => {
																							this.setState({
																								shippingPrice:
																									Globals.formatCurrency($event.target.value)
																							})
																						}}
																						className="border h-[23px] w-[51px] rounded-[5px] ml-[7px] text-right"
																						readOnly={this.state.isCustomerLabeled}
																						disabled={this.state.isCustomerLabeled}
																						value={state.shippingPrice}
																					/>
																				) : (
																					<></>
																				)}
																			</div>
																		</div>
																	</div>
																	<hr className="m-0 border-black w-4/12 float-right" />
																	<div
																		className="flex justify-between items-center w-full pt-[4px]">
																		<div
																			className="text-black text-base font-normal">Total
																		</div>
																		<div
																			className="text-black text-base font-normal">
																			{Globals.readCurrencyNumber(
																				sum(state.memoedItems.map(t => (t.wholesale_price ? Globals.revertPrice(t.wholesale_price) : 0))) +
																				sum(state.miscItems.map(t => (t.price ? Globals.revertPrice(t.price) : 0))) +
																				Globals.revertPrice(state.shippingPrice)
																			)}
																		</div>
																	</div>
																</div>
															</div>
														)}
														{isEmpty(state.memoedItems) &&
															<div className="text-xs py-4">No Item(s) Memoed</div>}
													</div>

													<div className="mt-[48px] bg-[#F4F4F4]">
														<div className="px-[39px] py-5">
															<div
																className="text-[#5D6E81] text-[22px] font-bold">Customer
																Details
															</div>
															<div className="w-full flex justify-between mt-[15px]">
																<div className="justify-between">
																	<div
																		className="flex items-center gap-x-[13px] mb-[30px]">
																		<img src="./Images/company.png" alt=""
																			 className="h-[24px] w-[24px]" />
																		<span>{state.selectedCustomer?.company_name}</span>
																	</div>
																	<div
																		className="flex items-center gap-x-[13px] mb-[30px]">
																		<img src="./Images/phone.png" alt=""
																			 className="h-[24px] w-[24px]" />
																		<div className="flex flex-col">
																			{
																				state.selectedCustomer?.customer_phone_numbers[0] && (
																					<div>
																						<span>{state.selectedCustomer?.customer_phone_numbers[0].phone_number_formatted}</span>
																					</div>
																				)
																			}
																		</div>
																	</div>
																	<div className="flex items-center gap-x-[13px]">
																		<img src="./Images/mail.png" alt=""
																			 className="h-[24px] w-[24px]" />
																		<span>{state.selectedCustomer?.email}</span>
																	</div>
																</div>
																<div className="flex flex-col">
																	<div
																		className="flex items-center gap-x-[13px] mb-[11px]">
																		<img src="./Images/people.png" alt=""
																			 className="h-[24px] w-[24px]" />
																		<span
																			className="text-[#5D6E81] text-[14px] font-semibold">Billing</span>
																		<AddressDropdown
																			addressOptions={state.selectedCustomer.customer_addresses}
																			addressType="billing"
																			selectedAddress={state.selectedBillingAddress}
																			onSelect={this.handleSelectAddress}
																			onAddNew={this.handleAddNewAddress}
																		></AddressDropdown>
																	</div>
																	<div className="ms-[43px] max-w-[235px]">
																		{state.newBillingAddress.visible ? (
																			<React.Fragment>
																				<input
																					type="text"
																					className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																					placeholder="Company Name"
																					value={state.newBillingAddress.company_name}
																					onChange={e => this.handleNewAddressChange('newBillingAddress', 'company_name', e.target.value)}
																				/>
																				<input
																					type="text"
																					className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																					placeholder="Contact Name"
																					value={state.newBillingAddress.contact_name}
																					onChange={e => this.handleNewAddressChange('newBillingAddress', 'contact_name', e.target.value)}
																				/>
																				{!this.state.isManualBillingAddress ?
																					<>
																						<div>
																							<div className='flex w-[235px] gap-1'>
																								<ServerAutoSuggestAddress
																									formData={{full_address: state.newBillingAddress.full_address}}
																									handleChangeGoogleAddress={this.handleChangeBillingAddress}
																									className="border rounded-[5px] w-full h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																									view={'memo'}
																								/>
																								<input
																									type="text"
																									name='apt_unit_number'
																									className="border rounded-[5px] w-[38%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																									placeholder="Apt/Unit #"
																									value={state.newBillingAddress.apt_unit_number}
																									onChange={e => this.handleNewAddressChange('newBillingAddress', 'apt_unit_number', e.target.value)}
																								/>
																							</div>
																							<span
																								onClick={this.handleVisibleManualBillingAddress}
																								className="text-xs mt-[1px] cursor-pointer underline"
																							>
																								Add Manually
																							</span>
																						</div>
																					</>
																					:
																					<>
																						<div>
																							<div className='flex w-[235px] gap-1'>
																								<input
																									type="text"
																									className="border rounded-[5px] w-[70%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																									placeholder="Street"
																									name='addressss'
																									value={state.newBillingAddress.address}
																									onChange={e => this.handleNewAddressChange('newBillingAddress', 'address', e.target.value)}
																								/>
																								<input
																									type="text"
																									name='apt_unit_number'
																									className="border rounded-[5px] w-[30%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																									placeholder="Apt/Unit #"
																									value={state.newBillingAddress.apt_unit_number}
																									onChange={e => this.handleNewAddressChange('newBillingAddress', 'apt_unit_number', e.target.value)}
																								/>
																							</div>
																							<span
																								onClick={this.handleVisibleManualBillingAddress}
																								className='text-xs mt-[1px] cursor-pointer underline'
																							>
																								Use Google autocomplete
																							</span>
																						</div>
																						<div className="flex w-[235px] gap-1">
																							<input
																								type="text"
																								name="city"
																								className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																								placeholder="City"
																								value={state.newBillingAddress.city}
																								onChange={e => this.handleNewAddressChange('newBillingAddress', 'city', e.target.value)}
																							/>
																							<input
																								type="text"
																								name="state"
																								className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																								placeholder="State"
																								value={state.newBillingAddress.state}
																								onChange={e => this.handleNewAddressChange('newBillingAddress', 'state', e.target.value)}
																							/>
																						</div>
																						<div
																							className="flex w-[235px] gap-1">
																							<input
																								type="text"
																								name="zip_code"
																								className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																								placeholder="Zip Code"
																								value={state.newBillingAddress.zip_code}
																								onChange={e => this.handleNewAddressChange('newBillingAddress', 'zip_code', e.target.value)}
																							/>
																							<div
																								className="flex items-center border rounded-[5px] w-1/2 h-[30px] text-[12px]">
																								<select
																									id="country"
																									name="country"
																									className="w-full"
																									defaultValue={state.newBillingAddress?.country || ''}
																									onChange={e => this.handleNewAddressChange('newBillingAddress', 'country', e.target.value)}
																								>
																									<option value=""
																											disabled>
																										Select Country
																									</option>
																									{Globals.countries.map(c => (
																										<option
																											key={c.code}
																											value={c.code}>
																											{c.name}
																										</option>
																									))}
																								</select>
																							</div>
																						</div>

																					</>
																				}
																				<input
																					type="text"
																					className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																					placeholder="Phone Number"
																					value={state.newBillingAddress.phone_number}
																					onChange={e => this.handleNewAddressChange('newBillingAddress', 'phone_number', e.target.value)}
																				/>
																				<div className="flex items-center">
																					<label
																						htmlFor="save_to_contact_billing">
																						<input
																							type="checkbox"
																							id="save_to_contact_billing"
																							className="hidden peer"
																							checked={state.newBillingAddress.save_to_contact}
																							onChange={e => this.handleNewAddressChange('newBillingAddress', 'save_to_contact', e.target.checked)}
																						/>
																						<div
																							className="flex items-center text-center">
																							<span
																								htmlFor="save_to_contact_billing"
																								className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500 indeterminate:bg-gray-300"
																							>
																								{state.newBillingAddress.save_to_contact && (
																									<svg
																										xmlns="http://www.w3.org/2000/svg"
																										width="12"
																										height="10"
																										viewBox="0 0 12 10"
																										fill="none">
																										<path
																											d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z"
																											fill="#2C79E0" />
																									</svg>
																								)}
																							</span>
																							<span
																								className="mt-1 text-[12px] text-[#5D6E81] font-normal">Save Address to Contact</span>
																						</div>
																					</label>
																				</div>
																			</React.Fragment>
																		) : (
																			<React.Fragment>
																				<p>{state.selectedBillingAddress?.company_name}</p>
																				<p>{state.selectedBillingAddress?.contact_name}</p>
																				<p>{state.selectedBillingAddress?.full_address}</p>
																				<p>{state.selectedBillingAddress?.phone_number_formatted}</p>
																			</React.Fragment>
																		)}
																	</div>
																</div>
																<div className="flex flex-col">
																	<div
																		className="flex items-center gap-x-[13px] mb-[11px]">
																		<img src="./Images/location_detail.png" alt=""
																			 className="h-[24px] w-[24px]" />
																		<span
																			className="text-[#5D6E81] text-[14px] font-semibold">Shipping</span>
																		{/* Customer form goes here */}
																		<AddressDropdown
																			addressOptions={state.selectedCustomer.customer_addresses}
																			addressType="shipping"
																			selectedAddress={state.selectedShippingAddress}
																			onSelect={this.handleSelectAddress}
																			onAddNew={this.handleAddNewAddress}
																		></AddressDropdown>
																	</div>
																	<div className="ms-[43px] max-w-[235px]">
																		{state.newShippingAddress.visible ? (
																			<React.Fragment>
																				<input
																					type="text"
																					className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																					placeholder="Company Name"
																					value={state.newShippingAddress.company_name}
																					onChange={e => this.handleNewAddressChange('newShippingAddress', 'company_name', e.target.value)}
																				/>
																				<input
																					type="text"
																					className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																					placeholder="Contact Name"
																					value={state.newShippingAddress.contact_name}
																					onChange={e => this.handleNewAddressChange('newShippingAddress', 'contact_name', e.target.value)}
																				/>
																				{!this.state.isManualShippingAddress ?
																				<>
																						<div>
																								<div className='flex w-[235px] gap-1'>
																									<ServerAutoSuggestAddress
																										formData={{full_address: state.newShippingAddress.full_address}}
																										handleChangeGoogleAddress={this.handleChangeShippingAddress}
																										className="border rounded-[5px] w-full h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																										view={'memo'}
																									/>
																									<input
																										type="text"
																										name='apt_unit_number'
																										className="border rounded-[5px] w-[38%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																										placeholder="Apt/Unit #"
																										value={state.newShippingAddress.apt_unit_number}
																										onChange={e => this.handleChangeShippingAddress('newBillingAddress', 'apt_unit_number', e.target.value)}
																									/>
																								</div>
																								<span
																									onClick={this.handleVisibleManualShippingAddress}
																									className="text-xs mt-[1px] cursor-pointer underline"
																								>
																									Add Manually
																								</span>
																							</div>
																					</>
																					:
																					<>
																						<div>
																								<div className='flex w-[235px] gap-1'>
																									<input
																										type="text"
																										className="border rounded-[5px] w-[70%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																										placeholder="Street"
																										name='address'
																										value={state.newShippingAddress.address}
																										onChange={e => this.handleNewAddressChange('newShippingAddress', 'address', e.target.value)}
																									/>
																									<input
																										type="text"
																										name='apt_unit_number'
																										className="border rounded-[5px] w-[30%] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																										placeholder="Apt/Unit #"
																										value={state.newShippingAddress.apt_unit_number}
																										onChange={e => this.handleNewAddressChange('newShippingAddress', 'apt_unit_number', e.target.value)}
																									/>
																								</div>
																								<span
																									onClick={this.handleVisibleManualShippingAddress}
																									className='text-xs mt-[1px] cursor-pointer underline'
																								>
																									Use Google autocomplete
																								</span>
																							</div>
																						<div
																							className="flex w-[235px] gap-1">
																							<input
																								type="text"
																								name="city"
																								className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																								placeholder="City"
																								value={state.newShippingAddress.city}
																								onChange={e => this.handleNewAddressChange('newShippingAddress', 'city', e.target.value)}
																							/>
																							<input
																								type="text"
																								name="state"
																								className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																								placeholder="State"
																								value={state.newShippingAddress.state}
																								onChange={e => this.handleNewAddressChange('newShippingAddress', 'state', e.target.value)}
																							/>
																						</div>
																						<div
																							className="flex w-[235px] gap-1">
																							<input
																								type="text"
																								name="zip_code"
																								className="border rounded-[5px] w-1/2 h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																								placeholder="Zip Code"
																								value={state.newShippingAddress.zip_code}
																								onChange={e => this.handleNewAddressChange('newShippingAddress', 'zip_code', e.target.value)}
																							/>
																							<div
																								className="flex items-center border rounded-[5px] w-1/2 h-[30px] text-[12px]">
																								<select
																									id="country"
																									name="country"
																									className="w-full"
																									defaultValue={state.newShippingAddress?.country || ''}
																									onChange={e => this.handleNewAddressChange('newShippingAddress', 'country', e.target.value)}
																								>
																									<option value=""
																											disabled>
																										Select Country
																									</option>
																									{Globals.countries.map(c => (
																										<option
																											key={c.code}
																											value={c.code}>
																											{c.name}
																										</option>
																									))}
																								</select>
																							</div>
																						</div>

																					</>
																				}
																				<input
																					type="text"
																					className="border rounded-[5px] w-[235px] h-[30px] px-[10px] mb-[4px] bg-[#F4F4F4]"
																					placeholder="Phone Number"
																					value={state.newShippingAddress.phone_number}
																					onChange={e => this.handleNewAddressChange('newShippingAddress', 'phone_number', e.target.value)}
																				/>
																				<div className="flex items-center">
																					<label
																						htmlFor="save_to_contact_shipping">
																						<input
																							type="checkbox"
																							id="save_to_contact_shipping"
																							className="hidden peer"
																							defaultChecked={state.newShippingAddress.save_to_contact}
																							onChange={e => this.handleNewAddressChange('newShippingAddress', 'save_to_contact', e.target.checked)}
																						/>
																						<div
																							className="flex items-center text-center">
																							<span
																								htmlFor="save_to_contact_shipping"
																								className="w-4 h-4 border border-gray-400 rounded-sm mr-2 flex items-center justify-center bg-white text-blue-500 indeterminate:bg-gray-300"
																							>
																								{state.newShippingAddress.save_to_contact && (
																									<svg
																										xmlns="http://www.w3.org/2000/svg"
																										width="12"
																										height="10"
																										viewBox="0 0 12 10"
																										fill="none">
																										<path
																											d="M10.59 0L12 1.42L4 9.42L0 5.43L1.42 4.02L4 6.59L10.59 0Z"
																											fill="#2C79E0" />
																									</svg>
																								)}
																							</span>
																							<span
																								className="mt-1 text-[12px] text-[#5D6E81] font-normal">Save Address to Contact</span>
																						</div>
																					</label>
																				</div>
																			</React.Fragment>
																		) : (
																			<React.Fragment>
																				<p>{state.selectedShippingAddress?.company_name}</p>
																				<p>{state.selectedShippingAddress?.contact_name}</p>
																				<p>{state.selectedShippingAddress?.full_address}</p>
																				<p>{state.selectedShippingAddress?.phone_number_formatted}</p>
																			</React.Fragment>
																		)}
																	</div>
																</div>
															</div>
														</div>
													</div>

													<div
														className="px-[39px] w-full flex mt-[21px] min-h-[153px] relative">
														<div className="w-1/2">
															<div className="text-[22px] text-[#5D6E81] font-bold">Memo
																Details
															</div>
															<div
																className="flex flex-col pl-[22px] pr-[37px] pt-[20px] gap-y-[13px]">
																<div className="flex justify-between">
																	<div>Memo Date:</div>
																	<div>{Moment().format('MM/DD/YYYY')}</div>
																</div>
																{this.props.currentSpace.invoice_settings?.[0]?.visible_salesperson &&
																	<div className="flex justify-between">
																		<div>Salesperson:</div>
																		<UsersDropdown
																			userOptions={state.loadedSpaceUsers}
																			selectedUser={state.selectedSalesPerson}
																			onSelect={this.handleSelectSalesPerson}
																		/>
																	</div>
																}
																<div className="flex justify-between">
																	<div>Tracking Number</div>
																	<input
																		name="tracking_number"
																		value={state.trackingNumber}
																		ref={this.trackingNumberInputRef}
																		onKeyDown={this.handleKeyDownTrackingNumber}
																		onChange={this.handleChangeTrackingNumber}
																		className="bg-white relative cursor-pointer rounded-[4px] border-[1px] border-[#5D6E81]
																			w-[184px] text-end px-1"
																	/>
																</div>
															</div>
														</div>
														<hr className="w-[1px] h-[153px] bg-[#0000004D] mt-10 mx-[20px]" />
														<div className="w-1/2 pl-[22px]">
															<div
																className="text-[22px] text-[#5D6E81] font-bold flex items-center gap-x-[37px]">
																<span>Comments</span>
																<img
																	src="./Images/plus_radius.png"
																	alt=""
																	className="w-[22px] h-[22px]"
																	onClick={() => this.setState({isVisibleComments: !state.isVisibleComments})}
																/>
															</div>
															<div className="flex flex-col">
																{state.isVisibleComments && (
                                  <>
                                    <textarea
                                      className="border rounded-[5px] w-[100%] h-[100px] px-[10px] py-[10px] mt-[20px]"
                                      placeholder="Add a comment"
                                      name='content'
                                      value={state.memoComment.content}
                                      onChange={this.handleCommentChange}
                                    />
                                    <div className='flex justify-between items-center'>
                                      <div className='items-center flex h-full'>
                                        <span className="__checkbox h-max" style={{transform: 'scale(.8)'}}>
                                          <input
                                            id={`checkbox-visible-on-pdf`}
                                            type="checkbox"
                                            name="visible_on_pdf"
                                            onChange={this.handleCommentChange}
                                            checked={state.memoComment.visible_on_pdf}
                                          />
                                          <Tooltip title="Visible on Memo" placement="top" arrow>
                                            <label htmlFor={`checkbox-visible-on-pdf`}></label>
                                          </Tooltip>
                                        </span>
                                        <label htmlFor={`checkbox-visible-on-pdf`} className='text-[12px] ml-[5px] cursor-pointer'>Visible on Memo</label>
                                      </div>
                                    </div>
                                  </>
																)}
															</div>
														</div>
													</div>

													<div
														className="mt-6 flex gap-4 items-center justify-center px-10 mx-auto gap-x-[16px]">
														<button
															className="transition w-[84px] h-[38px] rounded-[5px] border border-[#5d6e8147] text-[#5D6E81] font-semibold text-[12px] hover:bg-gray-300"
															onClick={() => {
																this.setState({
																	step: 1,
																	isVisibleAddSale: false,
																	loadedCustomers: null,
																	originalLoadedCustomers: null,
																	selectedCustomer: null,
																	memoedItems: [],
																	loadedProducts: [],
																	miscItems: [],
																	shippingPrice: 0,
																	isManualBillingAddress: false,
																	isManualShippingAddress: false,
																	newBillingAddress: initAddress,
																	newShippingAddress: initAddress,
																	trackingNumber: '',
																	selectedSalesPerson: null,
																	errors: []
																})
															}}
														>
															Cancel
														</button>
														<button
															className="w-[66px] h-[38px] transition rounded-[5px] bg-[#2863EB] text-white text-[12px] hover:bg-blue-800"
															onClick={this.handleSubmitMemo}
														>
															Save
														</button>
													</div>
												</div>
												{state.isVisibleMiscProducts && (
													<AddNewMiscProduct
														hideAddNewMiscProduct={this.hideAddNewMiscProduct}
														handleMiscItemList={this.handleMiscItemList}
														props={this.props} />
												)}

												{state.isVisibleProductsHunter && (
													<div
														className="single-customer-view bg-black/50 fixed top-0 right-0 left-0 bottom-0 z-40"
														tabIndex="-1">
														<div
															className="bg-white rounded-tl-xl absolute top-0 right-0 shadow-xl overflow-hidden h-full"
															style={{
																maxWidth: '80%',
																width: '100%'
															}}
														>
															<div
																className="flex items-center gap-3 bg-white border-b py-6 px-10">
																<div
																	className="flex flex-row items-center justify-between w-full">
																	<div className="flex gap-1">
																		<button
																			onClick={() => {
																				this.setState({
																					isVisibleProductsHunter: false,
																					shippingPrice: 0
																				})
																			}}
																		>
																			<svg className="w-6 h-6" viewBox="0 0 22 22"
																				 fill="currentColor"
																				 xmlns="http://www.w3.org/2000/svg">
																				<path
																					d="M17.8672 3.19508C17.625 3.2008 17.3949 3.30218 17.2271 3.47706L11.0003 9.70395L4.77337 3.47706C4.68784 3.38914 4.58554 3.31928 4.47252 3.27161C4.35951 3.22395 4.23807 3.19944 4.11541 3.19956C3.93314 3.19978 3.75506 3.25434 3.60394 3.35626C3.45282 3.45819 3.33551 3.60284 3.267 3.77176C3.1985 3.94068 3.1819 4.12618 3.21933 4.30457C3.25676 4.48297 3.34652 4.64615 3.47715 4.77329L9.70404 11.0002L3.47715 17.2271C3.38917 17.3115 3.31894 17.4127 3.27055 17.5247C3.22216 17.6366 3.1966 17.7571 3.19536 17.879C3.19412 18.001 3.21722 18.122 3.26332 18.2349C3.30942 18.3478 3.37759 18.4504 3.46382 18.5366C3.55006 18.6229 3.65264 18.691 3.76556 18.7371C3.87847 18.7832 3.99944 18.8063 4.1214 18.8051C4.24335 18.8038 4.36383 18.7783 4.47578 18.7299C4.58774 18.6815 4.68891 18.6113 4.77337 18.5233L11.0003 12.2964L17.2271 18.5233C17.3116 18.6113 17.4128 18.6815 17.5247 18.7299C17.6367 18.7783 17.7572 18.8038 17.8791 18.8051C18.0011 18.8063 18.1221 18.7832 18.235 18.7371C18.3479 18.691 18.4505 18.6229 18.5367 18.5366C18.6229 18.4504 18.6911 18.3478 18.7372 18.2349C18.7833 18.122 18.8064 18.001 18.8052 17.879C18.8039 17.7571 18.7784 17.6366 18.73 17.5247C18.6816 17.4127 18.6113 17.3115 18.5234 17.2271L12.2965 11.0002L18.5234 4.77329C18.6568 4.64538 18.7484 4.48015 18.7862 4.29926C18.824 4.11837 18.8063 3.93027 18.7354 3.75963C18.6644 3.58899 18.5436 3.44377 18.3886 3.34303C18.2337 3.24228 18.052 3.19071 17.8672 3.19508Z" />
																			</svg>
																		</button>
																		<h2 className="font-semibold text-2xl uppercase">Add
																			memoed items</h2>
																	</div>
																</div>
															</div>

															<div
																className="flex relative w-full justify-end items-center p-5 px-10">
																<div>
																	<div className="text-gray-400 relative">
																		<input
																			className="text-gray-700 text-xs bg-white border flex items-center max-w-xs outline-blue-300 px-3 pr-9 relative rounded w-screen h-11"
																			type="text"
																			name="search"
																			placeholder="Find a product by SKU"
																			onChange={this.handleSearchProducts}
																		/>
																		<button
																			className="pointer-events-none absolute right-0 top-0 h-full px-3">
																			<svg className="w-6 h-6" fill="currentColor"
																				 viewBox="0 0 20 20"
																				 xmlns="http://www.w3.org/2000/svg">
																				<path
																					fillRule="evenodd"
																					d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
																					clipRule="evenodd"
																				/>
																			</svg>
																		</button>
																	</div>
																</div>
															</div>
															<div
																className="max-h-full overflow-scroll overflow-x-hidden pb-60 px-10">
																<table
																	className="relative min-w-full border-b bg-transparent overflow-scroll">
																	<thead className="bg-white border-t border-b">
																	<tr>
																		<th className="w-96 py-2 pr-2 text-sm font-semibold tracking-worderer">Product</th>
																		<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">SKU</th>
																		<th className="py-2 pr-2 text-sm font-semibold tracking-worderer">Wholesale
																			Price
																		</th>
																		<th className="py-2 text-sm font-semibold tracking-worderer text-right">Action</th>
																	</tr>
																	</thead>
																	<tbody className="divide-y">
																	{state.loadedProducts.map((product, index) => {
																		return (
																			<tr
																				key={index}
																				className={Classnames('cursor-pointer hover:bg-gray-100', {
																					'bg-white': isEmpty(state.memoedItems.filter(item => item.id === product.id)),
																					'bg-neutral-100': !isEmpty(state.memoedItems.filter(item => item.id === product.id))
																				})}
																			>
																				<th className="py-2 pr-2 text-sm font-normal tracking-worderer">{product.name}</th>
																				<th className="py-2 text-sm font-normal tracking-worderer">{product.sku}</th>
																				<th className="py-2 text-sm font-normal tracking-worderer">
																					{!product.inBag && (
																						<input
																							onChange={this.handleItemPriceChange.bind(this, product)}
																							onClick={this.handleItemPriceChange.bind(this, product)}
																							type="text"
																							className="border px-2 py-1 rounded"
																							//defaultValue={product.MarketplacePrice}

																							value={product.wholesale_price ? Globals.formatCurrency(product.wholesale_price, true) : ''}
																							placeholder="Item Price"
																							style={{
																								maxWidth: 100
																							}}
																						/>
																					)}

																					{product.inBag && (
																						<input
																							onChange={this.handleMemoItemPriceChange.bind(this, product)}
																							type="text"
																							className="border px-2 py-1 rounded"
																							defaultValue={Globals.formatCurrency(product.wholesale_price, true)}
																							placeholder="Item Price"
																							style={{
																								maxWidth: 100
																							}}
																						/>
																					)}
																				</th>
																				<th className="py-2 text-sm font-normal tracking-worderer text-right">
																					{!product.inBag && (
																						<button
																							onClick={this.handleAddingItemToMemo.bind(this, product)}
																							className="inline-flex text-xs bg-green-100 text-green-700 border border-green-100 hover:bg-green-200 hover:border-green-200 transition rounded items-center pl-1 pr-2 py-0.5"
																						>
																							<svg className="w-4 h-4"
																								 fill="currentColor"
																								 viewBox="0 0 20 20"
																								 xmlns="http://www.w3.org/2000/svg">
																								<path
																									fillRule="evenodd"
																									d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
																									clipRule="evenodd"
																								/>
																							</svg>
																							<span>Add</span>
																						</button>
																					)}
																					{product.inBag && (
																						<button
																							onClick={this.handleRemovingItemToMemo.bind(this, product)}
																							className="inline-flex text-xs bg-red-100 text-red-700 border border-red-100 hover:bg-red-200 hover:border-red-200 transition rounded items-center pl-1 pr-2 py-0.5"
																						>
																							<svg className="w-4 h-4"
																								 fill="currentColor"
																								 viewBox="0 0 20 20"
																								 xmlns="http://www.w3.org/2000/svg">
																								<path
																									fillRule="evenodd"
																									d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
																									clipRule="evenodd"
																								/>
																							</svg>
																							<span>Remove</span>
																						</button>
																					)}
																				</th>
																			</tr>
																		)
																	})}
																	</tbody>
																</table>
																{isEmpty(state.loadedProducts) &&
																	<div className="text-xs py-4">No products
																		found</div>}
															</div>

															<div
																className="absolute bg-white border-t bottom-0 flex justify-end px-10 py-3 w-full">
																<button
																	className="hover:bg-blue-400 transition rounded bg-blue-500 text-white px-5 py-2 text-md"
																	onClick={() => {
																		return this.setState({
																			isVisibleProductsHunter: false
																		})
																	}}
																>
																	Done
																</button>
															</div>
														</div>
													</div>
												)}
											</React.Fragment>
										)}
									</div>
								</div>
							</div>
						)}

						{state.customerFormVisible &&
							<AddNewCustomer handleCloseForm={this.handleCloseCustomerForm} props={this.props}
											handleLoadCustomers={this.handleLoadCustomers}
											handlePrimaryTab={this.handlePrimaryTab} />}
						{state.isVisibleSingleMemo && (
							<SingleMemoDetail
								props={this.props}
								singleMemoItemId={this.state.singleMemoItemId}
								updateMemoList={this.updateMemoList}
								memosList={this.state.memosList}
								closeSingleMemoDetail={this.closeSingleMemoDetail}
								handleOpenEdit={this.handleOpenEdit}
								handleOpenPerformanceLimitAccessModal={this.handleOpenPerformanceLimitAccessModal}
								handleClickOut={this.handleClickOut}
								handleLoadMemos={this.handleLoadMemos}
								updateItemInMemos={this.updateItemInMemos}
								currentSpace={this.props.currentSpace}
								profile={this.props.profile}
								handlePrimaryTab={this.handlePrimaryTab}
							/>
						)}
						{state.isVisibleProductsHunter && (
							<ProductsHunter
								loadedProducts={this.state.loadedProducts}
								handleCloseProductsHunter={this.handleCloseProductsHunter}
								handleAddingItemToMemo={this.handleAddingItemToMemo}
								handleRemovingItemToMemo={this.handleRemovingItemToMemo}
								memoedItems={this.state.memoedItems}
								handleSearchProducts={this.handleSearchProducts}
								handleItemPriceChange={this.handleItemPriceChange}
								props={this.props}
								handleUpdateItemProduct={this.handleUpdateItemProduct}
								loadingProduct={this.state.loadingProduct}
							/>
						)}
            {state.openNoteModal && (
              <AddNoteForLineItem
                noteValue={state.memoItemSelected?.note}
                handleChangeNote={this.setNoteData}
                handleClose={this.handleCloseNoteModal}
                isEdit={!isEmpty(state.memoItemSelected?.note) ? true : false}
                addToast={this.props.addToast}
              />
            )}
					</React.Fragment>
				)}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		profile: state.profile,
		isOpenSideBar: state.isOpenSideBar,
		loggedin: state.loggedin,
		isOpenDetailsInventory: state.isOpenDetailsInventory,
		currentSpace: state.currentSpace
	}
}

const mapActionsToProps = {
	addToast,
	toggleSidebar,
	toggleDetailsCardInventory
}

export default connect(mapStateToProps, mapActionsToProps)(MemoView)
